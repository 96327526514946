const IconCopy: React.FC<{ size?: number; className?: string }> = ({
  size,
  className,
}) => {
  return (
    <svg
      width={size || 12}
      height={size || 12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || "fill-textSecondary"}
    >
      <g id="Standard-Icons">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.83112 1.559C8.94362 1.5665 9.05612 1.575 9.16762 1.584C9.93762 1.649 10.4996 2.302 10.4996 3.0545V7.8755C10.4996 8.27332 10.3416 8.65486 10.0603 8.93616C9.77897 9.21746 9.39744 9.3755 8.99962 9.3755V8.25C8.99962 5.289 6.71162 2.8625 3.80762 2.6415C3.96962 2.0755 4.45762 1.6365 5.08162 1.5845C5.19362 1.575 5.30562 1.5665 5.41812 1.559C5.54468 1.31514 5.73579 1.11073 5.9706 0.96807C6.2054 0.825408 6.47487 0.749974 6.74962 0.75H7.49962C7.77436 0.749974 8.04384 0.825408 8.27864 0.96807C8.51344 1.11073 8.70455 1.31514 8.83112 1.559ZM5.99962 2.25C5.99962 2.05109 6.07863 1.86032 6.21929 1.71967C6.35994 1.57902 6.5507 1.5 6.74962 1.5H7.49962C7.69853 1.5 7.88929 1.57902 8.02995 1.71967C8.1706 1.86032 8.24962 2.05109 8.24962 2.25H5.99962Z"
        />
        <path
          id="Vector_2"
          d="M1.5 4.3125C1.5 3.7945 1.92 3.375 2.4375 3.375H2.625C3.12228 3.375 3.59919 3.57254 3.95083 3.92417C4.30246 4.27581 4.5 4.75272 4.5 5.25V6.1875C4.5 6.7055 4.92 7.125 5.4375 7.125H6.375C6.62123 7.125 6.86505 7.1735 7.09253 7.26773C7.32002 7.36195 7.52672 7.50006 7.70083 7.67417C7.87494 7.84828 8.01305 8.05498 8.10727 8.28247C8.2015 8.50995 8.25 8.75377 8.25 9V10.3125C8.25 10.83 7.83 11.25 7.3125 11.25H2.4375C2.18886 11.25 1.9504 11.1512 1.77459 10.9754C1.59877 10.7996 1.5 10.5611 1.5 10.3125V4.3125Z"
        />
        <path
          id="Vector_3"
          d="M5.24985 5.2502C5.25073 4.61948 5.02361 4.00969 4.61035 3.5332C5.447 3.75319 6.2102 4.19144 6.82191 4.80315C7.43361 5.41485 7.87187 6.17806 8.09185 7.0147C7.61537 6.60145 7.00558 6.37433 6.37485 6.3752H5.43735C5.38762 6.3752 5.33993 6.35545 5.30477 6.32029C5.26961 6.28512 5.24985 6.23743 5.24985 6.1877V5.2502Z"
        />
      </g>
    </svg>
  );
};

export default IconCopy;
