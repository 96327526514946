const IconInfo: React.FC<{ size?: number; className?: string }> = ({
  size,
  className,
}) => {
  return (
    <svg
      width={size || 15}
      height={size || 14}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Standard-Icons">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.8125 7C1.8125 3.85875 4.35875 1.3125 7.5 1.3125C10.6412 1.3125 13.1875 3.85875 13.1875 7C13.1875 10.1412 10.6412 12.6875 7.5 12.6875C4.35875 12.6875 1.8125 10.1412 1.8125 7ZM6.891 6.15883C7.5595 5.82458 8.31258 6.42892 8.13117 7.154L7.71758 8.80833L7.74208 8.79667C7.84488 8.75148 7.96111 8.74754 8.06672 8.78567C8.17234 8.8238 8.25925 8.90108 8.30946 9.00152C8.35968 9.10195 8.36936 9.21785 8.3365 9.32522C8.30363 9.43259 8.23074 9.52321 8.13292 9.57833L8.10958 9.59117C7.4405 9.92542 6.68742 9.32108 6.86883 8.596L7.283 6.94167L7.2585 6.95333C7.2069 6.98201 7.15002 6.99995 7.0913 7.00607C7.03258 7.01218 6.97324 7.00635 6.91683 6.98893C6.86042 6.9715 6.80813 6.94284 6.76309 6.90466C6.71806 6.86649 6.68121 6.8196 6.65478 6.76681C6.62834 6.71402 6.61287 6.65643 6.60928 6.5975C6.60569 6.53858 6.61407 6.47953 6.63391 6.42393C6.65374 6.36832 6.68463 6.31731 6.7247 6.27395C6.76477 6.2306 6.81321 6.19581 6.86708 6.17167L6.891 6.15883ZM7.5 5.25C7.61603 5.25 7.72731 5.20391 7.80936 5.12186C7.89141 5.03981 7.9375 4.92853 7.9375 4.8125C7.9375 4.69647 7.89141 4.58519 7.80936 4.50314C7.72731 4.42109 7.61603 4.375 7.5 4.375C7.38397 4.375 7.27269 4.42109 7.19064 4.50314C7.10859 4.58519 7.0625 4.69647 7.0625 4.8125C7.0625 4.92853 7.10859 5.03981 7.19064 5.12186C7.27269 5.20391 7.38397 5.25 7.5 5.25Z"
          fill="#828187"
        />
      </g>
    </svg>
  );
};

export default IconInfo;
