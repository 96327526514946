const IconX: React.FC<{ size?: number }> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 12}
      height={size || 12}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29333 7.29334C7.48083 7.10608 7.735 7.00089 8 7.00089C8.265 7.00089 8.51917 7.10608 8.70667 7.29334L16 14.5867L23.2933 7.29334C23.3849 7.1951 23.4953 7.11629 23.618 7.06164C23.7406 7.00698 23.873 6.97759 24.0073 6.97522C24.1416 6.97285 24.2749 6.99755 24.3995 7.04785C24.524 7.09814 24.6371 7.173 24.7321 7.26796C24.827 7.36292 24.9019 7.47603 24.9522 7.60055C25.0025 7.72507 25.0272 7.85844 25.0248 7.99271C25.0224 8.12698 24.993 8.2594 24.9384 8.38206C24.8837 8.50473 24.8049 8.61513 24.7067 8.70668L17.4133 16L24.7067 23.2933C24.8049 23.3849 24.8837 23.4953 24.9384 23.618C24.993 23.7406 25.0224 23.873 25.0248 24.0073C25.0272 24.1416 25.0025 24.275 24.9522 24.3995C24.9019 24.524 24.827 24.6371 24.7321 24.7321C24.6371 24.827 24.524 24.9019 24.3995 24.9522C24.2749 25.0025 24.1416 25.0272 24.0073 25.0248C23.873 25.0224 23.7406 24.993 23.618 24.9384C23.4953 24.8837 23.3849 24.8049 23.2933 24.7067L16 17.4133L8.70667 24.7067C8.5171 24.8833 8.26637 24.9795 8.0073 24.9749C7.74824 24.9703 7.50106 24.8654 7.31784 24.6822C7.13462 24.499 7.02967 24.2518 7.0251 23.9927C7.02053 23.7336 7.11669 23.4829 7.29333 23.2933L14.5867 16L7.29333 8.70668C7.10607 8.51918 7.00088 8.26501 7.00088 8.00001C7.00088 7.73501 7.10607 7.48084 7.29333 7.29334Z"
        fill="white"
      />
    </svg>
  );
};

export default IconX;
