const IconCheck: React.FC<{ size?: number }> = ({ size }) => {
  return (
    <svg
      width={size || 16}
      height={size || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Standard-Icons">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 8C1.5 4.41 4.41 1.5 8 1.5C11.59 1.5 14.5 4.41 14.5 8C14.5 11.59 11.59 14.5 8 14.5C4.41 14.5 1.5 11.59 1.5 8ZM10.4067 6.79067C10.4467 6.73737 10.4756 6.67661 10.4918 6.61197C10.508 6.54732 10.5111 6.48009 10.5009 6.41424C10.4907 6.34838 10.4675 6.28522 10.4326 6.22847C10.3976 6.17171 10.3517 6.12252 10.2975 6.08377C10.2433 6.04502 10.1819 6.0175 10.1169 6.00282C10.0519 5.98814 9.98459 5.98661 9.91899 5.99831C9.85338 6.01001 9.79078 6.0347 9.73485 6.07094C9.67893 6.10718 9.63081 6.15423 9.59333 6.20933L7.436 9.22933L6.35333 8.14667C6.25855 8.05835 6.13319 8.01026 6.00365 8.01255C5.87412 8.01484 5.75053 8.06731 5.65892 8.15892C5.56731 8.25053 5.51484 8.37412 5.51255 8.50365C5.51026 8.63319 5.55835 8.75855 5.64667 8.85333L7.14667 10.3533C7.19799 10.4046 7.25987 10.4441 7.32799 10.4691C7.39612 10.4941 7.46886 10.5039 7.54118 10.498C7.61349 10.492 7.68364 10.4704 7.74675 10.4346C7.80987 10.3988 7.86443 10.3497 7.90667 10.2907L10.4067 6.79067Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default IconCheck;
