const BrandBackgroundPattern: React.FC<{
  themeColor: string;
}> = ({ themeColor }) => {
  return (
    <svg
      viewBox="0 0 1480 996"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-auto"
    >
      <mask
        id="mask0_1470_5262"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="1512"
        height="503"
      >
        <rect
          y="0.564453"
          width="1512"
          height="502"
          fill="url(#paint0_radial_1470_5262)"
        />
      </mask>
      <g mask="url(#mask0_1470_5262)">
        <rect y="-0.435547" width="1512" height="510" fill={themeColor} />
        <path
          d="M-3.39143 342.564C3.35132 339.005 7.96799 331.924 7.96799 323.773C7.95584 312.076 -1.55694 302.564 -13.2444 302.564H-50.7851C-62.4726 302.564 -71.9854 312.088 -71.9854 323.785L-71.961 361.332V361.356H-71.9854C-71.9854 373.053 -62.4726 382.564 -50.7851 382.564L-13.2322 382.54C-1.53263 382.54 7.98015 373.029 7.98015 361.332C7.98015 353.193 3.36344 346.124 -3.37931 342.564H-3.39143ZM-13.2444 377.669L-50.773 377.694V377.657C-59.7755 377.657 -67.0893 370.332 -67.0893 361.332L-67.1135 323.785C-67.1135 314.772 -59.7876 307.435 -50.7851 307.435H-13.2444C-4.22974 307.435 3.09618 314.772 3.09618 323.773C3.09618 332.774 -4.22974 340.135 -13.2322 340.135C-24.9318 340.135 -34.4567 349.646 -34.4567 361.344H-29.5849C-29.5849 352.331 -22.2469 344.994 -13.2322 344.994C-4.21758 344.994 3.09618 352.318 3.09618 361.319C3.09618 370.32 -4.22974 377.657 -13.2444 377.657V377.669Z"
          fill={themeColor}
        />
        <path
          d="M-13.2568 377.67L-50.7854 377.695V377.658C-59.7879 377.658 -67.1017 370.334 -67.1017 361.333L-67.126 323.786C-67.126 314.773 -59.8001 307.437 -50.7976 307.437H-13.2447C-4.23002 307.437 3.09591 314.773 3.09591 323.774C3.09591 332.775 -4.23001 340.136 -13.2325 340.136C-24.9321 340.136 -34.457 349.647 -34.457 361.345H-29.5852C-29.5852 352.332 -22.2471 344.995 -13.2325 344.995C-4.21786 344.995 3.09591 352.32 3.09591 361.321C3.09591 370.321 -4.23002 377.658 -13.2447 377.658L-13.2568 377.67Z"
          fill="white"
        />
        <path
          d="M82.994 323.786C82.994 312.081 73.4755 302.564 61.7688 302.564C53.6361 302.564 46.561 307.171 43.0113 313.917C39.4495 307.183 32.3622 302.564 24.2052 302.564H24.193C12.4985 302.564 2.97998 312.081 2.97998 323.786L3.00428 361.355C3.00428 373.048 12.5107 382.564 24.2052 382.564C32.3622 382.564 39.4494 377.946 42.9991 371.176C46.5488 377.934 53.6361 382.564 61.7931 382.564C73.4998 382.564 83.0183 373.048 83.0183 361.355L82.994 323.786ZM61.8052 377.691C52.7851 377.691 45.4548 370.361 45.4548 361.343L45.4304 360.918C45.394 349.249 35.8876 339.769 24.2052 339.769V344.643C33.2253 344.643 40.5557 351.972 40.5557 360.99V361.343C40.5557 370.361 33.2253 377.691 24.2052 377.691C15.1851 377.691 7.87905 370.361 7.87905 361.355L7.85475 323.786C7.85475 314.78 15.1851 307.438 24.2052 307.438C33.2375 307.438 40.5922 314.767 40.5922 323.762C40.5922 329.426 42.7925 334.81 46.7798 338.906C50.8158 343.051 56.1525 345.336 61.8052 345.336V340.462C52.9431 340.462 45.4669 332.804 45.4548 323.75C45.4548 314.755 52.7851 307.438 61.7809 307.438C70.7767 307.438 78.1314 314.767 78.1314 323.786L78.1557 361.355C78.1557 370.361 70.8254 377.691 61.8052 377.691Z"
          fill={themeColor}
        />
        <path
          d="M61.805 377.692C52.7849 377.692 45.4545 370.363 45.4545 361.344L45.4302 360.919C45.3937 349.25 35.8873 339.77 24.205 339.77V344.644C33.2251 344.644 40.5555 351.973 40.5555 360.992V361.344C40.5555 370.363 33.2251 377.692 24.205 377.692C15.1848 377.692 7.87879 370.363 7.87879 361.356L7.85449 323.787C7.85449 314.781 15.1848 307.439 24.205 307.439C33.2372 307.439 40.5919 314.769 40.5919 323.763C40.5919 329.427 42.7922 334.811 46.7796 338.907C50.8155 343.052 56.1522 345.337 61.805 345.337V340.463C52.9429 340.463 45.4667 332.806 45.4545 323.751C45.4545 314.756 52.7849 307.439 61.7807 307.439C70.7765 307.439 78.1312 314.769 78.1312 323.787L78.1555 361.356C78.1555 370.363 70.8251 377.692 61.805 377.692Z"
          fill="white"
        />
        <path
          d="M158.069 323.774C158.057 312.081 148.538 302.564 136.844 302.564L99.2681 302.589H99.2438V302.564C87.5371 302.564 78.0186 312.081 78.0186 323.774L78.0429 361.343C78.0429 373.048 87.5614 382.564 99.2681 382.564C104.933 382.564 110.27 380.304 114.294 376.183C115.813 374.628 117.077 372.877 118.062 371.006C119.047 372.877 120.299 374.628 121.831 376.183C125.854 380.304 131.191 382.564 136.856 382.564C148.563 382.564 158.081 373.048 158.081 361.343L158.106 323.774H158.069ZM136.856 377.691C127.836 377.691 120.506 370.228 120.506 361.051C120.506 351.875 127.69 344.995 136.868 344.995V340.121C131.191 340.121 125.867 342.273 121.879 346.174C120.384 347.645 119.132 349.298 118.159 351.085C114.597 343.914 107.389 338.87 99.2803 338.87V343.743C107.996 343.743 115.643 351.826 115.643 361.051C115.643 370.276 108.3 377.691 99.2803 377.691C90.2601 377.691 82.9298 370.361 82.9298 361.343L82.9055 323.798H82.9419C82.9419 314.792 90.2723 307.475 99.2803 307.475L136.856 307.451C145.876 307.451 153.206 314.78 153.206 323.798H153.243L153.219 361.355C153.219 370.374 145.888 377.703 136.868 377.703L136.856 377.691Z"
          fill={themeColor}
        />
        <path
          d="M82.9174 361.342L82.8931 323.797H82.9295C82.9295 314.791 90.2599 307.474 99.2679 307.474L136.844 307.45C145.864 307.45 153.194 314.779 153.194 323.797H153.231L153.206 361.354C153.206 370.373 145.876 377.702 136.856 377.702C127.836 377.702 120.505 370.239 120.505 361.063C120.505 351.886 127.69 345.007 136.868 345.007V340.133C131.191 340.133 125.866 342.284 121.879 346.186C120.384 347.656 119.132 349.309 118.159 351.096C114.597 343.925 107.388 338.881 99.28 338.881V343.755C107.996 343.755 115.643 351.837 115.643 361.063C115.643 370.288 108.3 377.702 99.28 377.702C90.2599 377.702 82.9295 370.373 82.9295 361.354L82.9174 361.342Z"
          fill="white"
        />
        <path
          d="M174.321 340.275C184.686 340.275 193.095 350.213 193.095 360.588"
          stroke="THEME"
          strokeWidth="15"
          strokeMiterlimit="10"
        />
        <path
          d="M211.88 382.564C203.848 382.564 196.691 377.656 193.094 370.634C189.51 377.656 182.353 382.552 174.321 382.552C162.62 382.552 153.105 373.04 153.105 361.34V323.801C153.105 312.101 162.62 302.589 174.321 302.589L211.88 302.564C223.569 302.564 233.083 312.077 233.083 323.776C233.083 331.916 228.454 339.011 221.673 342.558C228.454 346.118 233.095 353.201 233.095 361.34V361.668C232.925 373.21 223.472 382.552 211.892 382.552L211.88 382.564ZM195.537 360.575C195.537 369.857 203.022 377.693 211.88 377.693C220.738 377.693 228.211 370.367 228.211 361.365V361.061C228.053 352.18 220.738 345 211.746 345V340.129C220.823 340.129 228.211 332.791 228.211 323.776C228.211 314.762 220.883 307.436 211.88 307.436L174.321 307.46C165.305 307.46 157.978 314.786 157.978 323.801V361.34C157.978 370.355 165.305 377.681 174.321 377.681C183.337 377.681 190.664 369.845 190.664 360.575H195.537Z"
          fill={themeColor}
        />
        <path
          d="M228.211 361.376C228.211 370.379 220.884 377.704 211.88 377.704C202.876 377.704 195.537 369.868 195.537 360.587C195.537 348.474 185.621 337.844 174.321 337.844V342.716C183.033 342.716 190.664 351.074 190.664 360.587C190.664 370.099 183.179 377.692 174.321 377.692C165.463 377.692 157.978 370.366 157.978 361.352V323.812C157.978 314.798 165.305 307.472 174.321 307.472L211.88 307.448C220.884 307.448 228.211 314.773 228.211 323.788C228.211 332.802 220.823 340.14 211.746 340.14V345.012C220.726 345.012 228.053 352.192 228.211 361.073V361.376Z"
          fill="white"
        />
        <path
          d="M228.095 361.359H232.977C232.977 352.351 240.307 345.036 249.304 345.036C258.301 345.036 265.631 352.364 265.631 361.359H270.513C270.513 349.673 261.004 340.154 249.304 340.154V340.118C240.295 340.118 232.977 332.79 232.977 323.794C232.977 314.798 240.307 307.47 249.304 307.47L286.84 307.446C295.849 307.446 303.166 314.774 303.166 323.77H308.048C308.048 312.071 298.54 302.564 286.84 302.564L249.304 302.589C237.604 302.589 228.095 312.096 228.095 323.794C228.095 331.95 232.722 339.034 239.479 342.577C232.722 346.119 228.095 353.216 228.095 361.359Z"
          fill={themeColor}
        />
        <path
          d="M308.048 323.77H303.166C303.166 332.778 295.837 340.094 286.84 340.094C277.842 340.094 270.513 332.766 270.513 323.77H265.631C265.631 335.468 275.139 344.975 286.84 344.975V345.012C295.849 345.012 303.166 352.34 303.166 361.335C303.166 370.331 295.837 377.659 286.84 377.659L249.304 377.684C240.295 377.684 232.977 370.356 232.977 361.36H228.095C228.095 373.058 237.604 382.565 249.304 382.565L286.84 382.541C298.528 382.541 308.048 373.034 308.048 361.335C308.048 353.18 303.422 346.095 296.665 342.553C303.422 339.01 308.048 331.914 308.048 323.77Z"
          fill={themeColor}
        />
        <path
          d="M286.973 377.696L249.438 377.721C240.429 377.721 233.111 370.393 233.111 361.385C233.111 352.377 240.441 345.049 249.438 345.049C258.435 345.049 265.765 352.377 265.765 361.385H270.647C270.647 349.687 261.138 340.167 249.438 340.167C240.429 340.167 233.111 332.827 233.111 323.819C233.111 314.811 240.441 307.496 249.438 307.496L286.973 307.471C295.983 307.471 303.3 314.799 303.3 323.807C303.3 332.815 295.971 340.131 286.973 340.131C277.976 340.131 270.647 332.803 270.647 323.807H265.765C265.765 335.505 275.273 345.012 286.973 345.012V345.049C295.983 345.049 303.3 352.377 303.3 361.373C303.3 370.368 295.971 377.696 286.973 377.696Z"
          fill="white"
        />
        <circle cx="322.048" cy="342.565" r="6" fill="white" />
        <circle cx="322.048" cy="342.565" r="6" stroke="#04030F" />
        <path
          d="M340.923 323.775C340.923 314.769 348.253 307.439 357.273 307.439C366.293 307.439 373.624 314.769 373.624 323.787L373.66 361.356H378.535C378.535 352.338 385.865 345.009 394.886 345.009C403.906 345.009 411.236 352.338 411.236 361.356C411.236 370.375 403.906 377.704 394.886 377.704H357.298C348.278 377.704 340.947 370.375 340.947 361.356L340.923 323.787V323.775Z"
          fill="white"
        />
        <path
          d="M416.111 361.343C416.111 349.65 406.592 340.134 394.886 340.134C388.309 340.134 382.413 343.148 378.523 347.852L378.499 323.786C378.499 312.081 368.98 302.564 357.274 302.564C345.567 302.564 336.048 312.081 336.048 323.774L336.073 361.343C336.073 373.048 345.591 382.564 357.298 382.564H394.886C406.58 382.564 416.099 373.048 416.111 361.355V361.343ZM394.886 377.691H357.298C348.278 377.691 340.947 370.361 340.947 361.343L340.923 323.774C340.923 314.767 348.253 307.438 357.274 307.438C366.294 307.438 373.624 314.767 373.624 323.786L373.661 361.343H378.535C378.535 352.324 385.866 344.995 394.886 344.995C403.906 344.995 411.236 352.324 411.236 361.343C411.236 370.361 403.906 377.691 394.886 377.691Z"
          fill={themeColor}
        />
        <path
          d="M432.36 382.564C420.654 382.564 411.135 373.048 411.135 361.343L411.111 323.774C411.111 312.081 420.629 302.564 432.336 302.564C444.043 302.564 453.561 312.081 453.561 323.786V361.367C453.561 373.048 444.055 382.552 432.373 382.552L432.36 382.564ZM432.336 307.438C423.316 307.438 415.986 314.767 415.986 323.774L416.01 361.343C416.01 370.361 423.34 377.691 432.36 377.691C441.38 377.691 448.687 370.374 448.687 361.367V323.786C448.687 314.767 441.356 307.438 432.336 307.438Z"
          fill={themeColor}
        />
        <path
          d="M432.324 307.439C423.304 307.439 415.973 314.769 415.973 323.775L415.997 361.344C415.997 370.363 423.328 377.692 432.348 377.692C441.368 377.692 448.674 370.375 448.674 361.368V323.787C448.674 314.769 441.344 307.439 432.324 307.439Z"
          fill="white"
        />
        <path
          d="M453.436 323.775C453.436 314.769 460.766 307.439 469.787 307.439C478.807 307.439 486.137 314.769 486.137 323.787L486.173 361.356H491.048C491.048 352.338 498.379 345.009 507.399 345.009C516.419 345.009 523.749 352.338 523.749 361.356C523.749 370.375 516.419 377.704 507.399 377.704H469.811C460.791 377.704 453.46 370.375 453.46 361.356L453.436 323.787V323.775Z"
          fill="white"
        />
        <path
          d="M528.624 361.343C528.624 349.65 519.106 340.134 507.399 340.134C500.822 340.134 494.926 343.148 491.036 347.852L491.012 323.786C491.012 312.081 481.493 302.564 469.787 302.564C458.08 302.564 448.562 312.081 448.562 323.774L448.586 361.343C448.586 373.048 458.104 382.564 469.811 382.564H507.399C519.094 382.564 528.612 373.048 528.624 361.355V361.343ZM507.399 377.691H469.811C460.791 377.691 453.461 370.361 453.461 361.343L453.436 323.774C453.436 314.767 460.767 307.438 469.787 307.438C478.807 307.438 486.137 314.767 486.137 323.786L486.174 361.343H491.048C491.048 352.324 498.379 344.995 507.399 344.995C516.419 344.995 523.749 352.324 523.749 361.343C523.749 370.361 516.419 377.691 507.399 377.691Z"
          fill={themeColor}
        />
        <path
          d="M603.638 323.786C603.638 312.081 594.12 302.564 582.413 302.564C574.28 302.564 567.205 307.171 563.655 313.917C560.093 307.183 553.006 302.564 544.849 302.564H544.837C533.143 302.564 523.624 312.081 523.624 323.786L523.648 361.355C523.648 373.048 533.155 382.564 544.849 382.564C553.006 382.564 560.093 377.946 563.643 371.176C567.193 377.934 574.28 382.564 582.437 382.564C594.144 382.564 603.662 373.048 603.662 361.355L603.638 323.786ZM582.449 377.691C573.429 377.691 566.099 370.361 566.099 361.343L566.074 360.918C566.038 349.249 556.532 339.769 544.849 339.769V344.643C553.869 344.643 561.2 351.972 561.2 360.99V361.343C561.2 370.361 553.869 377.691 544.849 377.691C535.829 377.691 528.523 370.361 528.523 361.355L528.499 323.786C528.499 314.78 535.829 307.438 544.849 307.438C553.882 307.438 561.236 314.767 561.236 323.762C561.236 329.426 563.437 334.81 567.424 338.906C571.46 343.051 576.797 345.336 582.449 345.336V340.462C573.587 340.462 566.111 332.804 566.099 323.75C566.099 314.755 573.429 307.438 582.425 307.438C591.421 307.438 598.775 314.767 598.775 323.786L598.8 361.355C598.8 370.361 591.469 377.691 582.449 377.691Z"
          fill={themeColor}
        />
        <path
          d="M582.449 377.692C573.429 377.692 566.099 370.363 566.099 361.344L566.074 360.919C566.038 349.25 556.531 339.77 544.849 339.77V344.644C553.869 344.644 561.199 351.973 561.199 360.992V361.344C561.199 370.363 553.869 377.692 544.849 377.692C535.829 377.692 528.523 370.363 528.523 361.356L528.499 323.787C528.499 314.781 535.829 307.439 544.849 307.439C553.881 307.439 561.236 314.769 561.236 323.763C561.236 329.427 563.436 334.811 567.424 338.907C571.46 343.052 576.796 345.337 582.449 345.337V340.463C573.587 340.463 566.111 332.806 566.099 323.751C566.099 314.756 573.429 307.439 582.425 307.439C591.421 307.439 598.775 314.769 598.775 323.787L598.8 361.356C598.8 370.363 591.469 377.692 582.449 377.692Z"
          fill="white"
        />
        <path
          d="M657.398 302.589L619.856 302.564C608.537 302.564 599.258 311.502 598.687 322.686H598.663V361.362C598.663 373.056 608.172 382.564 619.856 382.564L657.398 382.54C669.094 382.54 678.604 373.032 678.604 361.338V323.791C678.604 312.097 669.094 302.589 657.398 302.589ZM657.398 377.659L619.856 377.683C610.857 377.683 603.533 370.36 603.533 361.35V337.258C607.444 341.994 613.371 345.017 619.978 345.017C631.601 345.017 641.062 335.485 641.062 323.767H636.192C636.192 332.801 628.917 340.148 619.966 340.148C611.015 340.148 603.521 332.789 603.521 323.754C603.521 314.72 610.844 307.422 619.844 307.422L657.386 307.446C666.398 307.446 673.721 314.768 673.721 323.779V361.326C673.721 370.336 666.398 377.659 657.386 377.659H657.398Z"
          fill={themeColor}
        />
        <path
          d="M619.856 377.696L657.398 377.672C666.41 377.672 673.733 370.349 673.733 361.339V323.792C673.733 314.782 666.41 307.459 657.398 307.459L619.856 307.435C610.856 307.435 603.533 314.757 603.533 323.768C603.533 332.778 610.905 340.161 619.978 340.161C629.05 340.161 636.204 332.815 636.204 323.78H641.074C641.074 335.498 631.613 345.031 619.99 345.031C613.37 345.031 607.456 342.007 603.545 337.271V361.363C603.545 370.374 610.869 377.696 619.868 377.696H619.856Z"
          fill="white"
        />
        <path
          d="M732.441 382.564H694.853C683.146 382.564 673.628 373.048 673.628 361.343L673.604 323.774C673.604 312.081 683.122 302.564 694.829 302.564C702.998 302.564 710.097 307.207 713.647 313.977C717.197 307.195 724.296 302.564 732.465 302.564C744.172 302.564 753.69 312.081 753.69 323.774L753.666 361.343C753.666 373.035 744.148 382.564 732.441 382.564ZM694.829 307.438C685.809 307.438 678.478 314.767 678.478 323.774L678.503 361.343C678.503 370.361 685.833 377.691 694.853 377.691H732.441C741.461 377.691 748.791 370.361 748.791 361.343L748.816 323.774C748.816 314.767 741.485 307.438 732.465 307.438C723.445 307.438 716.115 314.767 716.115 323.786L716.09 347.633H711.216V324.284H711.191L711.167 323.786C711.167 314.767 703.837 307.438 694.817 307.438H694.829Z"
          fill={themeColor}
        />
        <path
          d="M694.828 307.439C685.808 307.439 678.478 314.769 678.478 323.775L678.502 361.344C678.502 370.363 685.833 377.692 694.853 377.692H732.441C741.461 377.692 748.791 370.363 748.791 361.344L748.815 323.775C748.815 314.769 741.485 307.439 732.465 307.439C723.445 307.439 716.115 314.769 716.115 323.787L716.09 347.634H711.215V324.285H711.191L711.167 323.799C711.167 314.781 703.836 307.452 694.816 307.452L694.828 307.439Z"
          fill="white"
        />
        <path
          d="M828.704 323.786C828.704 312.081 819.186 302.564 807.479 302.564C799.347 302.564 792.271 307.171 788.722 313.917C785.16 307.183 778.073 302.564 769.916 302.564H769.903C758.209 302.564 748.69 312.081 748.69 323.786L748.715 361.355C748.715 373.048 758.221 382.564 769.916 382.564C778.073 382.564 785.16 377.946 788.71 371.176C792.259 377.934 799.347 382.564 807.504 382.564C819.21 382.564 828.729 373.048 828.729 361.355L828.704 323.786ZM807.516 377.691C798.496 377.691 791.165 370.361 791.165 361.343L791.141 360.918C791.104 349.249 781.598 339.769 769.916 339.769V344.643C778.936 344.643 786.266 351.972 786.266 360.99V361.343C786.266 370.361 778.936 377.691 769.916 377.691C760.896 377.691 753.589 370.361 753.589 361.355L753.565 323.786C753.565 314.78 760.896 307.438 769.916 307.438C778.948 307.438 786.303 314.767 786.303 323.762C786.303 329.426 788.503 334.81 792.49 338.906C796.526 343.051 801.863 345.336 807.516 345.336V340.462C798.654 340.462 791.177 332.804 791.165 323.75C791.165 314.755 798.496 307.438 807.491 307.438C816.487 307.438 823.842 314.767 823.842 323.786L823.866 361.355C823.866 370.361 816.536 377.691 807.516 377.691Z"
          fill={themeColor}
        />
        <path
          d="M807.515 377.692C798.495 377.692 791.165 370.363 791.165 361.344L791.141 360.919C791.104 349.25 781.598 339.77 769.915 339.77V344.644C778.936 344.644 786.266 351.973 786.266 360.992V361.344C786.266 370.363 778.936 377.692 769.915 377.692C760.895 377.692 753.589 370.363 753.589 361.356L753.565 323.787C753.565 314.781 760.895 307.439 769.915 307.439C778.948 307.439 786.302 314.769 786.302 323.763C786.302 329.427 788.503 334.811 792.49 338.907C796.526 343.052 801.863 345.337 807.515 345.337V340.463C798.653 340.463 791.177 332.806 791.165 323.751C791.165 314.756 798.495 307.439 807.491 307.439C816.487 307.439 823.842 314.769 823.842 323.787L823.866 361.356C823.866 370.363 816.536 377.692 807.515 377.692Z"
          fill="white"
        />
        <path
          d="M823.729 361.359H828.611C828.611 352.351 835.941 345.036 844.938 345.036C853.935 345.036 861.265 352.364 861.265 361.359H866.147C866.147 349.673 856.638 340.154 844.938 340.154V340.118C835.928 340.118 828.611 332.79 828.611 323.794C828.611 314.798 835.941 307.47 844.938 307.47L882.473 307.446C891.483 307.446 898.8 314.774 898.8 323.77H903.682C903.682 312.071 894.174 302.564 882.473 302.564L844.938 302.589C833.238 302.589 823.729 312.096 823.729 323.794C823.729 331.95 828.356 339.034 835.113 342.577C828.356 346.119 823.729 353.216 823.729 361.359Z"
          fill={themeColor}
        />
        <path
          d="M903.682 323.77H898.8C898.8 332.778 891.471 340.094 882.473 340.094C873.476 340.094 866.147 332.766 866.147 323.77H861.265C861.265 335.468 870.773 344.975 882.473 344.975V345.012C891.483 345.012 898.8 352.34 898.8 361.335C898.8 370.331 891.471 377.659 882.473 377.659L844.938 377.684C835.928 377.684 828.611 370.356 828.611 361.36H823.729C823.729 373.058 833.238 382.565 844.938 382.565L882.473 382.541C894.161 382.541 903.682 373.034 903.682 361.335C903.682 353.18 899.056 346.095 892.299 342.553C899.056 339.01 903.682 331.914 903.682 323.77Z"
          fill={themeColor}
        />
        <path
          d="M882.607 377.696L845.072 377.721C836.062 377.721 828.745 370.393 828.745 361.385C828.745 352.377 836.074 345.049 845.072 345.049C854.069 345.049 861.398 352.377 861.398 361.385H866.281C866.281 349.687 856.772 340.167 845.072 340.167C836.062 340.167 828.745 332.827 828.745 323.819C828.745 314.811 836.074 307.496 845.072 307.496L882.607 307.471C891.617 307.471 898.934 314.799 898.934 323.807C898.934 332.815 891.605 340.131 882.607 340.131C873.61 340.131 866.281 332.803 866.281 323.807H861.398C861.398 335.505 870.907 345.012 882.607 345.012V345.049C891.617 345.049 898.934 352.377 898.934 361.373C898.934 370.368 891.605 377.696 882.607 377.696Z"
          fill="white"
        />
        <circle cx="917.683" cy="342.565" r="6" fill="white" />
        <circle cx="917.683" cy="342.565" r="6" stroke="#04030F" />
        <path
          d="M1011.7 323.786C1011.7 312.081 1002.18 302.564 990.471 302.564C982.339 302.564 975.264 307.171 971.714 313.917C968.152 307.183 961.065 302.564 952.908 302.564H952.896C941.201 302.564 931.683 312.081 931.683 323.786L931.707 361.355C931.707 373.048 941.213 382.564 952.908 382.564C961.065 382.564 968.152 377.946 971.702 371.176C975.251 377.934 982.339 382.564 990.496 382.564C1002.2 382.564 1011.72 373.048 1011.72 361.355L1011.7 323.786ZM990.508 377.691C981.488 377.691 974.157 370.361 974.157 361.343L974.133 360.918C974.097 349.249 964.59 339.769 952.908 339.769V344.643C961.928 344.643 969.258 351.972 969.258 360.99V361.343C969.258 370.361 961.928 377.691 952.908 377.691C943.888 377.691 936.582 370.361 936.582 361.355L936.557 323.786C936.557 314.78 943.888 307.438 952.908 307.438C961.94 307.438 969.295 314.767 969.295 323.762C969.295 329.426 971.495 334.81 975.482 338.906C979.518 343.051 984.855 345.336 990.508 345.336V340.462C981.646 340.462 974.17 332.804 974.157 323.75C974.157 314.755 981.488 307.438 990.484 307.438C999.479 307.438 1006.83 314.767 1006.83 323.786L1006.86 361.355C1006.86 370.361 999.528 377.691 990.508 377.691Z"
          fill={themeColor}
        />
        <path
          d="M990.508 377.692C981.487 377.692 974.157 370.363 974.157 361.344L974.133 360.919C974.096 349.25 964.59 339.77 952.908 339.77V344.644C961.928 344.644 969.258 351.973 969.258 360.992V361.344C969.258 370.363 961.928 377.692 952.908 377.692C943.887 377.692 936.581 370.363 936.581 361.356L936.557 323.787C936.557 314.781 943.887 307.439 952.908 307.439C961.94 307.439 969.295 314.769 969.295 323.763C969.295 329.427 971.495 334.811 975.482 338.907C979.518 343.052 984.855 345.337 990.508 345.337V340.463C981.646 340.463 974.169 332.806 974.157 323.751C974.157 314.756 981.488 307.439 990.483 307.439C999.479 307.439 1006.83 314.769 1006.83 323.787L1006.86 361.356C1006.86 370.363 999.528 377.692 990.508 377.692Z"
          fill="#04030F"
        />
        <path
          d="M1065.46 302.589L1027.91 302.564C1016.6 302.564 1007.32 311.502 1006.75 322.686H1006.72V361.362C1006.72 373.056 1016.23 382.564 1027.91 382.564L1065.46 382.54C1077.15 382.54 1086.66 373.032 1086.66 361.338V323.791C1086.66 312.097 1077.15 302.589 1065.46 302.589ZM1065.46 377.659L1027.91 377.683C1018.92 377.683 1011.59 370.36 1011.59 361.35V337.258C1015.5 341.994 1021.43 345.017 1028.04 345.017C1039.66 345.017 1049.12 335.485 1049.12 323.767H1044.25C1044.25 332.801 1036.98 340.148 1028.02 340.148C1019.07 340.148 1011.58 332.789 1011.58 323.754C1011.58 314.72 1018.9 307.422 1027.9 307.422L1065.44 307.446C1074.46 307.446 1081.78 314.768 1081.78 323.779V361.326C1081.78 370.336 1074.46 377.659 1065.44 377.659H1065.46Z"
          fill={themeColor}
        />
        <path
          d="M1027.92 377.696L1065.46 377.672C1074.47 377.672 1081.79 370.349 1081.79 361.339V323.792C1081.79 314.782 1074.47 307.459 1065.46 307.459L1027.92 307.435C1018.92 307.435 1011.59 314.757 1011.59 323.768C1011.59 332.778 1018.96 340.161 1028.04 340.161C1037.11 340.161 1044.26 332.815 1044.26 323.78H1049.13C1049.13 335.498 1039.67 345.031 1028.05 345.031C1021.43 345.031 1015.51 342.007 1011.6 337.271V361.363C1011.6 370.374 1018.93 377.696 1027.93 377.696H1027.92Z"
          fill="#04030F"
        />
        <path
          d="M1140.5 382.564H1102.91C1091.2 382.564 1081.69 373.048 1081.69 361.343L1081.66 323.774C1081.66 312.081 1091.18 302.564 1102.89 302.564C1111.06 302.564 1118.16 307.207 1121.71 313.977C1125.26 307.195 1132.35 302.564 1140.52 302.564C1152.23 302.564 1161.75 312.081 1161.75 323.774L1161.72 361.343C1161.72 373.035 1152.21 382.564 1140.5 382.564ZM1102.89 307.438C1093.87 307.438 1086.54 314.767 1086.54 323.774L1086.56 361.343C1086.56 370.361 1093.89 377.691 1102.91 377.691H1140.5C1149.52 377.691 1156.85 370.361 1156.85 361.343L1156.87 323.774C1156.87 314.767 1149.54 307.438 1140.52 307.438C1131.5 307.438 1124.17 314.767 1124.17 323.786L1124.15 347.633H1119.27V324.284H1119.25L1119.23 323.786C1119.23 314.767 1111.9 307.438 1102.88 307.438H1102.89Z"
          fill={themeColor}
        />
        <path
          d="M1102.89 307.439C1093.87 307.439 1086.54 314.769 1086.54 323.775L1086.56 361.344C1086.56 370.363 1093.89 377.692 1102.91 377.692H1140.5C1149.52 377.692 1156.85 370.363 1156.85 361.344L1156.87 323.775C1156.87 314.769 1149.54 307.439 1140.52 307.439C1131.5 307.439 1124.17 314.769 1124.17 323.787L1124.15 347.634H1119.27V324.285H1119.25L1119.23 323.799C1119.23 314.781 1111.9 307.452 1102.87 307.452L1102.89 307.439Z"
          fill="#04030F"
        />
        <path
          d="M1236.76 323.786C1236.76 312.081 1227.24 302.564 1215.54 302.564C1207.41 302.564 1200.33 307.171 1196.78 313.917C1193.22 307.183 1186.13 302.564 1177.97 302.564H1177.96C1166.27 302.564 1156.75 312.081 1156.75 323.786L1156.77 361.355C1156.77 373.048 1166.28 382.564 1177.97 382.564C1186.13 382.564 1193.22 377.946 1196.77 371.176C1200.32 377.934 1207.41 382.564 1215.56 382.564C1227.27 382.564 1236.79 373.048 1236.79 361.355L1236.76 323.786ZM1215.57 377.691C1206.55 377.691 1199.22 370.361 1199.22 361.343L1199.2 360.918C1199.16 349.249 1189.66 339.769 1177.97 339.769V344.643C1186.99 344.643 1194.32 351.972 1194.32 360.99V361.343C1194.32 370.361 1186.99 377.691 1177.97 377.691C1168.95 377.691 1161.65 370.361 1161.65 361.355L1161.62 323.786C1161.62 314.78 1168.95 307.438 1177.97 307.438C1187.01 307.438 1194.36 314.767 1194.36 323.762C1194.36 329.426 1196.56 334.81 1200.55 338.906C1204.58 343.051 1209.92 345.336 1215.57 345.336V340.462C1206.71 340.462 1199.24 332.804 1199.22 323.75C1199.22 314.755 1206.55 307.438 1215.55 307.438C1224.55 307.438 1231.9 314.767 1231.9 323.786L1231.92 361.355C1231.92 370.361 1224.59 377.691 1215.57 377.691Z"
          fill={themeColor}
        />
        <path
          d="M1215.57 377.692C1206.55 377.692 1199.22 370.363 1199.22 361.344L1199.2 360.919C1199.16 349.25 1189.66 339.77 1177.97 339.77V344.644C1186.99 344.644 1194.32 351.973 1194.32 360.992V361.344C1194.32 370.363 1186.99 377.692 1177.97 377.692C1168.95 377.692 1161.65 370.363 1161.65 361.356L1161.62 323.787C1161.62 314.781 1168.95 307.439 1177.97 307.439C1187.01 307.439 1194.36 314.769 1194.36 323.763C1194.36 329.427 1196.56 334.811 1200.55 338.907C1204.58 343.052 1209.92 345.337 1215.57 345.337V340.463C1206.71 340.463 1199.24 332.806 1199.22 323.751C1199.22 314.756 1206.55 307.439 1215.55 307.439C1224.55 307.439 1231.9 314.769 1231.9 323.787L1231.92 361.356C1231.92 370.363 1224.59 377.692 1215.57 377.692Z"
          fill="#04030F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1236.67 361.359H1231.79C1231.79 353.216 1236.41 346.119 1243.17 342.577C1236.41 339.034 1231.79 331.95 1231.79 323.794C1231.79 312.096 1241.3 302.589 1253 302.589L1290.53 302.564C1302.23 302.564 1311.74 312.071 1311.74 323.77H1306.86C1306.86 314.774 1299.54 307.446 1290.53 307.446L1253 307.47C1244 307.47 1236.67 314.798 1236.67 323.794C1236.67 332.79 1243.99 340.118 1253 340.118V340.154C1264.7 340.154 1274.21 349.673 1274.21 361.359H1269.32C1269.32 352.364 1261.99 345.036 1253 345.036C1244 345.036 1236.67 352.351 1236.67 361.359ZM1306.86 323.77H1311.74C1311.74 331.914 1307.11 339.011 1300.36 342.553C1307.11 346.095 1311.74 353.18 1311.74 361.336C1311.74 373.034 1302.22 382.541 1290.53 382.541L1253 382.565C1241.3 382.565 1231.79 373.058 1231.79 361.36H1236.67C1236.67 370.356 1243.99 377.684 1253 377.684L1290.53 377.659C1299.53 377.659 1306.86 370.331 1306.86 361.336C1306.86 352.34 1299.54 345.012 1290.53 345.012V344.975C1278.83 344.975 1269.32 335.468 1269.32 323.77H1274.21C1274.21 332.766 1281.53 340.094 1290.53 340.094C1299.53 340.094 1306.86 332.778 1306.86 323.77Z"
          fill={themeColor}
        />
        <path
          d="M1290.67 377.696L1253.13 377.721C1244.12 377.721 1236.8 370.393 1236.8 361.385C1236.8 352.377 1244.13 345.049 1253.13 345.049C1262.13 345.049 1269.46 352.377 1269.46 361.385H1274.34C1274.34 349.687 1264.83 340.167 1253.13 340.167C1244.12 340.167 1236.8 332.827 1236.8 323.819C1236.8 314.811 1244.13 307.496 1253.13 307.496L1290.67 307.471C1299.68 307.471 1306.99 314.799 1306.99 323.807C1306.99 332.815 1299.66 340.131 1290.67 340.131C1281.67 340.131 1274.34 332.803 1274.34 323.807H1269.46C1269.46 335.505 1278.97 345.012 1290.67 345.012V345.049C1299.68 345.049 1306.99 352.377 1306.99 361.373C1306.99 370.368 1299.66 377.696 1290.67 377.696Z"
          fill="#04030F"
        />
        <circle cx="1325.74" cy="342.565" r="6" fill="white" />
        <circle cx="1325.74" cy="342.565" r="6" stroke="#04030F" />
        <path
          d="M1419.75 323.786C1419.75 312.082 1410.24 302.565 1398.53 302.565C1390.4 302.565 1383.32 307.171 1379.77 313.917C1376.21 307.184 1369.12 302.565 1360.97 302.565H1360.95C1349.26 302.565 1339.74 312.082 1339.74 323.786L1339.76 361.356C1339.76 373.048 1349.27 382.565 1360.97 382.565C1369.12 382.565 1376.21 377.946 1379.76 371.176C1383.31 377.934 1390.4 382.565 1398.55 382.565C1410.26 382.565 1419.78 373.048 1419.78 361.356L1419.75 323.786ZM1398.57 377.691C1389.55 377.691 1382.22 370.362 1382.22 361.343L1382.19 360.918C1382.15 349.25 1372.65 339.769 1360.97 339.769V344.643C1369.99 344.643 1377.32 351.972 1377.32 360.991V361.343C1377.32 370.362 1369.99 377.691 1360.97 377.691C1351.95 377.691 1344.64 370.362 1344.64 361.356L1344.62 323.786C1344.62 314.78 1351.95 307.439 1360.97 307.439C1370 307.439 1377.35 314.768 1377.35 323.762C1377.35 329.426 1379.55 334.81 1383.54 338.906C1387.58 343.051 1392.91 345.336 1398.57 345.336V340.462C1389.7 340.462 1382.23 332.805 1382.22 323.75C1382.22 314.756 1389.55 307.439 1398.54 307.439C1407.54 307.439 1414.89 314.768 1414.89 323.786L1414.92 361.356C1414.92 370.362 1407.59 377.691 1398.57 377.691Z"
          fill={themeColor}
        />
        <path
          d="M1398.57 377.692C1389.55 377.692 1382.21 370.363 1382.21 361.345L1382.19 360.919C1382.15 349.251 1372.65 339.771 1360.97 339.771V344.644C1369.99 344.644 1377.32 351.974 1377.32 360.992V361.345C1377.32 370.363 1369.99 377.692 1360.97 377.692C1351.95 377.692 1344.64 370.363 1344.64 361.357L1344.61 323.788C1344.61 314.781 1351.95 307.44 1360.97 307.44C1370 307.44 1377.35 314.769 1377.35 323.763C1377.35 329.427 1379.55 334.812 1383.54 338.908C1387.58 343.052 1392.91 345.337 1398.57 345.337V340.463C1389.7 340.463 1382.23 332.806 1382.21 323.751C1382.21 314.757 1389.55 307.44 1398.54 307.44C1407.54 307.44 1414.89 314.769 1414.89 323.788L1414.92 361.357C1414.92 370.363 1407.59 377.692 1398.57 377.692Z"
          fill="white"
        />
        <path
          d="M1496.79 323.786C1496.79 312.082 1487.27 302.565 1475.57 302.565C1467.43 302.565 1460.36 307.171 1456.81 313.917C1453.25 307.184 1446.16 302.565 1438 302.565H1437.99C1426.3 302.565 1416.78 312.082 1416.78 323.786L1416.8 361.356C1416.8 373.048 1426.31 382.565 1438 382.565C1446.16 382.565 1453.25 377.946 1456.8 371.176C1460.35 377.934 1467.43 382.565 1475.59 382.565C1487.3 382.565 1496.82 373.048 1496.82 361.356L1496.79 323.786ZM1475.6 377.691C1466.58 377.691 1459.25 370.362 1459.25 361.343L1459.23 360.918C1459.19 349.25 1449.69 339.769 1438 339.769V344.643C1447.02 344.643 1454.35 351.972 1454.35 360.991V361.343C1454.35 370.362 1447.02 377.691 1438 377.691C1428.98 377.691 1421.68 370.362 1421.68 361.356L1421.65 323.786C1421.65 314.78 1428.98 307.439 1438 307.439C1447.04 307.439 1454.39 314.768 1454.39 323.762C1454.39 329.426 1456.59 334.81 1460.58 338.906C1464.61 343.051 1469.95 345.336 1475.6 345.336V340.462C1466.74 340.462 1459.27 332.805 1459.25 323.75C1459.25 314.756 1466.58 307.439 1475.58 307.439C1484.58 307.439 1491.93 314.768 1491.93 323.786L1491.95 361.356C1491.95 370.362 1484.62 377.691 1475.6 377.691Z"
          fill={themeColor}
        />
        <path
          d="M1475.6 377.692C1466.58 377.692 1459.25 370.363 1459.25 361.345L1459.23 360.919C1459.19 349.251 1449.69 339.771 1438 339.771V344.644C1447.02 344.644 1454.35 351.974 1454.35 360.992V361.345C1454.35 370.363 1447.02 377.692 1438 377.692C1428.98 377.692 1421.68 370.363 1421.68 361.357L1421.65 323.788C1421.65 314.781 1428.98 307.44 1438 307.44C1447.04 307.44 1454.39 314.769 1454.39 323.763C1454.39 329.427 1456.59 334.812 1460.58 338.908C1464.61 343.052 1469.95 345.337 1475.6 345.337V340.463C1466.74 340.463 1459.27 332.806 1459.25 323.751C1459.25 314.757 1466.58 307.44 1475.58 307.44C1484.58 307.44 1491.93 314.769 1491.93 323.788L1491.95 361.357C1491.95 370.363 1484.62 377.692 1475.6 377.692Z"
          fill="white"
        />
        <path
          d="M8.86302 307.564C0.831242 307.564 -6.32567 302.656 -9.92235 295.634C-13.5069 302.656 -20.6638 307.552 -28.6956 307.552C-40.3969 307.552 -49.9111 298.04 -49.9111 286.34V248.801C-49.9111 237.101 -40.3969 227.589 -28.6956 227.589L8.86302 227.564C20.5522 227.564 30.0664 237.077 30.0664 248.776C30.0664 256.916 25.4369 264.011 18.6567 267.558C25.4369 271.118 30.0786 278.201 30.0786 286.34V286.668C29.9085 298.21 20.455 307.552 8.87516 307.552L8.86302 307.564ZM-7.48001 285.575C-7.48001 294.857 0.00497437 302.693 8.86302 302.693C17.7211 302.693 25.1939 295.367 25.1939 286.365V286.061C25.0359 277.18 17.7211 270 8.72936 270V265.129C17.8061 265.129 25.1939 257.791 25.1939 248.776C25.1939 239.762 17.8669 232.436 8.86302 232.436L-28.6956 232.46C-37.7116 232.46 -45.0386 239.786 -45.0386 248.801V286.34C-45.0386 295.355 -37.7116 302.681 -28.6956 302.681C-19.6796 302.681 -12.3525 294.845 -12.3525 285.575H-7.48001Z"
          fill={themeColor}
        />
        <path
          d="M25.1939 286.376C25.1939 295.379 17.8669 302.704 8.86305 302.704C-0.140808 302.704 -7.47998 294.868 -7.47998 285.587C-7.47998 273.474 -17.3952 262.844 -28.6955 262.844V267.716C-19.9833 267.716 -12.3525 276.074 -12.3525 285.587C-12.3525 295.099 -19.8375 302.692 -28.6955 302.692C-37.5536 302.692 -45.0386 295.366 -45.0386 286.352V248.812C-45.0386 239.798 -37.7115 232.472 -28.6955 232.472L8.86305 232.448C17.8669 232.448 25.1939 239.773 25.1939 248.788C25.1939 257.802 17.8061 265.14 8.72939 265.14V270.012C17.7089 270.012 25.036 277.192 25.1939 286.073V286.376Z"
          fill="white"
        />
        <path
          d="M25.0786 286.359H29.9608C29.9608 277.351 37.2902 270.036 46.2875 270.036C55.2848 270.036 62.6141 277.364 62.6141 286.359H67.4963C67.4963 274.673 57.9876 265.154 46.2875 265.154V265.118C37.278 265.118 29.9608 257.79 29.9608 248.794C29.9608 239.798 37.2902 232.47 46.2875 232.47L83.823 232.446C92.8325 232.446 100.15 239.774 100.15 248.77H105.032C105.032 237.071 95.5231 227.564 83.823 227.564L46.2875 227.589C34.5873 227.589 25.0786 237.096 25.0786 248.794C25.0786 256.95 29.7051 264.034 36.4622 267.577C29.7051 271.119 25.0786 278.216 25.0786 286.359Z"
          fill={themeColor}
        />
        <path
          d="M105.032 248.77H100.15C100.15 257.778 92.8203 265.094 83.823 265.094C74.8256 265.094 67.4963 257.766 67.4963 248.77H62.6141C62.6141 260.468 72.1228 269.975 83.823 269.975V270.012C92.8325 270.012 100.15 277.34 100.15 286.335C100.15 295.331 92.8203 302.659 83.823 302.659L46.2875 302.684C37.278 302.684 29.9608 295.356 29.9608 286.36H25.0786C25.0786 298.058 34.5873 307.565 46.2875 307.565L83.823 307.541C95.511 307.541 105.032 298.034 105.032 286.335C105.032 278.18 100.405 271.095 93.6482 267.553C100.405 264.01 105.032 256.914 105.032 248.77Z"
          fill={themeColor}
        />
        <path
          d="M83.9569 302.696L46.4214 302.721C37.4119 302.721 30.0947 295.393 30.0947 286.385C30.0947 277.377 37.4241 270.049 46.4214 270.049C55.4187 270.049 62.7481 277.377 62.7481 286.385H67.6302C67.6302 274.687 58.1216 265.167 46.4214 265.167C37.4119 265.167 30.0947 257.827 30.0947 248.819C30.0947 239.811 37.4241 232.496 46.4214 232.496L83.9569 232.471C92.9664 232.471 100.284 239.799 100.284 248.807C100.284 257.815 92.9542 265.131 83.9569 265.131C74.9596 265.131 67.6302 257.803 67.6302 248.807H62.7481C62.7481 260.505 72.2567 270.012 83.9569 270.012V270.049C92.9664 270.049 100.284 277.377 100.284 286.373C100.284 295.368 92.9542 302.696 83.9569 302.696Z"
          fill="white"
        />
        <circle cx="119.032" cy="267.565" r="6" fill="white" />
        <circle cx="119.032" cy="267.565" r="6" stroke="#04030F" />
        <path
          d="M213.046 248.786C213.046 237.081 203.527 227.564 191.821 227.564C183.688 227.564 176.613 232.171 173.063 238.917C169.501 232.183 162.414 227.564 154.257 227.564H154.245C142.55 227.564 133.032 237.081 133.032 248.786L133.056 286.355C133.056 298.048 142.562 307.564 154.257 307.564C162.414 307.564 169.501 302.946 173.051 296.176C176.601 302.934 183.688 307.564 191.845 307.564C203.552 307.564 213.07 298.048 213.07 286.355L213.046 248.786ZM191.857 302.691C182.837 302.691 175.507 295.361 175.507 286.343L175.482 285.918C175.446 274.249 165.939 264.769 154.257 264.769V269.643C163.277 269.643 170.607 276.972 170.607 285.99V286.343C170.607 295.361 163.277 302.691 154.257 302.691C145.237 302.691 137.931 295.361 137.931 286.355L137.907 248.786C137.907 239.78 145.237 232.438 154.257 232.438C163.289 232.438 170.644 239.767 170.644 248.762C170.644 254.426 172.844 259.81 176.832 263.906C180.868 268.051 186.204 270.336 191.857 270.336V265.462C182.995 265.462 175.519 257.804 175.507 248.75C175.507 239.755 182.837 232.438 191.833 232.438C200.828 232.438 208.183 239.767 208.183 248.786L208.207 286.355C208.207 295.361 200.877 302.691 191.857 302.691Z"
          fill={themeColor}
        />
        <path
          d="M191.857 302.692C182.837 302.692 175.506 295.363 175.506 286.344L175.482 285.919C175.445 274.25 165.939 264.77 154.257 264.77V269.644C163.277 269.644 170.607 276.973 170.607 285.992V286.344C170.607 295.363 163.277 302.692 154.257 302.692C145.237 302.692 137.931 295.363 137.931 286.356L137.906 248.787C137.906 239.781 145.237 232.439 154.257 232.439C163.289 232.439 170.644 239.769 170.644 248.763C170.644 254.427 172.844 259.811 176.831 263.907C180.867 268.052 186.204 270.337 191.857 270.337V265.463C182.995 265.463 175.518 257.806 175.506 248.751C175.506 239.756 182.837 232.439 191.832 232.439C200.828 232.439 208.183 239.769 208.183 248.787L208.207 286.356C208.207 295.363 200.877 302.692 191.857 302.692Z"
          fill="#04030F"
        />
        <path
          d="M266.806 227.589L229.264 227.564C217.945 227.564 208.665 236.502 208.095 247.686H208.07V286.362C208.07 298.056 217.58 307.564 229.264 307.564L266.806 307.54C278.502 307.54 288.011 298.032 288.011 286.338V248.791C288.011 237.097 278.502 227.589 266.806 227.589ZM266.806 302.659L229.264 302.683C220.264 302.683 212.941 295.36 212.941 286.35V262.258C216.851 266.994 222.778 270.017 229.386 270.017C241.009 270.017 250.47 260.485 250.47 248.767H245.6C245.6 257.801 238.325 265.148 229.373 265.148C220.422 265.148 212.928 257.789 212.928 248.754C212.928 239.72 220.252 232.422 229.252 232.422L266.793 232.446C275.805 232.446 283.129 239.768 283.129 248.779V286.326C283.129 295.336 275.805 302.659 266.793 302.659H266.806Z"
          fill={themeColor}
        />
        <path
          d="M229.264 302.696L266.806 302.672C275.818 302.672 283.141 295.349 283.141 286.339V248.792C283.141 239.782 275.818 232.459 266.806 232.459L229.264 232.435C220.265 232.435 212.941 239.757 212.941 248.768C212.941 257.778 220.313 265.161 229.386 265.161C238.458 265.161 245.612 257.815 245.612 248.78H250.482C250.482 260.498 241.021 270.031 229.398 270.031C222.779 270.031 216.864 267.007 212.953 262.271V286.363C212.953 295.374 220.277 302.696 229.276 302.696H229.264Z"
          fill="#04030F"
        />
        <path
          d="M341.849 307.564H304.261C292.554 307.564 283.036 298.048 283.036 286.343L283.011 248.774C283.011 237.081 292.53 227.564 304.236 227.564C312.406 227.564 319.505 232.207 323.055 238.977C326.604 232.195 333.704 227.564 341.873 227.564C353.58 227.564 363.098 237.081 363.098 248.774L363.074 286.343C363.074 298.035 353.555 307.564 341.849 307.564ZM304.236 232.438C295.216 232.438 287.886 239.767 287.886 248.774L287.91 286.343C287.91 295.361 295.241 302.691 304.261 302.691H341.849C350.869 302.691 358.199 295.361 358.199 286.343L358.223 248.774C358.223 239.767 350.893 232.438 341.873 232.438C332.853 232.438 325.522 239.767 325.522 248.786L325.498 272.633H320.623V249.284H320.599L320.575 248.786C320.575 239.767 313.244 232.438 304.224 232.438H304.236Z"
          fill={themeColor}
        />
        <path
          d="M304.236 232.439C295.216 232.439 287.886 239.769 287.886 248.775L287.91 286.344C287.91 295.363 295.24 302.692 304.261 302.692H341.848C350.869 302.692 358.199 295.363 358.199 286.344L358.223 248.775C358.223 239.769 350.893 232.439 341.873 232.439C332.853 232.439 325.522 239.769 325.522 248.787L325.498 272.634H320.623V249.285H320.599L320.575 248.799C320.575 239.781 313.244 232.452 304.224 232.452L304.236 232.439Z"
          fill="#04030F"
        />
        <path
          d="M438.112 248.786C438.112 237.081 428.594 227.564 416.887 227.564C408.754 227.564 401.679 232.171 398.129 238.917C394.568 232.183 387.48 227.564 379.323 227.564H379.311C367.617 227.564 358.098 237.081 358.098 248.786L358.122 286.355C358.122 298.048 367.629 307.564 379.323 307.564C387.48 307.564 394.568 302.946 398.117 296.176C401.667 302.934 408.754 307.564 416.911 307.564C428.618 307.564 438.136 298.048 438.136 286.355L438.112 248.786ZM416.923 302.691C407.903 302.691 400.573 295.361 400.573 286.343L400.549 285.918C400.512 274.249 391.006 264.769 379.323 264.769V269.643C388.343 269.643 395.674 276.972 395.674 285.99V286.343C395.674 295.361 388.343 302.691 379.323 302.691C370.303 302.691 362.997 295.361 362.997 286.355L362.973 248.786C362.973 239.78 370.303 232.438 379.323 232.438C388.356 232.438 395.71 239.767 395.71 248.762C395.71 254.426 397.911 259.81 401.898 263.906C405.934 268.051 411.271 270.336 416.923 270.336V265.462C408.061 265.462 400.585 257.804 400.573 248.75C400.573 239.755 407.903 232.438 416.899 232.438C425.895 232.438 433.25 239.767 433.25 248.786L433.274 286.355C433.274 295.361 425.944 302.691 416.923 302.691Z"
          fill={themeColor}
        />
        <path
          d="M416.923 302.692C407.903 302.692 400.573 295.363 400.573 286.344L400.548 285.919C400.512 274.25 391.006 264.77 379.323 264.77V269.644C388.343 269.644 395.674 276.973 395.674 285.992V286.344C395.674 295.363 388.343 302.692 379.323 302.692C370.303 302.692 362.997 295.363 362.997 286.356L362.973 248.787C362.973 239.781 370.303 232.439 379.323 232.439C388.355 232.439 395.71 239.769 395.71 248.763C395.71 254.427 397.91 259.811 401.898 263.907C405.934 268.052 411.27 270.337 416.923 270.337V265.463C408.061 265.463 400.585 257.806 400.573 248.751C400.573 239.756 407.903 232.439 416.899 232.439C425.895 232.439 433.249 239.769 433.249 248.787L433.274 286.356C433.274 295.363 425.943 302.692 416.923 302.692Z"
          fill="#04030F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M438.019 286.359H433.137C433.137 278.216 437.763 271.119 444.52 267.577C437.763 264.034 433.137 256.95 433.137 248.794C433.137 237.096 442.645 227.589 454.346 227.589L491.881 227.564C503.581 227.564 513.09 237.071 513.09 248.77H508.208C508.208 239.774 500.891 232.446 491.881 232.446L454.346 232.47C445.348 232.47 438.019 239.798 438.019 248.794C438.019 257.79 445.336 265.118 454.346 265.118V265.154C466.046 265.154 475.554 274.673 475.554 286.359H470.672C470.672 277.364 463.343 270.036 454.346 270.036C445.348 270.036 438.019 277.351 438.019 286.359ZM508.208 248.77H513.09C513.09 256.914 508.463 264.011 501.706 267.553C508.463 271.095 513.09 278.18 513.09 286.336C513.09 298.034 503.569 307.541 491.881 307.541L454.346 307.565C442.645 307.565 433.137 298.058 433.137 286.36H438.019C438.019 295.356 445.336 302.684 454.346 302.684L491.881 302.659C500.878 302.659 508.208 295.331 508.208 286.336C508.208 277.34 500.891 270.012 491.881 270.012V269.975C480.181 269.975 470.672 260.468 470.672 248.77H475.554C475.554 257.766 482.884 265.094 491.881 265.094C500.878 265.094 508.208 257.778 508.208 248.77Z"
          fill={themeColor}
        />
        <path
          d="M492.015 302.696L454.48 302.721C445.47 302.721 438.153 295.393 438.153 286.385C438.153 277.377 445.482 270.049 454.48 270.049C463.477 270.049 470.806 277.377 470.806 286.385H475.688C475.688 274.687 466.18 265.167 454.48 265.167C445.47 265.167 438.153 257.827 438.153 248.819C438.153 239.811 445.482 232.496 454.48 232.496L492.015 232.471C501.025 232.471 508.342 239.799 508.342 248.807C508.342 257.815 501.012 265.131 492.015 265.131C483.018 265.131 475.688 257.803 475.688 248.807H470.806C470.806 260.505 480.315 270.012 492.015 270.012V270.049C501.025 270.049 508.342 277.377 508.342 286.373C508.342 295.368 501.012 302.696 492.015 302.696Z"
          fill="#04030F"
        />
        <circle cx="527.09" cy="267.565" r="6" fill="white" />
        <circle cx="527.09" cy="267.565" r="6" stroke="#04030F" />
        <path
          d="M621.104 248.786C621.104 237.082 611.585 227.565 599.879 227.565C591.746 227.565 584.671 232.171 581.121 238.917C577.559 232.184 570.472 227.565 562.315 227.565H562.303C550.608 227.565 541.09 237.082 541.09 248.786L541.114 286.356C541.114 298.048 550.621 307.565 562.315 307.565C570.472 307.565 577.559 302.946 581.109 296.176C584.659 302.934 591.746 307.565 599.903 307.565C611.61 307.565 621.128 298.048 621.128 286.356L621.104 248.786ZM599.915 302.691C590.895 302.691 583.565 295.362 583.565 286.343L583.54 285.918C583.504 274.25 573.997 264.769 562.315 264.769V269.643C571.335 269.643 578.666 276.972 578.666 285.991V286.343C578.666 295.362 571.335 302.691 562.315 302.691C553.295 302.691 545.989 295.362 545.989 286.356L545.965 248.786C545.965 239.78 553.295 232.439 562.315 232.439C571.347 232.439 578.702 239.768 578.702 248.762C578.702 254.426 580.902 259.81 584.89 263.906C588.926 268.051 594.262 270.336 599.915 270.336V265.462C591.053 265.462 583.577 257.805 583.565 248.75C583.565 239.756 590.895 232.439 599.891 232.439C608.887 232.439 616.241 239.768 616.241 248.786L616.266 286.356C616.266 295.362 608.935 302.691 599.915 302.691Z"
          fill={themeColor}
        />
        <path
          d="M599.915 302.692C590.895 302.692 583.564 295.363 583.564 286.345L583.54 285.919C583.504 274.251 573.997 264.771 562.315 264.771V269.644C571.335 269.644 578.665 276.974 578.665 285.992V286.345C578.665 295.363 571.335 302.692 562.315 302.692C553.295 302.692 545.989 295.363 545.989 286.357L545.964 248.788C545.964 239.781 553.295 232.44 562.315 232.44C571.347 232.44 578.702 239.769 578.702 248.763C578.702 254.427 580.902 259.812 584.889 263.908C588.925 268.052 594.262 270.337 599.915 270.337V265.463C591.053 265.463 583.577 257.806 583.564 248.751C583.564 239.757 590.895 232.44 599.891 232.44C608.886 232.44 616.241 239.769 616.241 248.788L616.265 286.357C616.265 295.363 608.935 302.692 599.915 302.692Z"
          fill="white"
        />
        <path
          d="M698.142 248.786C698.142 237.082 688.624 227.565 676.917 227.565C668.785 227.565 661.709 232.171 658.16 238.917C654.598 232.184 647.511 227.565 639.354 227.565H639.341C627.647 227.565 618.128 237.082 618.128 248.786L618.153 286.356C618.153 298.048 627.659 307.565 639.354 307.565C647.511 307.565 654.598 302.946 658.148 296.176C661.697 302.934 668.785 307.565 676.942 307.565C688.648 307.565 698.167 298.048 698.167 286.356L698.142 248.786ZM676.954 302.691C667.934 302.691 660.603 295.362 660.603 286.343L660.579 285.918C660.542 274.25 651.036 264.769 639.354 264.769V269.643C648.374 269.643 655.704 276.972 655.704 285.991V286.343C655.704 295.362 648.374 302.691 639.354 302.691C630.334 302.691 623.027 295.362 623.027 286.356L623.003 248.786C623.003 239.78 630.334 232.439 639.354 232.439C648.386 232.439 655.741 239.768 655.741 248.762C655.741 254.426 657.941 259.81 661.928 263.906C665.964 268.051 671.301 270.336 676.954 270.336V265.462C668.092 265.462 660.615 257.805 660.603 248.75C660.603 239.756 667.934 232.439 676.929 232.439C685.925 232.439 693.28 239.768 693.28 248.786L693.304 286.356C693.304 295.362 685.974 302.691 676.954 302.691Z"
          fill={themeColor}
        />
        <path
          d="M676.953 302.692C667.933 302.692 660.603 295.363 660.603 286.345L660.579 285.919C660.542 274.251 651.036 264.771 639.353 264.771V269.644C648.374 269.644 655.704 276.974 655.704 285.992V286.345C655.704 295.363 648.374 302.692 639.353 302.692C630.333 302.692 623.027 295.363 623.027 286.357L623.003 248.788C623.003 239.781 630.333 232.44 639.353 232.44C648.386 232.44 655.74 239.769 655.74 248.763C655.74 254.427 657.941 259.812 661.928 263.908C665.964 268.052 671.301 270.337 676.953 270.337V265.463C668.091 265.463 660.615 257.806 660.603 248.751C660.603 239.757 667.933 232.44 676.929 232.44C685.925 232.44 693.28 239.769 693.28 248.788L693.304 286.357C693.304 295.363 685.974 302.692 676.953 302.692Z"
          fill="white"
        />
        <path
          d="M695.167 286.359H700.049C700.049 277.351 707.378 270.036 716.375 270.036C725.373 270.036 732.702 277.364 732.702 286.359H737.584C737.584 274.673 728.076 265.154 716.375 265.154V265.118C707.366 265.118 700.049 257.79 700.049 248.794C700.049 239.798 707.378 232.47 716.375 232.47L753.911 232.446C762.92 232.446 770.238 239.774 770.238 248.77H775.12C775.12 237.071 765.611 227.564 753.911 227.564L716.375 227.589C704.675 227.589 695.167 237.096 695.167 248.794C695.167 256.95 699.793 264.034 706.55 267.577C699.793 271.119 695.167 278.216 695.167 286.359Z"
          fill={themeColor}
        />
        <path
          d="M775.12 248.77H770.238C770.238 257.778 762.908 265.094 753.911 265.094C744.914 265.094 737.584 257.766 737.584 248.77H732.702C732.702 260.468 742.211 269.975 753.911 269.975V270.012C762.92 270.012 770.238 277.34 770.238 286.335C770.238 295.331 762.908 302.659 753.911 302.659L716.375 302.684C707.366 302.684 700.049 295.356 700.049 286.36H695.167C695.167 298.058 704.675 307.565 716.375 307.565L753.911 307.541C765.599 307.541 775.12 298.034 775.12 286.335C775.12 278.18 770.493 271.095 763.736 267.553C770.493 264.01 775.12 256.914 775.12 248.77Z"
          fill={themeColor}
        />
        <path
          d="M754.045 302.696L716.509 302.721C707.5 302.721 700.183 295.393 700.183 286.385C700.183 277.377 707.512 270.049 716.509 270.049C725.507 270.049 732.836 277.377 732.836 286.385H737.718C737.718 274.687 728.209 265.167 716.509 265.167C707.5 265.167 700.183 257.827 700.183 248.819C700.183 239.811 707.512 232.496 716.509 232.496L754.045 232.471C763.054 232.471 770.371 239.799 770.371 248.807C770.371 257.815 763.042 265.131 754.045 265.131C745.047 265.131 737.718 257.803 737.718 248.807H732.836C732.836 260.505 742.345 270.012 754.045 270.012V270.049C763.054 270.049 770.371 277.377 770.371 286.373C770.371 295.368 763.042 302.696 754.045 302.696Z"
          fill="white"
        />
        <circle cx="789.12" cy="267.565" r="6" fill="white" />
        <circle cx="789.12" cy="267.565" r="6" stroke="#04030F" />
        <path
          d="M807.995 248.775C807.995 239.769 815.325 232.439 824.345 232.439C833.365 232.439 840.696 239.769 840.696 248.787L840.732 286.356H845.607C845.607 277.338 852.937 270.009 861.957 270.009C870.977 270.009 878.308 277.338 878.308 286.356C878.308 295.375 870.977 302.704 861.957 302.704H824.369C815.349 302.704 808.019 295.375 808.019 286.356L807.995 248.787V248.775Z"
          fill="white"
        />
        <path
          d="M883.183 286.343C883.183 274.65 873.664 265.134 861.958 265.134C855.381 265.134 849.485 268.148 845.595 272.852L845.571 248.786C845.571 237.081 836.052 227.564 824.345 227.564C812.639 227.564 803.12 237.081 803.12 248.774L803.144 286.343C803.144 298.048 812.663 307.564 824.37 307.564H861.958C873.652 307.564 883.171 298.048 883.183 286.355V286.343ZM861.958 302.691H824.37C815.35 302.691 808.019 295.361 808.019 286.343L807.995 248.774C807.995 239.767 815.325 232.438 824.345 232.438C833.365 232.438 840.696 239.767 840.696 248.786L840.732 286.343H845.607C845.607 277.324 852.937 269.995 861.958 269.995C870.978 269.995 878.308 277.324 878.308 286.343C878.308 295.361 870.978 302.691 861.958 302.691Z"
          fill={themeColor}
        />
        <path
          d="M899.432 307.564C887.725 307.564 878.207 298.048 878.207 286.343L878.183 248.774C878.183 237.081 887.701 227.564 899.408 227.564C911.115 227.564 920.633 237.081 920.633 248.786V286.367C920.633 298.048 911.127 307.552 899.444 307.552L899.432 307.564ZM899.408 232.438C890.388 232.438 883.057 239.767 883.057 248.774L883.082 286.343C883.082 295.361 890.412 302.691 899.432 302.691C908.452 302.691 915.758 295.374 915.758 286.367V248.786C915.758 239.767 908.428 232.438 899.408 232.438Z"
          fill={themeColor}
        />
        <path
          d="M899.395 232.439C890.375 232.439 883.045 239.769 883.045 248.775L883.069 286.344C883.069 295.363 890.4 302.692 899.42 302.692C908.44 302.692 915.746 295.375 915.746 286.368V248.787C915.746 239.769 908.416 232.439 899.395 232.439Z"
          fill="white"
        />
        <path
          d="M920.508 248.775C920.508 239.769 927.838 232.439 936.858 232.439C945.878 232.439 953.209 239.769 953.209 248.787L953.245 286.356H958.12C958.12 277.338 965.45 270.009 974.47 270.009C983.491 270.009 990.821 277.338 990.821 286.356C990.821 295.375 983.491 302.704 974.47 302.704H936.883C927.862 302.704 920.532 295.375 920.532 286.356L920.508 248.787V248.775Z"
          fill="white"
        />
        <path
          d="M995.696 286.343C995.696 274.65 986.177 265.134 974.471 265.134C967.894 265.134 961.998 268.148 958.108 272.852L958.084 248.786C958.084 237.081 948.565 227.564 936.859 227.564C925.152 227.564 915.633 237.081 915.633 248.774L915.658 286.343C915.658 298.048 925.176 307.564 936.883 307.564H974.471C986.165 307.564 995.684 298.048 995.696 286.355V286.343ZM974.471 302.691H936.883C927.863 302.691 920.532 295.361 920.532 286.343L920.508 248.774C920.508 239.767 927.838 232.438 936.859 232.438C945.879 232.438 953.209 239.767 953.209 248.786L953.245 286.343H958.12C958.12 277.324 965.451 269.995 974.471 269.995C983.491 269.995 990.821 277.324 990.821 286.343C990.821 295.361 983.491 302.691 974.471 302.691Z"
          fill={themeColor}
        />
        <path
          d="M1070.71 248.786C1070.71 237.081 1061.19 227.564 1049.48 227.564C1041.35 227.564 1034.28 232.171 1030.73 238.917C1027.17 232.183 1020.08 227.564 1011.92 227.564H1011.91C1000.21 227.564 990.696 237.081 990.696 248.786L990.72 286.355C990.72 298.048 1000.23 307.564 1011.92 307.564C1020.08 307.564 1027.17 302.946 1030.71 296.176C1034.26 302.934 1041.35 307.564 1049.51 307.564C1061.22 307.564 1070.73 298.048 1070.73 286.355L1070.71 248.786ZM1049.52 302.691C1040.5 302.691 1033.17 295.361 1033.17 286.343L1033.15 285.918C1033.11 274.249 1023.6 264.769 1011.92 264.769V269.643C1020.94 269.643 1028.27 276.972 1028.27 285.99V286.343C1028.27 295.361 1020.94 302.691 1011.92 302.691C1002.9 302.691 995.595 295.361 995.595 286.355L995.571 248.786C995.571 239.78 1002.9 232.438 1011.92 232.438C1020.95 232.438 1028.31 239.767 1028.31 248.762C1028.31 254.426 1030.51 259.81 1034.5 263.906C1038.53 268.051 1043.87 270.336 1049.52 270.336V265.462C1040.66 265.462 1033.18 257.804 1033.17 248.75C1033.17 239.755 1040.5 232.438 1049.5 232.438C1058.49 232.438 1065.85 239.767 1065.85 248.786L1065.87 286.355C1065.87 295.361 1058.54 302.691 1049.52 302.691Z"
          fill={themeColor}
        />
        <path
          d="M1049.52 302.692C1040.5 302.692 1033.17 295.363 1033.17 286.344L1033.15 285.919C1033.11 274.25 1023.6 264.77 1011.92 264.77V269.644C1020.94 269.644 1028.27 276.973 1028.27 285.992V286.344C1028.27 295.363 1020.94 302.692 1011.92 302.692C1002.9 302.692 995.595 295.363 995.595 286.356L995.57 248.787C995.57 239.781 1002.9 232.439 1011.92 232.439C1020.95 232.439 1028.31 239.769 1028.31 248.763C1028.31 254.427 1030.51 259.811 1034.5 263.907C1038.53 268.052 1043.87 270.337 1049.52 270.337V265.463C1040.66 265.463 1033.18 257.806 1033.17 248.751C1033.17 239.756 1040.5 232.439 1049.5 232.439C1058.49 232.439 1065.85 239.769 1065.85 248.787L1065.87 286.356C1065.87 295.363 1058.54 302.692 1049.52 302.692Z"
          fill="white"
        />
        <path
          d="M1124.47 227.589L1086.93 227.564C1075.61 227.564 1066.33 236.502 1065.76 247.686H1065.73V286.362C1065.73 298.056 1075.24 307.564 1086.93 307.564L1124.47 307.54C1136.17 307.54 1145.68 298.032 1145.68 286.338V248.791C1145.68 237.097 1136.17 227.589 1124.47 227.589ZM1124.47 302.659L1086.93 302.683C1077.93 302.683 1070.6 295.36 1070.6 286.35V262.258C1074.52 266.994 1080.44 270.017 1087.05 270.017C1098.67 270.017 1108.13 260.485 1108.13 248.767H1103.26C1103.26 257.801 1095.99 265.148 1087.04 265.148C1078.09 265.148 1070.59 257.789 1070.59 248.754C1070.59 239.72 1077.92 232.422 1086.92 232.422L1124.46 232.446C1133.47 232.446 1140.79 239.768 1140.79 248.779V286.326C1140.79 295.336 1133.47 302.659 1124.46 302.659H1124.47Z"
          fill={themeColor}
        />
        <path
          d="M1086.93 302.696L1124.47 302.672C1133.48 302.672 1140.81 295.349 1140.81 286.339V248.792C1140.81 239.782 1133.48 232.459 1124.47 232.459L1086.93 232.435C1077.93 232.435 1070.6 239.757 1070.6 248.768C1070.6 257.778 1077.98 265.161 1087.05 265.161C1096.12 265.161 1103.28 257.815 1103.28 248.78H1108.15C1108.15 260.498 1098.68 270.031 1087.06 270.031C1080.44 270.031 1074.53 267.007 1070.62 262.271V286.363C1070.62 295.374 1077.94 302.696 1086.94 302.696H1086.93Z"
          fill="white"
        />
        <path
          d="M1199.51 307.564H1161.92C1150.22 307.564 1140.7 298.048 1140.7 286.343L1140.68 248.774C1140.68 237.081 1150.19 227.564 1161.9 227.564C1170.07 227.564 1177.17 232.207 1180.72 238.977C1184.27 232.195 1191.37 227.564 1199.54 227.564C1211.24 227.564 1220.76 237.081 1220.76 248.774L1220.74 286.343C1220.74 298.035 1211.22 307.564 1199.51 307.564ZM1161.9 232.438C1152.88 232.438 1145.55 239.767 1145.55 248.774L1145.57 286.343C1145.57 295.361 1152.9 302.691 1161.92 302.691H1199.51C1208.53 302.691 1215.86 295.361 1215.86 286.343L1215.89 248.774C1215.89 239.767 1208.56 232.438 1199.54 232.438C1190.52 232.438 1183.19 239.767 1183.19 248.786L1183.16 272.633H1178.29V249.284H1178.26L1178.24 248.786C1178.24 239.767 1170.91 232.438 1161.89 232.438H1161.9Z"
          fill={themeColor}
        />
        <path
          d="M1161.9 232.439C1152.88 232.439 1145.55 239.769 1145.55 248.775L1145.57 286.344C1145.57 295.363 1152.9 302.692 1161.92 302.692H1199.51C1208.53 302.692 1215.86 295.363 1215.86 286.344L1215.89 248.775C1215.89 239.769 1208.56 232.439 1199.54 232.439C1190.52 232.439 1183.19 239.769 1183.19 248.787L1183.16 272.634H1178.29V249.285H1178.26L1178.24 248.799C1178.24 239.781 1170.91 232.452 1161.89 232.452L1161.9 232.439Z"
          fill="white"
        />
        <path
          d="M1295.78 248.786C1295.78 237.081 1286.26 227.564 1274.55 227.564C1266.42 227.564 1259.34 232.171 1255.79 238.917C1252.23 232.183 1245.14 227.564 1236.99 227.564H1236.98C1225.28 227.564 1215.76 237.081 1215.76 248.786L1215.79 286.355C1215.79 298.048 1225.29 307.564 1236.99 307.564C1245.14 307.564 1252.23 302.946 1255.78 296.176C1259.33 302.934 1266.42 307.564 1274.58 307.564C1286.28 307.564 1295.8 298.048 1295.8 286.355L1295.78 248.786ZM1274.59 302.691C1265.57 302.691 1258.24 295.361 1258.24 286.343L1258.21 285.918C1258.18 274.249 1248.67 264.769 1236.99 264.769V269.643C1246.01 269.643 1253.34 276.972 1253.34 285.99V286.343C1253.34 295.361 1246.01 302.691 1236.99 302.691C1227.97 302.691 1220.66 295.361 1220.66 286.355L1220.64 248.786C1220.64 239.78 1227.97 232.438 1236.99 232.438C1246.02 232.438 1253.37 239.767 1253.37 248.762C1253.37 254.426 1255.57 259.81 1259.56 263.906C1263.6 268.051 1268.93 270.336 1274.59 270.336V265.462C1265.73 265.462 1258.25 257.804 1258.24 248.75C1258.24 239.755 1265.57 232.438 1274.56 232.438C1283.56 232.438 1290.91 239.767 1290.91 248.786L1290.94 286.355C1290.94 295.361 1283.61 302.691 1274.59 302.691Z"
          fill={themeColor}
        />
        <path
          d="M1274.59 302.692C1265.57 302.692 1258.24 295.363 1258.24 286.344L1258.21 285.919C1258.18 274.25 1248.67 264.77 1236.99 264.77V269.644C1246.01 269.644 1253.34 276.973 1253.34 285.992V286.344C1253.34 295.363 1246.01 302.692 1236.99 302.692C1227.97 302.692 1220.66 295.363 1220.66 286.356L1220.64 248.787C1220.64 239.781 1227.97 232.439 1236.99 232.439C1246.02 232.439 1253.37 239.769 1253.37 248.763C1253.37 254.427 1255.57 259.811 1259.56 263.907C1263.6 268.052 1268.93 270.337 1274.59 270.337V265.463C1265.73 265.463 1258.25 257.806 1258.24 248.751C1258.24 239.756 1265.57 232.439 1274.56 232.439C1283.56 232.439 1290.91 239.769 1290.91 248.787L1290.94 286.356C1290.94 295.363 1283.61 302.692 1274.59 302.692Z"
          fill="white"
        />
        <path
          d="M1290.8 286.359H1295.68C1295.68 277.351 1303.01 270.036 1312.01 270.036C1321.01 270.036 1328.34 277.364 1328.34 286.359H1333.22C1333.22 274.673 1323.71 265.154 1312.01 265.154V265.118C1303 265.118 1295.68 257.79 1295.68 248.794C1295.68 239.798 1303.01 232.47 1312.01 232.47L1349.55 232.446C1358.55 232.446 1365.87 239.774 1365.87 248.77H1370.75C1370.75 237.071 1361.25 227.564 1349.55 227.564L1312.01 227.589C1300.31 227.589 1290.8 237.096 1290.8 248.794C1290.8 256.95 1295.43 264.034 1302.18 267.577C1295.43 271.119 1290.8 278.216 1290.8 286.359Z"
          fill={themeColor}
        />
        <path
          d="M1370.75 248.77H1365.87C1365.87 257.778 1358.54 265.094 1349.55 265.094C1340.55 265.094 1333.22 257.766 1333.22 248.77H1328.34C1328.34 260.468 1337.84 269.975 1349.55 269.975V270.012C1358.55 270.012 1365.87 277.34 1365.87 286.335C1365.87 295.331 1358.54 302.659 1349.55 302.659L1312.01 302.684C1303 302.684 1295.68 295.356 1295.68 286.36H1290.8C1290.8 298.058 1300.31 307.565 1312.01 307.565L1349.55 307.541C1361.23 307.541 1370.75 298.034 1370.75 286.335C1370.75 278.18 1366.13 271.095 1359.37 267.553C1366.13 264.01 1370.75 256.914 1370.75 248.77Z"
          fill={themeColor}
        />
        <path
          d="M1349.68 302.696L1312.14 302.721C1303.13 302.721 1295.82 295.393 1295.82 286.385C1295.82 277.377 1303.15 270.049 1312.14 270.049C1321.14 270.049 1328.47 277.377 1328.47 286.385H1333.35C1333.35 274.687 1323.84 265.167 1312.14 265.167C1303.13 265.167 1295.82 257.827 1295.82 248.819C1295.82 239.811 1303.15 232.496 1312.14 232.496L1349.68 232.471C1358.69 232.471 1366.01 239.799 1366.01 248.807C1366.01 257.815 1358.68 265.131 1349.68 265.131C1340.68 265.131 1333.35 257.803 1333.35 248.807H1328.47C1328.47 260.505 1337.98 270.012 1349.68 270.012V270.049C1358.69 270.049 1366.01 277.377 1366.01 286.373C1366.01 295.368 1358.68 302.696 1349.68 302.696Z"
          fill="white"
        />
        <circle cx="1384.75" cy="267.565" r="6" fill="white" />
        <circle cx="1384.75" cy="267.565" r="6" stroke="#04030F" />
        <path
          d="M1467.35 267.564C1474.09 264.005 1478.71 256.924 1478.71 248.773C1478.7 237.076 1469.18 227.564 1457.49 227.564H1419.95C1408.27 227.564 1398.75 237.088 1398.75 248.785L1398.78 286.332V286.356H1398.75C1398.75 298.053 1408.27 307.564 1419.95 307.564L1457.51 307.54C1469.21 307.54 1478.72 298.029 1478.72 286.332C1478.72 278.193 1474.1 271.124 1467.36 267.564H1467.35ZM1457.49 302.669L1419.97 302.694V302.657C1410.96 302.657 1403.65 295.332 1403.65 286.332L1403.63 248.785C1403.63 239.772 1410.95 232.435 1419.95 232.435H1457.49C1466.51 232.435 1473.84 239.772 1473.84 248.773C1473.84 257.774 1466.51 265.135 1457.51 265.135C1445.81 265.135 1436.28 274.646 1436.28 286.344H1441.15C1441.15 277.331 1448.49 269.994 1457.51 269.994C1466.52 269.994 1473.84 277.318 1473.84 286.319C1473.84 295.32 1466.51 302.657 1457.49 302.657V302.669Z"
          fill={themeColor}
        />
        <path
          d="M1457.48 302.67L1419.95 302.695V302.658C1410.95 302.658 1403.64 295.334 1403.64 286.333L1403.61 248.786C1403.61 239.773 1410.94 232.437 1419.94 232.437H1457.49C1466.51 232.437 1473.84 239.773 1473.84 248.774C1473.84 257.775 1466.51 265.136 1457.51 265.136C1445.81 265.136 1436.28 274.647 1436.28 286.345H1441.15C1441.15 277.332 1448.49 269.995 1457.51 269.995C1466.52 269.995 1473.84 277.32 1473.84 286.321C1473.84 295.321 1466.51 302.658 1457.49 302.658L1457.48 302.67Z"
          fill="white"
        />
        <path
          d="M1553.73 248.786C1553.73 237.081 1544.21 227.564 1532.51 227.564C1524.38 227.564 1517.3 232.171 1513.75 238.917C1510.19 232.183 1503.1 227.564 1494.94 227.564H1494.93C1483.24 227.564 1473.72 237.081 1473.72 248.786L1473.74 286.355C1473.74 298.048 1483.25 307.564 1494.94 307.564C1503.1 307.564 1510.19 302.946 1513.74 296.176C1517.29 302.934 1524.38 307.564 1532.53 307.564C1544.24 307.564 1553.76 298.048 1553.76 286.355L1553.73 248.786ZM1532.54 302.691C1523.52 302.691 1516.19 295.361 1516.19 286.343L1516.17 285.918C1516.13 274.249 1506.63 264.769 1494.94 264.769V269.643C1503.96 269.643 1511.29 276.972 1511.29 285.99V286.343C1511.29 295.361 1503.96 302.691 1494.94 302.691C1485.92 302.691 1478.62 295.361 1478.62 286.355L1478.59 248.786C1478.59 239.78 1485.92 232.438 1494.94 232.438C1503.98 232.438 1511.33 239.767 1511.33 248.762C1511.33 254.426 1513.53 259.81 1517.52 263.906C1521.56 268.051 1526.89 270.336 1532.54 270.336V265.462C1523.68 265.462 1516.21 257.804 1516.19 248.75C1516.19 239.755 1523.52 232.438 1532.52 232.438C1541.52 232.438 1548.87 239.767 1548.87 248.786L1548.89 286.355C1548.89 295.361 1541.56 302.691 1532.54 302.691Z"
          fill={themeColor}
        />
        <path
          d="M1532.54 302.692C1523.52 302.692 1516.19 295.363 1516.19 286.344L1516.17 285.919C1516.13 274.25 1506.63 264.77 1494.94 264.77V269.644C1503.96 269.644 1511.29 276.973 1511.29 285.992V286.344C1511.29 295.363 1503.96 302.692 1494.94 302.692C1485.92 302.692 1478.62 295.363 1478.62 286.356L1478.59 248.787C1478.59 239.781 1485.92 232.439 1494.94 232.439C1503.98 232.439 1511.33 239.769 1511.33 248.763C1511.33 254.427 1513.53 259.811 1517.52 263.907C1521.55 268.052 1526.89 270.337 1532.54 270.337V265.463C1523.68 265.463 1516.21 257.806 1516.19 248.751C1516.19 239.756 1523.52 232.439 1532.52 232.439C1541.52 232.439 1548.87 239.769 1548.87 248.787L1548.89 286.356C1548.89 295.363 1541.56 302.692 1532.54 302.692Z"
          fill="white"
        />
        <path
          d="M8.0287 98.786C8.0287 87.0813 -1.48986 77.5645 -13.1966 77.5645C-21.3293 77.5645 -28.4043 82.171 -31.954 88.9166C-35.5159 82.1831 -42.6031 77.5645 -50.7601 77.5645H-50.7723C-62.4668 77.5645 -71.9854 87.0813 -71.9854 98.786L-71.9611 136.355C-71.9611 148.048 -62.4547 157.564 -50.7601 157.564C-42.6031 157.564 -35.5159 152.946 -31.9662 146.176C-28.4165 152.934 -21.3292 157.564 -13.1722 157.564C-1.46552 157.564 8.053 148.048 8.053 136.355L8.0287 98.786ZM-13.1601 152.691C-22.1802 152.691 -29.5106 145.361 -29.5106 136.343L-29.5349 135.918C-29.5714 124.249 -39.0777 114.769 -50.7601 114.769V119.643C-41.74 119.643 -34.4096 126.972 -34.4096 135.99V136.343C-34.4096 145.361 -41.74 152.691 -50.7601 152.691C-59.7802 152.691 -67.0863 145.361 -67.0863 136.355L-67.1106 98.786C-67.1106 89.7796 -59.7802 82.4384 -50.7601 82.4384C-41.7278 82.4384 -34.3732 89.7674 -34.3732 98.7617C-34.3732 104.426 -32.1728 109.81 -28.1855 113.906C-24.1496 118.051 -18.8129 120.336 -13.1601 120.336V115.462C-22.0222 115.462 -29.4984 107.804 -29.5106 98.7495C-29.5106 89.7553 -22.1802 82.4384 -13.1844 82.4384C-4.18859 82.4384 3.1661 89.7674 3.1661 98.786L3.1904 136.355C3.1904 145.361 -4.13998 152.691 -13.1601 152.691Z"
          fill={themeColor}
        />
        <path
          d="M-13.1604 152.692C-22.1805 152.692 -29.5108 145.363 -29.5108 136.344L-29.5352 135.919C-29.5716 124.25 -39.078 114.77 -50.7604 114.77V119.644C-41.7403 119.644 -34.4099 126.973 -34.4099 135.992V136.344C-34.4099 145.363 -41.7403 152.692 -50.7604 152.692C-59.7805 152.692 -67.0865 145.363 -67.0865 136.356L-67.1108 98.7871C-67.1108 89.7807 -59.7805 82.4395 -50.7604 82.4395C-41.7281 82.4395 -34.3734 89.7685 -34.3734 98.7628C-34.3734 104.427 -32.1731 109.811 -28.1858 113.907C-24.1498 118.052 -18.8131 120.337 -13.1604 120.337V115.463C-22.0224 115.463 -29.4987 107.806 -29.5108 98.7506C-29.5108 89.7564 -22.1805 82.4395 -13.1847 82.4395C-4.18885 82.4395 3.16585 89.7685 3.16585 98.7871L3.19015 136.356C3.19015 145.363 -4.14023 152.692 -13.1604 152.692Z"
          fill="#04030F"
        />
        <path
          d="M61.7885 77.5887L24.247 77.5645C12.9274 77.5645 3.64834 86.5019 3.0775 97.6859H3.05322V136.362C3.05322 148.056 12.5631 157.564 24.247 157.564L61.7885 157.54C73.4845 157.54 82.9944 148.032 82.9944 136.338V98.7909C82.9944 87.0969 73.4845 77.5887 61.7885 77.5887ZM61.7885 152.659L24.247 152.683C15.2472 152.683 7.92355 145.36 7.92355 136.35V112.258C11.8344 116.994 17.7613 120.017 24.3684 120.017C35.9916 120.017 45.4529 110.485 45.4529 98.7666H40.5826C40.5826 107.801 33.3075 115.148 24.3563 115.148C15.4051 115.148 7.91139 107.789 7.91139 98.7545C7.91139 89.7199 15.2351 82.4218 24.2348 82.4218L61.7763 82.4461C70.7883 82.4461 78.1119 89.7685 78.1119 98.7788V136.326C78.1119 145.336 70.7883 152.659 61.7763 152.659H61.7885Z"
          fill={themeColor}
        />
        <path
          d="M24.2472 152.696L61.7887 152.672C70.8007 152.672 78.1244 145.349 78.1244 136.339V98.7921C78.1244 89.7818 70.8007 82.4593 61.7887 82.4593L24.2472 82.4351C15.2475 82.4351 7.92383 89.7575 7.92383 98.7678C7.92383 107.778 15.2961 115.161 24.3687 115.161C33.4413 115.161 40.595 107.815 40.595 98.7799H45.4653C45.4653 110.498 36.004 120.031 24.3809 120.031C17.7616 120.031 11.8468 117.007 7.93595 112.271V136.363C7.93595 145.374 15.2596 152.696 24.2594 152.696H24.2472Z"
          fill="#04030F"
        />
        <path
          d="M136.832 157.564H99.2437C87.537 157.564 78.0184 148.048 78.0184 136.343L77.9941 98.7738C77.9941 87.0813 87.5127 77.5645 99.2194 77.5645C107.389 77.5645 114.488 82.2074 118.038 88.9774C121.587 82.1953 128.687 77.5645 136.856 77.5645C148.563 77.5645 158.081 87.0813 158.081 98.7738L158.057 136.343C158.057 148.035 148.538 157.564 136.832 157.564ZM99.2194 82.4384C90.1993 82.4384 82.8689 89.7674 82.8689 98.7738L82.8932 136.343C82.8932 145.361 90.2235 152.691 99.2437 152.691H136.832C145.852 152.691 153.182 145.361 153.182 136.343L153.206 98.7738C153.206 89.7674 145.876 82.4384 136.856 82.4384C127.836 82.4384 120.505 89.7674 120.505 98.786L120.481 122.633H115.606V99.2843H115.582L115.558 98.786C115.558 89.7674 108.227 82.4384 99.2072 82.4384H99.2194Z"
          fill={themeColor}
        />
        <path
          d="M99.2191 82.4395C90.199 82.4395 82.8687 89.7685 82.8687 98.7749L82.893 136.344C82.893 145.363 90.2233 152.692 99.2434 152.692H136.831C145.851 152.692 153.182 145.363 153.182 136.344L153.206 98.7749C153.206 89.7685 145.876 82.4395 136.856 82.4395C127.835 82.4395 120.505 89.7685 120.505 98.7871L120.481 122.634H115.606V99.2854H115.582L115.557 98.7992C115.557 89.7807 108.227 82.4516 99.2069 82.4516L99.2191 82.4395Z"
          fill="#04030F"
        />
        <path
          d="M233.095 98.786C233.095 87.0813 223.577 77.5645 211.87 77.5645C203.737 77.5645 196.662 82.171 193.112 88.9166C189.551 82.1831 182.463 77.5645 174.306 77.5645H174.294C162.6 77.5645 153.081 87.0813 153.081 98.786L153.105 136.355C153.105 148.048 162.612 157.564 174.306 157.564C182.463 157.564 189.551 152.946 193.1 146.176C196.65 152.934 203.737 157.564 211.894 157.564C223.601 157.564 233.119 148.048 233.119 136.355L233.095 98.786ZM211.906 152.691C202.886 152.691 195.556 145.361 195.556 136.343L195.532 135.918C195.495 124.249 185.989 114.769 174.306 114.769V119.643C183.326 119.643 190.657 126.972 190.657 135.99V136.343C190.657 145.361 183.326 152.691 174.306 152.691C165.286 152.691 157.98 145.361 157.98 136.355L157.956 98.786C157.956 89.7796 165.286 82.4384 174.306 82.4384C183.339 82.4384 190.693 89.7674 190.693 98.7617C190.693 104.426 192.894 109.81 196.881 113.906C200.917 118.051 206.254 120.336 211.906 120.336V115.462C203.044 115.462 195.568 107.804 195.556 98.7495C195.556 89.7553 202.886 82.4384 211.882 82.4384C220.878 82.4384 228.233 89.7674 228.233 98.786L228.257 136.355C228.257 145.361 220.926 152.691 211.906 152.691Z"
          fill={themeColor}
        />
        <path
          d="M211.906 152.692C202.886 152.692 195.556 145.363 195.556 136.344L195.531 135.919C195.495 124.25 185.988 114.77 174.306 114.77V119.644C183.326 119.644 190.657 126.973 190.657 135.992V136.344C190.657 145.363 183.326 152.692 174.306 152.692C165.286 152.692 157.98 145.363 157.98 136.356L157.956 98.7871C157.956 89.7807 165.286 82.4395 174.306 82.4395C183.338 82.4395 190.693 89.7685 190.693 98.7628C190.693 104.427 192.893 109.811 196.881 113.907C200.917 118.052 206.253 120.337 211.906 120.337V115.463C203.044 115.463 195.568 107.806 195.556 98.7506C195.556 89.7564 202.886 82.4395 211.882 82.4395C220.878 82.4395 228.232 89.7685 228.232 98.7871L228.257 136.356C228.257 145.363 220.926 152.692 211.906 152.692Z"
          fill="#04030F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.002 136.359H228.12C228.12 128.216 232.746 121.119 239.503 117.577C232.746 114.034 228.12 106.95 228.12 98.7939C228.12 87.0958 237.628 77.5888 249.328 77.5888L286.864 77.5645C298.564 77.5645 308.073 87.0715 308.073 98.7696H303.191C303.191 89.7738 295.873 82.4458 286.864 82.4458L249.328 82.4701C240.331 82.4701 233.002 89.7982 233.002 98.7939C233.002 107.79 240.319 115.118 249.328 115.118V115.154C261.029 115.154 270.537 124.673 270.537 136.359H265.655C265.655 127.364 258.326 120.036 249.328 120.036C240.331 120.036 233.002 127.351 233.002 136.359ZM303.191 98.7701H308.073C308.073 106.914 303.446 114.011 296.689 117.553C303.446 121.095 308.073 128.18 308.073 136.336C308.073 148.034 298.552 157.541 286.864 157.541L249.328 157.565C237.628 157.565 228.12 148.058 228.12 136.36H233.002C233.002 145.356 240.319 152.684 249.328 152.684L286.864 152.659C295.861 152.659 303.191 145.331 303.191 136.336C303.191 127.34 295.873 120.012 286.864 120.012V119.975C275.164 119.975 265.655 110.468 265.655 98.7701H270.537C270.537 107.766 277.867 115.094 286.864 115.094C295.861 115.094 303.191 107.778 303.191 98.7701Z"
          fill={themeColor}
        />
        <path
          d="M286.998 152.696L249.462 152.721C240.453 152.721 233.136 145.393 233.136 136.385C233.136 127.377 240.465 120.049 249.462 120.049C258.46 120.049 265.789 127.377 265.789 136.385H270.671C270.671 124.687 261.163 115.167 249.462 115.167C240.453 115.167 233.136 107.827 233.136 98.8193C233.136 89.8114 240.465 82.4955 249.462 82.4955L286.998 82.4712C296.007 82.4712 303.325 89.7992 303.325 98.8072C303.325 107.815 295.995 115.131 286.998 115.131C278.001 115.131 270.671 107.803 270.671 98.8072H265.789C265.789 110.505 275.298 120.012 286.998 120.012V120.049C296.007 120.049 303.325 127.377 303.325 136.373C303.325 145.368 295.995 152.696 286.998 152.696Z"
          fill="#04030F"
        />
        <circle cx="322.073" cy="117.564" r="6" fill="white" />
        <circle cx="322.073" cy="117.564" r="6" stroke="#04030F" />
        <circle cx="584.103" cy="117.564" r="6" fill="white" />
        <circle cx="584.103" cy="117.564" r="6" stroke="#04030F" />
        <path
          d="M666.696 117.564C673.439 114.005 678.056 106.924 678.056 98.7731C678.044 87.0755 668.531 77.5645 656.844 77.5645H619.303C607.615 77.5645 598.103 87.0877 598.103 98.7852L598.127 136.332V136.356H598.103C598.103 148.053 607.615 157.564 619.303 157.564L656.856 157.54C668.555 157.54 678.068 148.029 678.068 136.332C678.068 128.193 673.451 121.124 666.709 117.564H666.696ZM656.844 152.669L619.315 152.694V152.657C610.312 152.657 602.999 145.332 602.999 136.332L602.974 98.7852C602.974 89.7722 610.3 82.4354 619.303 82.4354H656.844C665.858 82.4354 673.184 89.7722 673.184 98.7731C673.184 107.774 665.858 115.135 656.856 115.135C645.156 115.135 635.631 124.646 635.631 136.344H640.503C640.503 127.331 647.841 119.994 656.856 119.994C665.87 119.994 673.184 127.318 673.184 136.319C673.184 145.32 665.858 152.657 656.844 152.657V152.669Z"
          fill={themeColor}
        />
        <path
          d="M656.831 152.67L619.302 152.695V152.658C610.3 152.658 602.986 145.334 602.986 136.333L602.962 98.7864C602.962 89.7733 610.288 82.4365 619.29 82.4365H656.843C665.858 82.4365 673.184 89.7733 673.184 98.7742C673.184 107.775 665.858 115.136 656.855 115.136C645.156 115.136 635.631 124.647 635.631 136.345H640.503C640.503 127.332 647.841 119.995 656.855 119.995C665.87 119.995 673.184 127.32 673.184 136.321C673.184 145.321 665.858 152.658 656.843 152.658L656.831 152.67Z"
          fill="white"
        />
        <path
          d="M753.082 98.786C753.082 87.0813 743.563 77.5645 731.857 77.5645C723.724 77.5645 716.649 82.171 713.099 88.9166C709.537 82.1831 702.45 77.5645 694.293 77.5645H694.281C682.586 77.5645 673.068 87.0813 673.068 98.786L673.092 136.355C673.092 148.048 682.599 157.564 694.293 157.564C702.45 157.564 709.537 152.946 713.087 146.176C716.637 152.934 723.724 157.564 731.881 157.564C743.588 157.564 753.106 148.048 753.106 136.355L753.082 98.786ZM731.893 152.691C722.873 152.691 715.543 145.361 715.543 136.343L715.518 135.918C715.482 124.249 705.975 114.769 694.293 114.769V119.643C703.313 119.643 710.644 126.972 710.644 135.99V136.343C710.644 145.361 703.313 152.691 694.293 152.691C685.273 152.691 677.967 145.361 677.967 136.355L677.943 98.786C677.943 89.7796 685.273 82.4384 694.293 82.4384C703.325 82.4384 710.68 89.7674 710.68 98.7617C710.68 104.426 712.88 109.81 716.868 113.906C720.904 118.051 726.24 120.336 731.893 120.336V115.462C723.031 115.462 715.555 107.804 715.543 98.7495C715.543 89.7553 722.873 82.4384 731.869 82.4384C740.865 82.4384 748.219 89.7674 748.219 98.786L748.244 136.355C748.244 145.361 740.913 152.691 731.893 152.691Z"
          fill={themeColor}
        />
        <path
          d="M731.893 152.692C722.873 152.692 715.542 145.363 715.542 136.344L715.518 135.919C715.482 124.25 705.975 114.77 694.293 114.77V119.644C703.313 119.644 710.643 126.973 710.643 135.992V136.344C710.643 145.363 703.313 152.692 694.293 152.692C685.273 152.692 677.967 145.363 677.967 136.356L677.942 98.7871C677.942 89.7807 685.273 82.4395 694.293 82.4395C703.325 82.4395 710.68 89.7685 710.68 98.7628C710.68 104.427 712.88 109.811 716.867 113.907C720.903 118.052 726.24 120.337 731.893 120.337V115.463C723.031 115.463 715.555 107.806 715.542 98.7506C715.542 89.7564 722.873 82.4395 731.869 82.4395C740.864 82.4395 748.219 89.7685 748.219 98.7871L748.243 136.356C748.243 145.363 740.913 152.692 731.893 152.692Z"
          fill="white"
        />
        <path
          d="M828.157 98.7738C828.145 87.0813 818.626 77.5645 806.932 77.5645L769.356 77.5888H769.332V77.5645C757.625 77.5645 748.106 87.0813 748.106 98.7738L748.131 136.343C748.131 148.048 757.649 157.564 769.356 157.564C775.021 157.564 780.358 155.304 784.381 151.183C785.901 149.628 787.165 147.877 788.15 146.006C789.135 147.877 790.387 149.628 791.918 151.183C795.942 155.304 801.279 157.564 806.944 157.564C818.651 157.564 828.169 148.048 828.169 136.343L828.193 98.7738H828.157ZM806.944 152.691C797.924 152.691 790.593 145.228 790.593 136.051C790.593 126.875 797.778 119.995 806.956 119.995V115.121C801.279 115.121 795.954 117.273 791.967 121.174C790.472 122.645 789.22 124.298 788.247 126.085C784.685 118.914 777.477 113.87 769.368 113.87V118.743C778.084 118.743 785.731 126.826 785.731 136.051C785.731 145.276 778.388 152.691 769.368 152.691C760.348 152.691 753.018 145.361 753.018 136.343L752.993 98.7981H753.03C753.03 89.7917 760.36 82.4748 769.368 82.4748L806.944 82.4505C815.964 82.4505 823.294 89.7796 823.294 98.7981H823.331L823.307 136.355C823.307 145.374 815.976 152.703 806.956 152.703L806.944 152.691Z"
          fill={themeColor}
        />
        <path
          d="M753.005 136.342L752.981 98.7973H753.017C753.017 89.7909 760.348 82.474 769.356 82.474L806.931 82.4497C815.952 82.4497 823.282 89.7788 823.282 98.7973H823.318L823.294 136.354C823.294 145.373 815.964 152.702 806.944 152.702C797.924 152.702 790.593 145.239 790.593 136.063C790.593 126.886 797.778 120.007 806.956 120.007V115.133C801.279 115.133 795.954 117.284 791.967 121.186C790.472 122.656 789.219 124.309 788.247 126.096C784.685 118.925 777.476 113.881 769.368 113.881V118.755C778.084 118.755 785.731 126.837 785.731 136.063C785.731 145.288 778.388 152.702 769.368 152.702C760.348 152.702 753.017 145.373 753.017 136.354L753.005 136.342Z"
          fill="white"
        />
        <path
          d="M844.409 115.275C854.774 115.275 863.182 125.213 863.182 135.588"
          stroke="THEME"
          strokeWidth="15"
          strokeMiterlimit="10"
        />
        <path
          d="M881.968 157.564C873.936 157.564 866.779 152.656 863.182 145.634C859.598 152.656 852.441 157.552 844.409 157.552C832.708 157.552 823.193 148.04 823.193 136.34V98.8006C823.193 87.1013 832.708 77.5888 844.409 77.5888L881.968 77.5645C893.657 77.5645 903.171 87.077 903.171 98.7763C903.171 106.916 898.541 114.011 891.761 117.558C898.541 121.118 903.183 128.201 903.183 136.34V136.668C903.013 148.21 893.56 157.552 881.98 157.552L881.968 157.564ZM865.624 135.575C865.624 144.857 873.109 152.693 881.968 152.693C890.826 152.693 898.298 145.367 898.298 136.365V136.061C898.14 127.18 890.826 120 881.834 120V115.129C890.911 115.129 898.298 107.791 898.298 98.7763C898.298 89.7619 890.971 82.4361 881.968 82.4361L844.409 82.4604C835.393 82.4604 828.066 89.7862 828.066 98.8006V136.34C828.066 145.355 835.393 152.681 844.409 152.681C853.425 152.681 860.752 144.845 860.752 135.575H865.624Z"
          fill={themeColor}
        />
        <path
          d="M898.298 136.376C898.298 145.379 890.971 152.704 881.968 152.704C872.964 152.704 865.625 144.868 865.625 135.587C865.625 123.474 855.709 112.844 844.409 112.844V117.716C853.121 117.716 860.752 126.074 860.752 135.587C860.752 145.099 853.267 152.692 844.409 152.692C835.551 152.692 828.066 145.366 828.066 136.352V98.8122C828.066 89.7978 835.393 82.4721 844.409 82.4721L881.968 82.4478C890.971 82.4478 898.298 89.7735 898.298 98.7879C898.298 107.802 890.911 115.14 881.834 115.14V120.012C890.813 120.012 898.14 127.192 898.298 136.073V136.376Z"
          fill="white"
        />
        <path
          d="M898.183 136.359H903.065C903.065 127.351 910.395 120.036 919.392 120.036C928.389 120.036 935.719 127.364 935.719 136.359H940.601C940.601 124.673 931.092 115.154 919.392 115.154V115.118C910.382 115.118 903.065 107.79 903.065 98.7939C903.065 89.7982 910.395 82.4701 919.392 82.4701L956.927 82.4458C965.937 82.4458 973.254 89.7738 973.254 98.7696H978.136C978.136 87.0715 968.628 77.5645 956.927 77.5645L919.392 77.5888C907.692 77.5888 898.183 87.0958 898.183 98.7939C898.183 106.95 902.81 114.034 909.567 117.577C902.81 121.119 898.183 128.216 898.183 136.359Z"
          fill={themeColor}
        />
        <path
          d="M978.136 98.77H973.254C973.254 107.778 965.925 115.094 956.927 115.094C947.93 115.094 940.601 107.766 940.601 98.77H935.719C935.719 110.468 945.227 119.975 956.927 119.975V120.012C965.937 120.012 973.254 127.34 973.254 136.335C973.254 145.331 965.925 152.659 956.927 152.659L919.392 152.684C910.382 152.684 903.065 145.356 903.065 136.36H898.183C898.183 148.058 907.692 157.565 919.392 157.565L956.927 157.541C968.615 157.541 978.136 148.034 978.136 136.335C978.136 128.18 973.51 121.095 966.753 117.553C973.51 114.01 978.136 106.914 978.136 98.77Z"
          fill={themeColor}
        />
        <path
          d="M957.061 152.696L919.526 152.721C910.516 152.721 903.199 145.393 903.199 136.385C903.199 127.377 910.529 120.049 919.526 120.049C928.523 120.049 935.853 127.377 935.853 136.385H940.735C940.735 124.687 931.226 115.167 919.526 115.167C910.516 115.167 903.199 107.827 903.199 98.8193C903.199 89.8114 910.529 82.4955 919.526 82.4955L957.061 82.4712C966.071 82.4712 973.388 89.7992 973.388 98.8072C973.388 107.815 966.059 115.131 957.061 115.131C948.064 115.131 940.735 107.803 940.735 98.8072H935.853C935.853 110.505 945.361 120.012 957.061 120.012V120.049C966.071 120.049 973.388 127.377 973.388 136.373C973.388 145.368 966.059 152.696 957.061 152.696Z"
          fill="white"
        />
        <circle cx="992.137" cy="117.564" r="6" fill="white" />
        <circle cx="992.137" cy="117.564" r="6" stroke="#04030F" />
        <path
          d="M1011.01 98.7749C1011.01 89.7685 1018.34 82.4395 1027.36 82.4395C1036.38 82.4395 1043.71 89.7685 1043.71 98.7871L1043.75 136.356H1048.62C1048.62 127.338 1055.95 120.009 1064.97 120.009C1073.99 120.009 1081.32 127.338 1081.32 136.356C1081.32 145.375 1073.99 152.704 1064.97 152.704H1027.39C1018.37 152.704 1011.04 145.375 1011.04 136.356L1011.01 98.7871V98.7749Z"
          fill="white"
        />
        <path
          d="M1086.2 136.343C1086.2 124.65 1076.68 115.134 1064.97 115.134C1058.4 115.134 1052.5 118.148 1048.61 122.852L1048.59 98.786C1048.59 87.0813 1039.07 77.5645 1027.36 77.5645C1015.66 77.5645 1006.14 87.0813 1006.14 98.7738L1006.16 136.343C1006.16 148.048 1015.68 157.564 1027.39 157.564H1064.97C1076.67 157.564 1086.19 148.048 1086.2 136.355V136.343ZM1064.97 152.691H1027.39C1018.37 152.691 1011.04 145.361 1011.04 136.343L1011.01 98.7738C1011.01 89.7674 1018.34 82.4384 1027.36 82.4384C1036.38 82.4384 1043.71 89.7674 1043.71 98.786L1043.75 136.343H1048.62C1048.62 127.324 1055.95 119.995 1064.97 119.995C1073.99 119.995 1081.32 127.324 1081.32 136.343C1081.32 145.361 1073.99 152.691 1064.97 152.691Z"
          fill={themeColor}
        />
        <path
          d="M1102.45 157.564C1090.74 157.564 1081.22 148.048 1081.22 136.343L1081.2 98.7738C1081.2 87.0813 1090.72 77.5645 1102.42 77.5645C1114.13 77.5645 1123.65 87.0813 1123.65 98.786V136.367C1123.65 148.048 1114.14 157.552 1102.46 157.552L1102.45 157.564ZM1102.42 82.4384C1093.4 82.4384 1086.07 89.7674 1086.07 98.7738L1086.1 136.343C1086.1 145.361 1093.43 152.691 1102.45 152.691C1111.47 152.691 1118.77 145.374 1118.77 136.367V98.786C1118.77 89.7674 1111.44 82.4384 1102.42 82.4384Z"
          fill={themeColor}
        />
        <path
          d="M1102.41 82.4395C1093.39 82.4395 1086.06 89.7685 1086.06 98.7749L1086.09 136.344C1086.09 145.363 1093.42 152.692 1102.44 152.692C1111.46 152.692 1118.76 145.375 1118.76 136.368V98.7871C1118.76 89.7685 1111.43 82.4395 1102.41 82.4395Z"
          fill="white"
        />
        <path
          d="M1123.52 98.7749C1123.52 89.7685 1130.85 82.4395 1139.87 82.4395C1148.9 82.4395 1156.23 89.7685 1156.23 98.7871L1156.26 136.356H1161.14C1161.14 127.338 1168.47 120.009 1177.49 120.009C1186.51 120.009 1193.84 127.338 1193.84 136.356C1193.84 145.375 1186.51 152.704 1177.49 152.704H1139.9C1130.88 152.704 1123.55 145.375 1123.55 136.356L1123.52 98.7871V98.7749Z"
          fill="white"
        />
        <path
          d="M1198.71 136.343C1198.71 124.65 1189.19 115.134 1177.49 115.134C1170.91 115.134 1165.01 118.148 1161.12 122.852L1161.1 98.786C1161.1 87.0813 1151.58 77.5645 1139.88 77.5645C1128.17 77.5645 1118.65 87.0813 1118.65 98.7738L1118.67 136.343C1118.67 148.048 1128.19 157.564 1139.9 157.564H1177.49C1189.18 157.564 1198.7 148.048 1198.71 136.355V136.343ZM1177.49 152.691H1139.9C1130.88 152.691 1123.55 145.361 1123.55 136.343L1123.52 98.7738C1123.52 89.7674 1130.86 82.4384 1139.88 82.4384C1148.9 82.4384 1156.23 89.7674 1156.23 98.786L1156.26 136.343H1161.14C1161.14 127.324 1168.47 119.995 1177.49 119.995C1186.51 119.995 1193.84 127.324 1193.84 136.343C1193.84 145.361 1186.51 152.691 1177.49 152.691Z"
          fill={themeColor}
        />
        <path
          d="M1273.73 98.786C1273.73 87.0813 1264.21 77.5645 1252.5 77.5645C1244.37 77.5645 1237.29 82.171 1233.74 88.9166C1230.18 82.1831 1223.09 77.5645 1214.94 77.5645H1214.93C1203.23 77.5645 1193.71 87.0813 1193.71 98.786L1193.74 136.355C1193.74 148.048 1203.24 157.564 1214.94 157.564C1223.09 157.564 1230.18 152.946 1233.73 146.176C1237.28 152.934 1244.37 157.564 1252.53 157.564C1264.23 157.564 1273.75 148.048 1273.75 136.355L1273.73 98.786ZM1252.54 152.691C1243.52 152.691 1236.19 145.361 1236.19 136.343L1236.16 135.918C1236.13 124.249 1226.62 114.769 1214.94 114.769V119.643C1223.96 119.643 1231.29 126.972 1231.29 135.99V136.343C1231.29 145.361 1223.96 152.691 1214.94 152.691C1205.92 152.691 1198.61 145.361 1198.61 136.355L1198.59 98.786C1198.59 89.7796 1205.92 82.4384 1214.94 82.4384C1223.97 82.4384 1231.32 89.7674 1231.32 98.7617C1231.32 104.426 1233.52 109.81 1237.51 113.906C1241.55 118.051 1246.88 120.336 1252.54 120.336V115.462C1243.68 115.462 1236.2 107.804 1236.19 98.7495C1236.19 89.7553 1243.52 82.4384 1252.51 82.4384C1261.51 82.4384 1268.86 89.7674 1268.86 98.786L1268.89 136.355C1268.89 145.361 1261.56 152.691 1252.54 152.691Z"
          fill={themeColor}
        />
        <path
          d="M1252.54 152.692C1243.52 152.692 1236.19 145.363 1236.19 136.344L1236.16 135.919C1236.13 124.25 1226.62 114.77 1214.94 114.77V119.644C1223.96 119.644 1231.29 126.973 1231.29 135.992V136.344C1231.29 145.363 1223.96 152.692 1214.94 152.692C1205.92 152.692 1198.61 145.363 1198.61 136.356L1198.59 98.7871C1198.59 89.7807 1205.92 82.4395 1214.94 82.4395C1223.97 82.4395 1231.32 89.7685 1231.32 98.7628C1231.32 104.427 1233.52 109.811 1237.51 113.907C1241.55 118.052 1246.88 120.337 1252.54 120.337V115.463C1243.68 115.463 1236.2 107.806 1236.19 98.7506C1236.19 89.7564 1243.52 82.4395 1252.51 82.4395C1261.51 82.4395 1268.86 89.7685 1268.86 98.7871L1268.89 136.356C1268.89 145.363 1261.56 152.692 1252.54 152.692Z"
          fill="white"
        />
        <path
          d="M1327.49 77.5887L1289.94 77.5645C1278.63 77.5645 1269.35 86.5019 1268.78 97.6859H1268.75V136.362C1268.75 148.056 1278.26 157.564 1289.94 157.564L1327.49 157.54C1339.18 157.54 1348.69 148.032 1348.69 136.338V98.7909C1348.69 87.0969 1339.18 77.5887 1327.49 77.5887ZM1327.49 152.659L1289.94 152.683C1280.94 152.683 1273.62 145.36 1273.62 136.35V112.258C1277.53 116.994 1283.46 120.017 1290.07 120.017C1301.69 120.017 1311.15 110.485 1311.15 98.7666H1306.28C1306.28 107.801 1299.01 115.148 1290.05 115.148C1281.1 115.148 1273.61 107.789 1273.61 98.7545C1273.61 89.7199 1280.93 82.4218 1289.93 82.4218L1327.47 82.4461C1336.49 82.4461 1343.81 89.7685 1343.81 98.7788V136.326C1343.81 145.336 1336.49 152.659 1327.47 152.659H1327.49Z"
          fill={themeColor}
        />
        <path
          d="M1289.94 152.696L1327.49 152.672C1336.5 152.672 1343.82 145.349 1343.82 136.339V98.7921C1343.82 89.7818 1336.5 82.4593 1327.49 82.4593L1289.94 82.4351C1280.94 82.4351 1273.62 89.7575 1273.62 98.7678C1273.62 107.778 1280.99 115.161 1290.07 115.161C1299.14 115.161 1306.29 107.815 1306.29 98.7799H1311.16C1311.16 110.498 1301.7 120.031 1290.08 120.031C1283.46 120.031 1277.54 117.007 1273.63 112.271V136.363C1273.63 145.374 1280.96 152.696 1289.96 152.696H1289.94Z"
          fill="white"
        />
        <path
          d="M1402.53 157.564H1364.94C1353.23 157.564 1343.72 148.048 1343.72 136.343L1343.69 98.7738C1343.69 87.0813 1353.21 77.5645 1364.92 77.5645C1373.09 77.5645 1380.19 82.2074 1383.74 88.9774C1387.29 82.1953 1394.38 77.5645 1402.55 77.5645C1414.26 77.5645 1423.78 87.0813 1423.78 98.7738L1423.75 136.343C1423.75 148.035 1414.24 157.564 1402.53 157.564ZM1364.92 82.4384C1355.9 82.4384 1348.57 89.7674 1348.57 98.7738L1348.59 136.343C1348.59 145.361 1355.92 152.691 1364.94 152.691H1402.53C1411.55 152.691 1418.88 145.361 1418.88 136.343L1418.9 98.7738C1418.9 89.7674 1411.57 82.4384 1402.55 82.4384C1393.53 82.4384 1386.2 89.7674 1386.2 98.786L1386.18 122.633H1381.3V99.2843H1381.28L1381.26 98.786C1381.26 89.7674 1373.93 82.4384 1364.9 82.4384H1364.92Z"
          fill={themeColor}
        />
        <path
          d="M1364.92 82.4395C1355.9 82.4395 1348.57 89.7685 1348.57 98.7749L1348.59 136.344C1348.59 145.363 1355.92 152.692 1364.94 152.692H1402.53C1411.55 152.692 1418.88 145.363 1418.88 136.344L1418.9 98.7749C1418.9 89.7685 1411.57 82.4395 1402.55 82.4395C1393.53 82.4395 1386.2 89.7685 1386.2 98.7871L1386.18 122.634H1381.3V99.2854H1381.28L1381.26 98.7992C1381.26 89.7807 1373.92 82.4516 1364.9 82.4516L1364.92 82.4395Z"
          fill="white"
        />
        <path
          d="M1498.79 98.786C1498.79 87.0813 1489.27 77.5645 1477.57 77.5645C1469.43 77.5645 1462.36 82.171 1458.81 88.9166C1455.25 82.1831 1448.16 77.5645 1440 77.5645H1439.99C1428.3 77.5645 1418.78 87.0813 1418.78 98.786L1418.8 136.355C1418.8 148.048 1428.31 157.564 1440 157.564C1448.16 157.564 1455.25 152.946 1458.8 146.176C1462.35 152.934 1469.43 157.564 1477.59 157.564C1489.3 157.564 1498.82 148.048 1498.82 136.355L1498.79 98.786ZM1477.6 152.691C1468.58 152.691 1461.25 145.361 1461.25 136.343L1461.23 135.918C1461.19 124.249 1451.69 114.769 1440 114.769V119.643C1449.02 119.643 1456.35 126.972 1456.35 135.99V136.343C1456.35 145.361 1449.02 152.691 1440 152.691C1430.98 152.691 1423.68 145.361 1423.68 136.355L1423.65 98.786C1423.65 89.7796 1430.98 82.4384 1440 82.4384C1449.04 82.4384 1456.39 89.7674 1456.39 98.7617C1456.39 104.426 1458.59 109.81 1462.58 113.906C1466.61 118.051 1471.95 120.336 1477.6 120.336V115.462C1468.74 115.462 1461.27 107.804 1461.25 98.7495C1461.25 89.7553 1468.58 82.4384 1477.58 82.4384C1486.58 82.4384 1493.93 89.7674 1493.93 98.786L1493.95 136.355C1493.95 145.361 1486.62 152.691 1477.6 152.691Z"
          fill={themeColor}
        />
        <path
          d="M1477.6 152.692C1468.58 152.692 1461.25 145.363 1461.25 136.344L1461.23 135.919C1461.19 124.25 1451.69 114.77 1440 114.77V119.644C1449.02 119.644 1456.35 126.973 1456.35 135.992V136.344C1456.35 145.363 1449.02 152.692 1440 152.692C1430.98 152.692 1423.68 145.363 1423.68 136.356L1423.65 98.7871C1423.65 89.7807 1430.98 82.4395 1440 82.4395C1449.04 82.4395 1456.39 89.7685 1456.39 98.7628C1456.39 104.427 1458.59 109.811 1462.58 113.907C1466.61 118.052 1471.95 120.337 1477.6 120.337V115.463C1468.74 115.463 1461.27 107.806 1461.25 98.7506C1461.25 89.7564 1468.58 82.4395 1477.58 82.4395C1486.58 82.4395 1493.93 89.7685 1493.93 98.7871L1493.95 136.356C1493.95 145.363 1486.62 152.692 1477.6 152.692Z"
          fill="white"
        />
        <path
          d="M416.087 98.7865C416.087 87.0818 406.568 77.5649 394.862 77.5649C386.729 77.5649 379.654 82.1714 376.104 88.9171C372.542 82.1836 365.455 77.5649 357.298 77.5649H357.286C345.591 77.5649 336.073 87.0818 336.073 98.7865L336.097 136.356C336.097 148.048 345.603 157.565 357.298 157.565C365.455 157.565 372.542 152.946 376.092 146.176C379.642 152.934 386.729 157.565 394.886 157.565C406.593 157.565 416.111 148.048 416.111 136.356L416.087 98.7865ZM394.898 152.691C385.878 152.691 378.548 145.362 378.548 136.343L378.523 135.918C378.487 124.25 368.98 114.769 357.298 114.769V119.643C366.318 119.643 373.648 126.972 373.648 135.991V136.343C373.648 145.362 366.318 152.691 357.298 152.691C348.278 152.691 340.972 145.362 340.972 136.356L340.948 98.7865C340.948 89.7801 348.278 82.4388 357.298 82.4388C366.33 82.4388 373.685 89.7679 373.685 98.7621C373.685 104.426 375.885 109.81 379.873 113.906C383.909 118.051 389.245 120.336 394.898 120.336V115.462C386.036 115.462 378.56 107.805 378.548 98.75C378.548 89.7558 385.878 82.4388 394.874 82.4388C403.87 82.4388 411.224 89.7679 411.224 98.7865L411.249 136.356C411.249 145.362 403.918 152.691 394.898 152.691Z"
          fill={themeColor}
        />
        <path
          d="M394.898 152.692C385.878 152.692 378.547 145.363 378.547 136.345L378.523 135.919C378.486 124.251 368.98 114.771 357.298 114.771V119.644C366.318 119.644 373.648 126.974 373.648 135.992V136.345C373.648 145.363 366.318 152.692 357.298 152.692C348.278 152.692 340.972 145.363 340.972 136.357L340.947 98.7875C340.947 89.7812 348.278 82.4399 357.298 82.4399C366.33 82.4399 373.685 89.769 373.685 98.7632C373.685 104.427 375.885 109.812 379.872 113.908C383.908 118.052 389.245 120.337 394.898 120.337V115.463C386.036 115.463 378.559 107.806 378.547 98.7511C378.547 89.7569 385.878 82.4399 394.873 82.4399C403.869 82.4399 411.224 89.769 411.224 98.7875L411.248 136.357C411.248 145.363 403.918 152.692 394.898 152.692Z"
          fill="white"
        />
        <path
          d="M493.125 98.7865C493.125 87.0818 483.607 77.5649 471.9 77.5649C463.767 77.5649 456.692 82.1714 453.143 88.9171C449.581 82.1836 442.494 77.5649 434.337 77.5649H434.324C422.63 77.5649 413.111 87.0818 413.111 98.7865L413.136 136.356C413.136 148.048 422.642 157.565 434.337 157.565C442.494 157.565 449.581 152.946 453.13 146.176C456.68 152.934 463.767 157.565 471.924 157.565C483.631 157.565 493.15 148.048 493.15 136.356L493.125 98.7865ZM471.937 152.691C462.916 152.691 455.586 145.362 455.586 136.343L455.562 135.918C455.525 124.25 446.019 114.769 434.337 114.769V119.643C443.357 119.643 450.687 126.972 450.687 135.991V136.343C450.687 145.362 443.357 152.691 434.337 152.691C425.316 152.691 418.01 145.362 418.01 136.356L417.986 98.7865C417.986 89.7801 425.316 82.4388 434.337 82.4388C443.369 82.4388 450.724 89.7679 450.724 98.7621C450.724 104.426 452.924 109.81 456.911 113.906C460.947 118.051 466.284 120.336 471.937 120.336V115.462C463.074 115.462 455.598 107.805 455.586 98.75C455.586 89.7558 462.916 82.4388 471.912 82.4388C480.908 82.4388 488.263 89.7679 488.263 98.7865L488.287 136.356C488.287 145.362 480.957 152.691 471.937 152.691Z"
          fill={themeColor}
        />
        <path
          d="M471.936 152.692C462.916 152.692 455.586 145.363 455.586 136.345L455.562 135.919C455.525 124.251 446.019 114.771 434.336 114.771V119.644C443.356 119.644 450.687 126.974 450.687 135.992V136.345C450.687 145.363 443.356 152.692 434.336 152.692C425.316 152.692 418.01 145.363 418.01 136.357L417.986 98.7875C417.986 89.7812 425.316 82.4399 434.336 82.4399C443.369 82.4399 450.723 89.769 450.723 98.7632C450.723 104.427 452.924 109.812 456.911 113.908C460.947 118.052 466.284 120.337 471.936 120.337V115.463C463.074 115.463 455.598 107.806 455.586 98.7511C455.586 89.7569 462.916 82.4399 471.912 82.4399C480.908 82.4399 488.263 89.769 488.263 98.7875L488.287 136.357C488.287 145.363 480.956 152.692 471.936 152.692Z"
          fill="white"
        />
        <path
          d="M490.149 136.359H495.032C495.032 127.351 502.361 120.036 511.358 120.036C520.356 120.036 527.685 127.364 527.685 136.359H532.567C532.567 124.673 523.058 115.154 511.358 115.154V115.118C502.349 115.118 495.032 107.79 495.032 98.7939C495.032 89.7982 502.361 82.4701 511.358 82.4701L548.894 82.4458C557.903 82.4458 565.22 89.7738 565.22 98.7696H570.103C570.103 87.0715 560.594 77.5645 548.894 77.5645L511.358 77.5888C499.658 77.5888 490.149 87.0958 490.149 98.7939C490.149 106.95 494.776 114.034 501.533 117.577C494.776 121.119 490.149 128.216 490.149 136.359Z"
          fill={themeColor}
        />
        <path
          d="M570.103 98.77H565.22C565.22 107.778 557.891 115.094 548.894 115.094C539.896 115.094 532.567 107.766 532.567 98.77H527.685C527.685 110.468 537.194 119.975 548.894 119.975V120.012C557.903 120.012 565.22 127.34 565.22 136.335C565.22 145.331 557.891 152.659 548.894 152.659L511.358 152.684C502.349 152.684 495.032 145.356 495.032 136.36H490.149C490.149 148.058 499.658 157.565 511.358 157.565L548.894 157.541C560.582 157.541 570.103 148.034 570.103 136.335C570.103 128.18 565.476 121.095 558.719 117.553C565.476 114.01 570.103 106.914 570.103 98.77Z"
          fill={themeColor}
        />
        <path
          d="M549.028 152.696L511.492 152.721C502.483 152.721 495.166 145.393 495.166 136.385C495.166 127.377 502.495 120.049 511.492 120.049C520.49 120.049 527.819 127.377 527.819 136.385H532.701C532.701 124.687 523.192 115.167 511.492 115.167C502.483 115.167 495.166 107.827 495.166 98.8193C495.166 89.8114 502.495 82.4955 511.492 82.4955L549.028 82.4712C558.037 82.4712 565.354 89.7992 565.354 98.8072C565.354 107.815 558.025 115.131 549.028 115.131C540.03 115.131 532.701 107.803 532.701 98.8072H527.819C527.819 110.505 537.328 120.012 549.028 120.012V120.049C558.037 120.049 565.354 127.377 565.354 136.373C565.354 145.368 558.025 152.696 549.028 152.696Z"
          fill="white"
        />
        <path
          d="M8.0287 473.786C8.0287 462.081 -1.48986 452.564 -13.1966 452.564C-21.3293 452.564 -28.4043 457.171 -31.954 463.917C-35.5159 457.183 -42.6031 452.564 -50.7601 452.564H-50.7723C-62.4668 452.564 -71.9854 462.081 -71.9854 473.786L-71.9611 511.355C-71.9611 523.048 -62.4547 532.564 -50.7601 532.564C-42.6031 532.564 -35.5159 527.946 -31.9662 521.176C-28.4165 527.934 -21.3292 532.564 -13.1722 532.564C-1.46552 532.564 8.053 523.048 8.053 511.355L8.0287 473.786ZM-13.1601 527.691C-22.1802 527.691 -29.5106 520.361 -29.5106 511.343L-29.5349 510.918C-29.5714 499.249 -39.0777 489.769 -50.7601 489.769V494.643C-41.74 494.643 -34.4096 501.972 -34.4096 510.99V511.343C-34.4096 520.361 -41.74 527.691 -50.7601 527.691C-59.7802 527.691 -67.0863 520.361 -67.0863 511.355L-67.1106 473.786C-67.1106 464.78 -59.7802 457.438 -50.7601 457.438C-41.7278 457.438 -34.3732 464.767 -34.3732 473.762C-34.3732 479.426 -32.1728 484.81 -28.1855 488.906C-24.1496 493.051 -18.8129 495.336 -13.1601 495.336V490.462C-22.0222 490.462 -29.4984 482.804 -29.5106 473.75C-29.5106 464.755 -22.1802 457.438 -13.1844 457.438C-4.18859 457.438 3.1661 464.767 3.1661 473.786L3.1904 511.355C3.1904 520.361 -4.13998 527.691 -13.1601 527.691Z"
          fill="#04030F"
        />
        <path
          d="M-13.1604 527.692C-22.1805 527.692 -29.5108 520.363 -29.5108 511.344L-29.5352 510.919C-29.5716 499.25 -39.078 489.77 -50.7604 489.77V494.644C-41.7403 494.644 -34.4099 501.973 -34.4099 510.992V511.344C-34.4099 520.363 -41.7403 527.692 -50.7604 527.692C-59.7805 527.692 -67.0865 520.363 -67.0865 511.356L-67.1108 473.787C-67.1108 464.781 -59.7805 457.439 -50.7604 457.439C-41.7281 457.439 -34.3734 464.769 -34.3734 473.763C-34.3734 479.427 -32.1731 484.811 -28.1858 488.907C-24.1498 493.052 -18.8131 495.337 -13.1604 495.337V490.463C-22.0224 490.463 -29.4987 482.806 -29.5108 473.751C-29.5108 464.756 -22.1805 457.439 -13.1847 457.439C-4.18885 457.439 3.16585 464.769 3.16585 473.787L3.19015 511.356C3.19015 520.363 -4.14023 527.692 -13.1604 527.692Z"
          fill="white"
        />
        <path
          d="M61.7885 452.589L24.247 452.564C12.9274 452.564 3.64834 461.502 3.0775 472.686H3.05322V511.362C3.05322 523.056 12.5631 532.564 24.247 532.564L61.7885 532.54C73.4845 532.54 82.9944 523.032 82.9944 511.338V473.791C82.9944 462.097 73.4845 452.589 61.7885 452.589ZM61.7885 527.659L24.247 527.683C15.2472 527.683 7.92355 520.36 7.92355 511.35V487.258C11.8344 491.994 17.7613 495.017 24.3684 495.017C35.9916 495.017 45.4529 485.485 45.4529 473.767H40.5826C40.5826 482.801 33.3075 490.148 24.3563 490.148C15.4051 490.148 7.91139 482.789 7.91139 473.754C7.91139 464.72 15.2351 457.422 24.2348 457.422L61.7763 457.446C70.7883 457.446 78.1119 464.768 78.1119 473.779V511.326C78.1119 520.336 70.7883 527.659 61.7763 527.659H61.7885Z"
          fill="#04030F"
        />
        <path
          d="M24.2472 527.696L61.7887 527.672C70.8007 527.672 78.1244 520.349 78.1244 511.339V473.792C78.1244 464.782 70.8007 457.459 61.7887 457.459L24.2472 457.435C15.2475 457.435 7.92383 464.757 7.92383 473.768C7.92383 482.778 15.2961 490.161 24.3687 490.161C33.4413 490.161 40.595 482.815 40.595 473.78H45.4653C45.4653 485.498 36.004 495.031 24.3809 495.031C17.7616 495.031 11.8468 492.007 7.93595 487.271V511.363C7.93595 520.374 15.2596 527.696 24.2594 527.696H24.2472Z"
          fill="white"
        />
        <path
          d="M136.832 532.564H99.2437C87.537 532.564 78.0184 523.048 78.0184 511.343L77.9941 473.774C77.9941 462.081 87.5127 452.564 99.2194 452.564C107.389 452.564 114.488 457.207 118.038 463.977C121.587 457.195 128.687 452.564 136.856 452.564C148.563 452.564 158.081 462.081 158.081 473.774L158.057 511.343C158.057 523.035 148.538 532.564 136.832 532.564ZM99.2194 457.438C90.1993 457.438 82.8689 464.767 82.8689 473.774L82.8932 511.343C82.8932 520.361 90.2235 527.691 99.2437 527.691H136.832C145.852 527.691 153.182 520.361 153.182 511.343L153.206 473.774C153.206 464.767 145.876 457.438 136.856 457.438C127.836 457.438 120.505 464.767 120.505 473.786L120.481 497.633H115.606V474.284H115.582L115.558 473.786C115.558 464.767 108.227 457.438 99.2072 457.438H99.2194Z"
          fill="#04030F"
        />
        <path
          d="M99.2191 457.439C90.199 457.439 82.8687 464.769 82.8687 473.775L82.893 511.344C82.893 520.363 90.2233 527.692 99.2434 527.692H136.831C145.851 527.692 153.182 520.363 153.182 511.344L153.206 473.775C153.206 464.769 145.876 457.439 136.856 457.439C127.835 457.439 120.505 464.769 120.505 473.787L120.481 497.634H115.606V474.285H115.582L115.557 473.799C115.557 464.781 108.227 457.452 99.2069 457.452L99.2191 457.439Z"
          fill="white"
        />
        <path
          d="M233.095 473.786C233.095 462.081 223.577 452.564 211.87 452.564C203.737 452.564 196.662 457.171 193.112 463.917C189.551 457.183 182.463 452.564 174.306 452.564H174.294C162.6 452.564 153.081 462.081 153.081 473.786L153.105 511.355C153.105 523.048 162.612 532.564 174.306 532.564C182.463 532.564 189.551 527.946 193.1 521.176C196.65 527.934 203.737 532.564 211.894 532.564C223.601 532.564 233.119 523.048 233.119 511.355L233.095 473.786ZM211.906 527.691C202.886 527.691 195.556 520.361 195.556 511.343L195.532 510.918C195.495 499.249 185.989 489.769 174.306 489.769V494.643C183.326 494.643 190.657 501.972 190.657 510.99V511.343C190.657 520.361 183.326 527.691 174.306 527.691C165.286 527.691 157.98 520.361 157.98 511.355L157.956 473.786C157.956 464.78 165.286 457.438 174.306 457.438C183.339 457.438 190.693 464.767 190.693 473.762C190.693 479.426 192.894 484.81 196.881 488.906C200.917 493.051 206.254 495.336 211.906 495.336V490.462C203.044 490.462 195.568 482.804 195.556 473.75C195.556 464.755 202.886 457.438 211.882 457.438C220.878 457.438 228.233 464.767 228.233 473.786L228.257 511.355C228.257 520.361 220.926 527.691 211.906 527.691Z"
          fill="#04030F"
        />
        <path
          d="M211.906 527.692C202.886 527.692 195.556 520.363 195.556 511.344L195.531 510.919C195.495 499.25 185.988 489.77 174.306 489.77V494.644C183.326 494.644 190.657 501.973 190.657 510.992V511.344C190.657 520.363 183.326 527.692 174.306 527.692C165.286 527.692 157.98 520.363 157.98 511.356L157.956 473.787C157.956 464.781 165.286 457.439 174.306 457.439C183.338 457.439 190.693 464.769 190.693 473.763C190.693 479.427 192.893 484.811 196.881 488.907C200.917 493.052 206.253 495.337 211.906 495.337V490.463C203.044 490.463 195.568 482.806 195.556 473.751C195.556 464.756 202.886 457.439 211.882 457.439C220.878 457.439 228.232 464.769 228.232 473.787L228.257 511.356C228.257 520.363 220.926 527.692 211.906 527.692Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.002 511.359H228.12C228.12 503.216 232.746 496.119 239.503 492.577C232.746 489.034 228.12 481.95 228.12 473.794C228.12 462.096 237.628 452.589 249.328 452.589L286.864 452.564C298.564 452.564 308.073 462.071 308.073 473.77H303.191C303.191 464.774 295.873 457.446 286.864 457.446L249.328 457.47C240.331 457.47 233.002 464.798 233.002 473.794C233.002 482.79 240.319 490.118 249.328 490.118V490.154C261.029 490.154 270.537 499.673 270.537 511.359H265.655C265.655 502.364 258.326 495.036 249.328 495.036C240.331 495.036 233.002 502.351 233.002 511.359ZM303.191 473.77H308.073C308.073 481.914 303.446 489.011 296.689 492.553C303.446 496.095 308.073 503.18 308.073 511.336C308.073 523.034 298.552 532.541 286.864 532.541L249.328 532.565C237.628 532.565 228.12 523.058 228.12 511.36H233.002C233.002 520.356 240.319 527.684 249.328 527.684L286.864 527.659C295.861 527.659 303.191 520.331 303.191 511.336C303.191 502.34 295.873 495.012 286.864 495.012V494.975C275.164 494.975 265.655 485.468 265.655 473.77H270.537C270.537 482.766 277.867 490.094 286.864 490.094C295.861 490.094 303.191 482.778 303.191 473.77Z"
          fill="#04030F"
        />
        <path
          d="M286.998 527.696L249.462 527.721C240.453 527.721 233.136 520.393 233.136 511.385C233.136 502.377 240.465 495.049 249.462 495.049C258.46 495.049 265.789 502.377 265.789 511.385H270.671C270.671 499.687 261.163 490.167 249.462 490.167C240.453 490.167 233.136 482.827 233.136 473.819C233.136 464.811 240.465 457.496 249.462 457.496L286.998 457.471C296.007 457.471 303.325 464.799 303.325 473.807C303.325 482.815 295.995 490.131 286.998 490.131C278.001 490.131 270.671 482.803 270.671 473.807H265.789C265.789 485.505 275.298 495.012 286.998 495.012V495.049C296.007 495.049 303.325 502.377 303.325 511.373C303.325 520.368 295.995 527.696 286.998 527.696Z"
          fill="white"
        />
        <circle cx="322.073" cy="492.564" r="6" fill="white" />
        <circle cx="322.073" cy="492.564" r="6" stroke="#04030F" />
        <circle cx="584.103" cy="492.564" r="6" fill="white" />
        <circle cx="584.103" cy="492.564" r="6" stroke="#04030F" />
        <path
          d="M666.696 492.564C673.439 489.005 678.056 481.924 678.056 473.773C678.044 462.076 668.531 452.564 656.844 452.564H619.303C607.615 452.564 598.103 462.088 598.103 473.785L598.127 511.332V511.356H598.103C598.103 523.053 607.615 532.564 619.303 532.564L656.856 532.54C668.555 532.54 678.068 523.029 678.068 511.332C678.068 503.193 673.451 496.124 666.709 492.564H666.696ZM656.844 527.669L619.315 527.694V527.657C610.312 527.657 602.999 520.332 602.999 511.332L602.974 473.785C602.974 464.772 610.3 457.435 619.303 457.435H656.844C665.858 457.435 673.184 464.772 673.184 473.773C673.184 482.774 665.858 490.135 656.856 490.135C645.156 490.135 635.631 499.646 635.631 511.344H640.503C640.503 502.331 647.841 494.994 656.856 494.994C665.87 494.994 673.184 502.318 673.184 511.319C673.184 520.32 665.858 527.657 656.844 527.657V527.669Z"
          fill="#04030F"
        />
        <path
          d="M656.831 527.67L619.302 527.695V527.658C610.3 527.658 602.986 520.334 602.986 511.333L602.962 473.786C602.962 464.773 610.288 457.437 619.29 457.437H656.843C665.858 457.437 673.184 464.773 673.184 473.774C673.184 482.775 665.858 490.136 656.855 490.136C645.156 490.136 635.631 499.647 635.631 511.345H640.503C640.503 502.332 647.841 494.995 656.855 494.995C665.87 494.995 673.184 502.32 673.184 511.321C673.184 520.321 665.858 527.658 656.843 527.658L656.831 527.67Z"
          fill="white"
        />
        <path
          d="M753.082 473.786C753.082 462.081 743.563 452.564 731.857 452.564C723.724 452.564 716.649 457.171 713.099 463.917C709.537 457.183 702.45 452.564 694.293 452.564H694.281C682.586 452.564 673.068 462.081 673.068 473.786L673.092 511.355C673.092 523.048 682.599 532.564 694.293 532.564C702.45 532.564 709.537 527.946 713.087 521.176C716.637 527.934 723.724 532.564 731.881 532.564C743.588 532.564 753.106 523.048 753.106 511.355L753.082 473.786ZM731.893 527.691C722.873 527.691 715.543 520.361 715.543 511.343L715.518 510.918C715.482 499.249 705.975 489.769 694.293 489.769V494.643C703.313 494.643 710.644 501.972 710.644 510.99V511.343C710.644 520.361 703.313 527.691 694.293 527.691C685.273 527.691 677.967 520.361 677.967 511.355L677.943 473.786C677.943 464.78 685.273 457.438 694.293 457.438C703.325 457.438 710.68 464.767 710.68 473.762C710.68 479.426 712.88 484.81 716.868 488.906C720.904 493.051 726.24 495.336 731.893 495.336V490.462C723.031 490.462 715.555 482.804 715.543 473.75C715.543 464.755 722.873 457.438 731.869 457.438C740.865 457.438 748.219 464.767 748.219 473.786L748.244 511.355C748.244 520.361 740.913 527.691 731.893 527.691Z"
          fill="#04030F"
        />
        <path
          d="M731.893 527.692C722.873 527.692 715.542 520.363 715.542 511.344L715.518 510.919C715.482 499.25 705.975 489.77 694.293 489.77V494.644C703.313 494.644 710.643 501.973 710.643 510.992V511.344C710.643 520.363 703.313 527.692 694.293 527.692C685.273 527.692 677.967 520.363 677.967 511.356L677.942 473.787C677.942 464.781 685.273 457.439 694.293 457.439C703.325 457.439 710.68 464.769 710.68 473.763C710.68 479.427 712.88 484.811 716.867 488.907C720.903 493.052 726.24 495.337 731.893 495.337V490.463C723.031 490.463 715.555 482.806 715.542 473.751C715.542 464.756 722.873 457.439 731.869 457.439C740.864 457.439 748.219 464.769 748.219 473.787L748.243 511.356C748.243 520.363 740.913 527.692 731.893 527.692Z"
          fill="white"
        />
        <path
          d="M828.157 473.774C828.145 462.081 818.626 452.564 806.932 452.564L769.356 452.589H769.332V452.564C757.625 452.564 748.106 462.081 748.106 473.774L748.131 511.343C748.131 523.048 757.649 532.564 769.356 532.564C775.021 532.564 780.358 530.304 784.381 526.183C785.901 524.628 787.165 522.877 788.15 521.006C789.135 522.877 790.387 524.628 791.918 526.183C795.942 530.304 801.279 532.564 806.944 532.564C818.651 532.564 828.169 523.048 828.169 511.343L828.193 473.774H828.157ZM806.944 527.691C797.924 527.691 790.593 520.228 790.593 511.051C790.593 501.875 797.778 494.995 806.956 494.995V490.121C801.279 490.121 795.954 492.273 791.967 496.174C790.472 497.645 789.22 499.298 788.247 501.085C784.685 493.914 777.477 488.87 769.368 488.87V493.743C778.084 493.743 785.731 501.826 785.731 511.051C785.731 520.276 778.388 527.691 769.368 527.691C760.348 527.691 753.018 520.361 753.018 511.343L752.993 473.798H753.03C753.03 464.792 760.36 457.475 769.368 457.475L806.944 457.451C815.964 457.451 823.294 464.78 823.294 473.798H823.331L823.307 511.355C823.307 520.374 815.976 527.703 806.956 527.703L806.944 527.691Z"
          fill="#04030F"
        />
        <path
          d="M753.005 511.342L752.981 473.797H753.017C753.017 464.791 760.348 457.474 769.356 457.474L806.931 457.45C815.952 457.45 823.282 464.779 823.282 473.797H823.318L823.294 511.354C823.294 520.373 815.964 527.702 806.944 527.702C797.924 527.702 790.593 520.239 790.593 511.063C790.593 501.886 797.778 495.007 806.956 495.007V490.133C801.279 490.133 795.954 492.284 791.967 496.186C790.472 497.656 789.219 499.309 788.247 501.096C784.685 493.925 777.476 488.881 769.368 488.881V493.755C778.084 493.755 785.731 501.837 785.731 511.063C785.731 520.288 778.388 527.702 769.368 527.702C760.348 527.702 753.017 520.373 753.017 511.354L753.005 511.342Z"
          fill="white"
        />
        <path
          d="M844.409 490.275C854.774 490.275 863.182 500.213 863.182 510.588"
          stroke="#04030F"
          strokeWidth="15"
          strokeMiterlimit="10"
        />
        <path
          d="M881.968 532.564C873.936 532.564 866.779 527.656 863.182 520.634C859.598 527.656 852.441 532.552 844.409 532.552C832.708 532.552 823.193 523.04 823.193 511.34V473.801C823.193 462.101 832.708 452.589 844.409 452.589L881.968 452.564C893.657 452.564 903.171 462.077 903.171 473.776C903.171 481.916 898.541 489.011 891.761 492.558C898.541 496.118 903.183 503.201 903.183 511.34V511.668C903.013 523.21 893.56 532.552 881.98 532.552L881.968 532.564ZM865.624 510.575C865.624 519.857 873.109 527.693 881.968 527.693C890.826 527.693 898.298 520.367 898.298 511.365V511.061C898.14 502.18 890.826 495 881.834 495V490.129C890.911 490.129 898.298 482.791 898.298 473.776C898.298 464.762 890.971 457.436 881.968 457.436L844.409 457.46C835.393 457.46 828.066 464.786 828.066 473.801V511.34C828.066 520.355 835.393 527.681 844.409 527.681C853.425 527.681 860.752 519.845 860.752 510.575H865.624Z"
          fill="#04030F"
        />
        <path
          d="M898.298 511.376C898.298 520.379 890.971 527.704 881.968 527.704C872.964 527.704 865.625 519.868 865.625 510.587C865.625 498.474 855.709 487.844 844.409 487.844V492.716C853.121 492.716 860.752 501.074 860.752 510.587C860.752 520.099 853.267 527.692 844.409 527.692C835.551 527.692 828.066 520.366 828.066 511.352V473.812C828.066 464.798 835.393 457.472 844.409 457.472L881.968 457.448C890.971 457.448 898.298 464.773 898.298 473.788C898.298 482.802 890.911 490.14 881.834 490.14V495.012C890.813 495.012 898.14 502.192 898.298 511.073V511.376Z"
          fill="white"
        />
        <path
          d="M898.183 511.359H903.065C903.065 502.351 910.395 495.036 919.392 495.036C928.389 495.036 935.719 502.364 935.719 511.359H940.601C940.601 499.673 931.092 490.154 919.392 490.154V490.118C910.382 490.118 903.065 482.79 903.065 473.794C903.065 464.798 910.395 457.47 919.392 457.47L956.927 457.446C965.937 457.446 973.254 464.774 973.254 473.77H978.136C978.136 462.071 968.628 452.564 956.927 452.564L919.392 452.589C907.692 452.589 898.183 462.096 898.183 473.794C898.183 481.95 902.81 489.034 909.567 492.577C902.81 496.119 898.183 503.216 898.183 511.359Z"
          fill="#04030F"
        />
        <path
          d="M978.136 473.77H973.254C973.254 482.778 965.925 490.094 956.927 490.094C947.93 490.094 940.601 482.766 940.601 473.77H935.719C935.719 485.468 945.227 494.975 956.927 494.975V495.012C965.937 495.012 973.254 502.34 973.254 511.335C973.254 520.331 965.925 527.659 956.927 527.659L919.392 527.684C910.382 527.684 903.065 520.356 903.065 511.36H898.183C898.183 523.058 907.692 532.565 919.392 532.565L956.927 532.541C968.615 532.541 978.136 523.034 978.136 511.335C978.136 503.18 973.51 496.095 966.753 492.553C973.51 489.01 978.136 481.914 978.136 473.77Z"
          fill="#04030F"
        />
        <path
          d="M957.061 527.696L919.526 527.721C910.516 527.721 903.199 520.393 903.199 511.385C903.199 502.377 910.529 495.049 919.526 495.049C928.523 495.049 935.853 502.377 935.853 511.385H940.735C940.735 499.687 931.226 490.167 919.526 490.167C910.516 490.167 903.199 482.827 903.199 473.819C903.199 464.811 910.529 457.496 919.526 457.496L957.061 457.471C966.071 457.471 973.388 464.799 973.388 473.807C973.388 482.815 966.059 490.131 957.061 490.131C948.064 490.131 940.735 482.803 940.735 473.807H935.853C935.853 485.505 945.361 495.012 957.061 495.012V495.049C966.071 495.049 973.388 502.377 973.388 511.373C973.388 520.368 966.059 527.696 957.061 527.696Z"
          fill="white"
        />
        <circle cx="992.137" cy="492.564" r="6" fill="white" />
        <circle cx="992.137" cy="492.564" r="6" stroke="#04030F" />
        <path
          d="M1011.01 473.775C1011.01 464.769 1018.34 457.439 1027.36 457.439C1036.38 457.439 1043.71 464.769 1043.71 473.787L1043.75 511.356H1048.62C1048.62 502.338 1055.95 495.009 1064.97 495.009C1073.99 495.009 1081.32 502.338 1081.32 511.356C1081.32 520.375 1073.99 527.704 1064.97 527.704H1027.39C1018.37 527.704 1011.04 520.375 1011.04 511.356L1011.01 473.787V473.775Z"
          fill="white"
        />
        <path
          d="M1086.2 511.343C1086.2 499.65 1076.68 490.134 1064.97 490.134C1058.4 490.134 1052.5 493.148 1048.61 497.852L1048.59 473.786C1048.59 462.081 1039.07 452.564 1027.36 452.564C1015.66 452.564 1006.14 462.081 1006.14 473.774L1006.16 511.343C1006.16 523.048 1015.68 532.564 1027.39 532.564H1064.97C1076.67 532.564 1086.19 523.048 1086.2 511.355V511.343ZM1064.97 527.691H1027.39C1018.37 527.691 1011.04 520.361 1011.04 511.343L1011.01 473.774C1011.01 464.767 1018.34 457.438 1027.36 457.438C1036.38 457.438 1043.71 464.767 1043.71 473.786L1043.75 511.343H1048.62C1048.62 502.324 1055.95 494.995 1064.97 494.995C1073.99 494.995 1081.32 502.324 1081.32 511.343C1081.32 520.361 1073.99 527.691 1064.97 527.691Z"
          fill="#04030F"
        />
        <path
          d="M1102.45 532.564C1090.74 532.564 1081.22 523.048 1081.22 511.343L1081.2 473.774C1081.2 462.081 1090.72 452.564 1102.42 452.564C1114.13 452.564 1123.65 462.081 1123.65 473.786V511.367C1123.65 523.048 1114.14 532.552 1102.46 532.552L1102.45 532.564ZM1102.42 457.438C1093.4 457.438 1086.07 464.767 1086.07 473.774L1086.1 511.343C1086.1 520.361 1093.43 527.691 1102.45 527.691C1111.47 527.691 1118.77 520.374 1118.77 511.367V473.786C1118.77 464.767 1111.44 457.438 1102.42 457.438Z"
          fill="#04030F"
        />
        <path
          d="M1102.41 457.439C1093.39 457.439 1086.06 464.769 1086.06 473.775L1086.09 511.344C1086.09 520.363 1093.42 527.692 1102.44 527.692C1111.46 527.692 1118.76 520.375 1118.76 511.368V473.787C1118.76 464.769 1111.43 457.439 1102.41 457.439Z"
          fill="white"
        />
        <path
          d="M1123.52 473.775C1123.52 464.769 1130.85 457.439 1139.87 457.439C1148.9 457.439 1156.23 464.769 1156.23 473.787L1156.26 511.356H1161.14C1161.14 502.338 1168.47 495.009 1177.49 495.009C1186.51 495.009 1193.84 502.338 1193.84 511.356C1193.84 520.375 1186.51 527.704 1177.49 527.704H1139.9C1130.88 527.704 1123.55 520.375 1123.55 511.356L1123.52 473.787V473.775Z"
          fill="white"
        />
        <path
          d="M1198.71 511.343C1198.71 499.65 1189.19 490.134 1177.49 490.134C1170.91 490.134 1165.01 493.148 1161.12 497.852L1161.1 473.786C1161.1 462.081 1151.58 452.564 1139.88 452.564C1128.17 452.564 1118.65 462.081 1118.65 473.774L1118.67 511.343C1118.67 523.048 1128.19 532.564 1139.9 532.564H1177.49C1189.18 532.564 1198.7 523.048 1198.71 511.355V511.343ZM1177.49 527.691H1139.9C1130.88 527.691 1123.55 520.361 1123.55 511.343L1123.52 473.774C1123.52 464.767 1130.86 457.438 1139.88 457.438C1148.9 457.438 1156.23 464.767 1156.23 473.786L1156.26 511.343H1161.14C1161.14 502.324 1168.47 494.995 1177.49 494.995C1186.51 494.995 1193.84 502.324 1193.84 511.343C1193.84 520.361 1186.51 527.691 1177.49 527.691Z"
          fill="#04030F"
        />
        <path
          d="M1273.73 473.786C1273.73 462.081 1264.21 452.564 1252.5 452.564C1244.37 452.564 1237.29 457.171 1233.74 463.917C1230.18 457.183 1223.09 452.564 1214.94 452.564H1214.93C1203.23 452.564 1193.71 462.081 1193.71 473.786L1193.74 511.355C1193.74 523.048 1203.24 532.564 1214.94 532.564C1223.09 532.564 1230.18 527.946 1233.73 521.176C1237.28 527.934 1244.37 532.564 1252.53 532.564C1264.23 532.564 1273.75 523.048 1273.75 511.355L1273.73 473.786ZM1252.54 527.691C1243.52 527.691 1236.19 520.361 1236.19 511.343L1236.16 510.918C1236.13 499.249 1226.62 489.769 1214.94 489.769V494.643C1223.96 494.643 1231.29 501.972 1231.29 510.99V511.343C1231.29 520.361 1223.96 527.691 1214.94 527.691C1205.92 527.691 1198.61 520.361 1198.61 511.355L1198.59 473.786C1198.59 464.78 1205.92 457.438 1214.94 457.438C1223.97 457.438 1231.32 464.767 1231.32 473.762C1231.32 479.426 1233.52 484.81 1237.51 488.906C1241.55 493.051 1246.88 495.336 1252.54 495.336V490.462C1243.68 490.462 1236.2 482.804 1236.19 473.75C1236.19 464.755 1243.52 457.438 1252.51 457.438C1261.51 457.438 1268.86 464.767 1268.86 473.786L1268.89 511.355C1268.89 520.361 1261.56 527.691 1252.54 527.691Z"
          fill="#04030F"
        />
        <path
          d="M1252.54 527.692C1243.52 527.692 1236.19 520.363 1236.19 511.344L1236.16 510.919C1236.13 499.25 1226.62 489.77 1214.94 489.77V494.644C1223.96 494.644 1231.29 501.973 1231.29 510.992V511.344C1231.29 520.363 1223.96 527.692 1214.94 527.692C1205.92 527.692 1198.61 520.363 1198.61 511.356L1198.59 473.787C1198.59 464.781 1205.92 457.439 1214.94 457.439C1223.97 457.439 1231.32 464.769 1231.32 473.763C1231.32 479.427 1233.52 484.811 1237.51 488.907C1241.55 493.052 1246.88 495.337 1252.54 495.337V490.463C1243.68 490.463 1236.2 482.806 1236.19 473.751C1236.19 464.756 1243.52 457.439 1252.51 457.439C1261.51 457.439 1268.86 464.769 1268.86 473.787L1268.89 511.356C1268.89 520.363 1261.56 527.692 1252.54 527.692Z"
          fill="white"
        />
        <path
          d="M1327.49 452.589L1289.94 452.564C1278.63 452.564 1269.35 461.502 1268.78 472.686H1268.75V511.362C1268.75 523.056 1278.26 532.564 1289.94 532.564L1327.49 532.54C1339.18 532.54 1348.69 523.032 1348.69 511.338V473.791C1348.69 462.097 1339.18 452.589 1327.49 452.589ZM1327.49 527.659L1289.94 527.683C1280.94 527.683 1273.62 520.36 1273.62 511.35V487.258C1277.53 491.994 1283.46 495.017 1290.07 495.017C1301.69 495.017 1311.15 485.485 1311.15 473.767H1306.28C1306.28 482.801 1299.01 490.148 1290.05 490.148C1281.1 490.148 1273.61 482.789 1273.61 473.754C1273.61 464.72 1280.93 457.422 1289.93 457.422L1327.47 457.446C1336.49 457.446 1343.81 464.768 1343.81 473.779V511.326C1343.81 520.336 1336.49 527.659 1327.47 527.659H1327.49Z"
          fill="#04030F"
        />
        <path
          d="M1289.94 527.696L1327.49 527.672C1336.5 527.672 1343.82 520.349 1343.82 511.339V473.792C1343.82 464.782 1336.5 457.459 1327.49 457.459L1289.94 457.435C1280.94 457.435 1273.62 464.757 1273.62 473.768C1273.62 482.778 1280.99 490.161 1290.07 490.161C1299.14 490.161 1306.29 482.815 1306.29 473.78H1311.16C1311.16 485.498 1301.7 495.031 1290.08 495.031C1283.46 495.031 1277.54 492.007 1273.63 487.271V511.363C1273.63 520.374 1280.96 527.696 1289.96 527.696H1289.94Z"
          fill="white"
        />
        <path
          d="M1402.53 532.564H1364.94C1353.23 532.564 1343.72 523.048 1343.72 511.343L1343.69 473.774C1343.69 462.081 1353.21 452.564 1364.92 452.564C1373.09 452.564 1380.19 457.207 1383.74 463.977C1387.29 457.195 1394.38 452.564 1402.55 452.564C1414.26 452.564 1423.78 462.081 1423.78 473.774L1423.75 511.343C1423.75 523.035 1414.24 532.564 1402.53 532.564ZM1364.92 457.438C1355.9 457.438 1348.57 464.767 1348.57 473.774L1348.59 511.343C1348.59 520.361 1355.92 527.691 1364.94 527.691H1402.53C1411.55 527.691 1418.88 520.361 1418.88 511.343L1418.9 473.774C1418.9 464.767 1411.57 457.438 1402.55 457.438C1393.53 457.438 1386.2 464.767 1386.2 473.786L1386.18 497.633H1381.3V474.284H1381.28L1381.26 473.786C1381.26 464.767 1373.93 457.438 1364.9 457.438H1364.92Z"
          fill="#04030F"
        />
        <path
          d="M1364.92 457.439C1355.9 457.439 1348.57 464.769 1348.57 473.775L1348.59 511.344C1348.59 520.363 1355.92 527.692 1364.94 527.692H1402.53C1411.55 527.692 1418.88 520.363 1418.88 511.344L1418.9 473.775C1418.9 464.769 1411.57 457.439 1402.55 457.439C1393.53 457.439 1386.2 464.769 1386.2 473.787L1386.18 497.634H1381.3V474.285H1381.28L1381.26 473.799C1381.26 464.781 1373.92 457.452 1364.9 457.452L1364.92 457.439Z"
          fill="white"
        />
        <path
          d="M1498.79 473.786C1498.79 462.081 1489.27 452.564 1477.57 452.564C1469.43 452.564 1462.36 457.171 1458.81 463.917C1455.25 457.183 1448.16 452.564 1440 452.564H1439.99C1428.3 452.564 1418.78 462.081 1418.78 473.786L1418.8 511.355C1418.8 523.048 1428.31 532.564 1440 532.564C1448.16 532.564 1455.25 527.946 1458.8 521.176C1462.35 527.934 1469.43 532.564 1477.59 532.564C1489.3 532.564 1498.82 523.048 1498.82 511.355L1498.79 473.786ZM1477.6 527.691C1468.58 527.691 1461.25 520.361 1461.25 511.343L1461.23 510.918C1461.19 499.249 1451.69 489.769 1440 489.769V494.643C1449.02 494.643 1456.35 501.972 1456.35 510.99V511.343C1456.35 520.361 1449.02 527.691 1440 527.691C1430.98 527.691 1423.68 520.361 1423.68 511.355L1423.65 473.786C1423.65 464.78 1430.98 457.438 1440 457.438C1449.04 457.438 1456.39 464.767 1456.39 473.762C1456.39 479.426 1458.59 484.81 1462.58 488.906C1466.61 493.051 1471.95 495.336 1477.6 495.336V490.462C1468.74 490.462 1461.27 482.804 1461.25 473.75C1461.25 464.755 1468.58 457.438 1477.58 457.438C1486.58 457.438 1493.93 464.767 1493.93 473.786L1493.95 511.355C1493.95 520.361 1486.62 527.691 1477.6 527.691Z"
          fill="#04030F"
        />
        <path
          d="M1477.6 527.692C1468.58 527.692 1461.25 520.363 1461.25 511.344L1461.23 510.919C1461.19 499.25 1451.69 489.77 1440 489.77V494.644C1449.02 494.644 1456.35 501.973 1456.35 510.992V511.344C1456.35 520.363 1449.02 527.692 1440 527.692C1430.98 527.692 1423.68 520.363 1423.68 511.356L1423.65 473.787C1423.65 464.781 1430.98 457.439 1440 457.439C1449.04 457.439 1456.39 464.769 1456.39 473.763C1456.39 479.427 1458.59 484.811 1462.58 488.907C1466.61 493.052 1471.95 495.337 1477.6 495.337V490.463C1468.74 490.463 1461.27 482.806 1461.25 473.751C1461.25 464.756 1468.58 457.439 1477.58 457.439C1486.58 457.439 1493.93 464.769 1493.93 473.787L1493.95 511.356C1493.95 520.363 1486.62 527.692 1477.6 527.692Z"
          fill="white"
        />
        <path
          d="M416.087 473.786C416.087 462.082 406.568 452.565 394.862 452.565C386.729 452.565 379.654 457.171 376.104 463.917C372.542 457.184 365.455 452.565 357.298 452.565H357.286C345.591 452.565 336.073 462.082 336.073 473.786L336.097 511.356C336.097 523.048 345.603 532.565 357.298 532.565C365.455 532.565 372.542 527.946 376.092 521.176C379.642 527.934 386.729 532.565 394.886 532.565C406.593 532.565 416.111 523.048 416.111 511.356L416.087 473.786ZM394.898 527.691C385.878 527.691 378.548 520.362 378.548 511.343L378.523 510.918C378.487 499.25 368.98 489.769 357.298 489.769V494.643C366.318 494.643 373.648 501.972 373.648 510.991V511.343C373.648 520.362 366.318 527.691 357.298 527.691C348.278 527.691 340.972 520.362 340.972 511.356L340.948 473.786C340.948 464.78 348.278 457.439 357.298 457.439C366.33 457.439 373.685 464.768 373.685 473.762C373.685 479.426 375.885 484.81 379.873 488.906C383.909 493.051 389.245 495.336 394.898 495.336V490.462C386.036 490.462 378.56 482.805 378.548 473.75C378.548 464.756 385.878 457.439 394.874 457.439C403.87 457.439 411.224 464.768 411.224 473.786L411.249 511.356C411.249 520.362 403.918 527.691 394.898 527.691Z"
          fill="url(#paint1_linear_1470_5262)"
        />
        <path
          d="M394.898 527.692C385.878 527.692 378.547 520.363 378.547 511.345L378.523 510.919C378.486 499.251 368.98 489.771 357.298 489.771V494.644C366.318 494.644 373.648 501.974 373.648 510.992V511.345C373.648 520.363 366.318 527.692 357.298 527.692C348.278 527.692 340.972 520.363 340.972 511.357L340.947 473.788C340.947 464.781 348.278 457.44 357.298 457.44C366.33 457.44 373.685 464.769 373.685 473.763C373.685 479.427 375.885 484.812 379.872 488.908C383.908 493.052 389.245 495.337 394.898 495.337V490.463C386.036 490.463 378.559 482.806 378.547 473.751C378.547 464.757 385.878 457.44 394.873 457.44C403.869 457.44 411.224 464.769 411.224 473.788L411.248 511.357C411.248 520.363 403.918 527.692 394.898 527.692Z"
          fill="#04030F"
        />
        <path
          d="M493.125 473.786C493.125 462.082 483.607 452.565 471.9 452.565C463.767 452.565 456.692 457.171 453.143 463.917C449.581 457.184 442.494 452.565 434.337 452.565H434.324C422.63 452.565 413.111 462.082 413.111 473.786L413.136 511.356C413.136 523.048 422.642 532.565 434.337 532.565C442.494 532.565 449.581 527.946 453.13 521.176C456.68 527.934 463.767 532.565 471.924 532.565C483.631 532.565 493.15 523.048 493.15 511.356L493.125 473.786ZM471.937 527.691C462.916 527.691 455.586 520.362 455.586 511.343L455.562 510.918C455.525 499.25 446.019 489.769 434.337 489.769V494.643C443.357 494.643 450.687 501.972 450.687 510.991V511.343C450.687 520.362 443.357 527.691 434.337 527.691C425.316 527.691 418.01 520.362 418.01 511.356L417.986 473.786C417.986 464.78 425.316 457.439 434.337 457.439C443.369 457.439 450.724 464.768 450.724 473.762C450.724 479.426 452.924 484.81 456.911 488.906C460.947 493.051 466.284 495.336 471.937 495.336V490.462C463.074 490.462 455.598 482.805 455.586 473.75C455.586 464.756 462.916 457.439 471.912 457.439C480.908 457.439 488.263 464.768 488.263 473.786L488.287 511.356C488.287 520.362 480.957 527.691 471.937 527.691Z"
          fill="url(#paint2_linear_1470_5262)"
        />
        <path
          d="M471.936 527.692C462.916 527.692 455.586 520.363 455.586 511.345L455.562 510.919C455.525 499.251 446.019 489.771 434.336 489.771V494.644C443.356 494.644 450.687 501.974 450.687 510.992V511.345C450.687 520.363 443.356 527.692 434.336 527.692C425.316 527.692 418.01 520.363 418.01 511.357L417.986 473.788C417.986 464.781 425.316 457.44 434.336 457.44C443.369 457.44 450.723 464.769 450.723 473.763C450.723 479.427 452.924 484.812 456.911 488.908C460.947 493.052 466.284 495.337 471.936 495.337V490.463C463.074 490.463 455.598 482.806 455.586 473.751C455.586 464.757 462.916 457.44 471.912 457.44C480.908 457.44 488.263 464.769 488.263 473.788L488.287 511.357C488.287 520.363 480.956 527.692 471.936 527.692Z"
          fill="#04030F"
        />
        <path
          d="M490.149 511.359H495.032C495.032 502.351 502.361 495.036 511.358 495.036C520.356 495.036 527.685 502.364 527.685 511.359H532.567C532.567 499.673 523.058 490.154 511.358 490.154V490.118C502.349 490.118 495.032 482.79 495.032 473.794C495.032 464.798 502.361 457.47 511.358 457.47L548.894 457.446C557.903 457.446 565.22 464.774 565.22 473.77H570.103C570.103 462.071 560.594 452.564 548.894 452.564L511.358 452.589C499.658 452.589 490.149 462.096 490.149 473.794C490.149 481.95 494.776 489.034 501.533 492.577C494.776 496.119 490.149 503.216 490.149 511.359Z"
          fill="url(#paint3_linear_1470_5262)"
        />
        <path
          d="M570.103 473.77H565.22C565.22 482.778 557.891 490.094 548.894 490.094C539.896 490.094 532.567 482.766 532.567 473.77H527.685C527.685 485.468 537.194 494.975 548.894 494.975V495.012C557.903 495.012 565.22 502.34 565.22 511.335C565.22 520.331 557.891 527.659 548.894 527.659L511.358 527.684C502.349 527.684 495.032 520.356 495.032 511.36H490.149C490.149 523.058 499.658 532.565 511.358 532.565L548.894 532.541C560.582 532.541 570.103 523.034 570.103 511.335C570.103 503.18 565.476 496.095 558.719 492.553C565.476 489.01 570.103 481.914 570.103 473.77Z"
          fill="url(#paint4_linear_1470_5262)"
        />
        <path
          d="M549.028 527.696L511.492 527.721C502.483 527.721 495.166 520.393 495.166 511.385C495.166 502.377 502.495 495.049 511.492 495.049C520.49 495.049 527.819 502.377 527.819 511.385H532.701C532.701 499.687 523.192 490.167 511.492 490.167C502.483 490.167 495.166 482.827 495.166 473.819C495.166 464.811 502.495 457.496 511.492 457.496L549.028 457.471C558.037 457.471 565.354 464.799 565.354 473.807C565.354 482.815 558.025 490.131 549.028 490.131C540.03 490.131 532.701 482.803 532.701 473.807H527.819C527.819 485.505 537.328 495.012 549.028 495.012V495.049C558.037 495.049 565.354 502.377 565.354 511.373C565.354 520.368 558.025 527.696 549.028 527.696Z"
          fill="#04030F"
        />
        <path
          d="M-42.1108 173.775C-42.1108 164.769 -34.7805 157.439 -25.7604 157.439C-16.7402 157.439 -9.40988 164.769 -9.40988 173.787L-9.37341 211.356H-4.49865C-4.49865 202.338 2.83171 195.009 11.8518 195.009C20.872 195.009 28.2023 202.338 28.2023 211.356C28.2023 220.375 20.872 227.704 11.8518 227.704H-25.736C-34.7562 227.704 -42.0865 220.375 -42.0865 211.356L-42.1108 173.787V173.775Z"
          fill="white"
        />
        <path
          d="M33.0773 211.343C33.0773 199.65 23.5588 190.134 11.8521 190.134C5.27542 190.134 -0.620483 193.148 -4.51056 197.852L-4.53486 173.786C-4.53486 162.081 -14.0534 152.564 -25.7601 152.564C-37.4668 152.564 -46.9854 162.081 -46.9854 173.774L-46.961 211.343C-46.961 223.048 -37.4425 232.564 -25.7358 232.564H11.8521C23.5466 232.564 33.0652 223.048 33.0773 211.355V211.343ZM11.8521 227.691H-25.7358C-34.7559 227.691 -42.0863 220.361 -42.0863 211.343L-42.1106 173.774C-42.1106 164.767 -34.7802 157.438 -25.7601 157.438C-16.74 157.438 -9.40963 164.767 -9.40963 173.786L-9.37316 211.343H-4.49839C-4.49839 202.324 2.83197 194.995 11.8521 194.995C20.8722 194.995 28.2026 202.324 28.2026 211.343C28.2026 220.361 20.8722 227.691 11.8521 227.691Z"
          fill={themeColor}
        />
        <path
          d="M49.3267 232.564C37.62 232.564 28.1015 223.048 28.1015 211.343L28.0771 173.774C28.0771 162.081 37.5957 152.564 49.3024 152.564C61.0091 152.564 70.5276 162.081 70.5276 173.786V211.367C70.5276 223.048 61.0212 232.552 49.3388 232.552L49.3267 232.564ZM49.3024 157.438C40.2823 157.438 32.9519 164.767 32.9519 173.774L32.9763 211.343C32.9763 220.361 40.3066 227.691 49.3267 227.691C58.3468 227.691 65.6529 220.374 65.6529 211.367V173.786C65.6529 164.767 58.3225 157.438 49.3024 157.438Z"
          fill={themeColor}
        />
        <path
          d="M49.29 157.439C40.2698 157.439 32.9395 164.769 32.9395 173.775L32.9638 211.344C32.9638 220.363 40.2942 227.692 49.3143 227.692C58.3344 227.692 65.6405 220.375 65.6405 211.368V173.787C65.6405 164.769 58.3101 157.439 49.29 157.439Z"
          fill="white"
        />
        <path
          d="M70.4023 173.775C70.4023 164.769 77.7327 157.439 86.7528 157.439C95.7729 157.439 103.103 164.769 103.103 173.787L103.14 211.356H108.015C108.015 202.338 115.345 195.009 124.365 195.009C133.385 195.009 140.715 202.338 140.715 211.356C140.715 220.375 133.385 227.704 124.365 227.704H86.7771C77.757 227.704 70.4267 220.375 70.4267 211.356L70.4023 173.787V173.775Z"
          fill="white"
        />
        <path
          d="M145.59 211.343C145.59 199.65 136.072 190.134 124.365 190.134C117.789 190.134 111.893 193.148 108.003 197.852L107.978 173.786C107.978 162.081 98.4598 152.564 86.7531 152.564C75.0464 152.564 65.5278 162.081 65.5278 173.774L65.5522 211.343C65.5522 223.048 75.0707 232.564 86.7774 232.564H124.365C136.06 232.564 145.578 223.048 145.59 211.355V211.343ZM124.365 227.691H86.7774C77.7573 227.691 70.4269 220.361 70.4269 211.343L70.4026 173.774C70.4026 164.767 77.733 157.438 86.7531 157.438C95.7732 157.438 103.104 164.767 103.104 173.786L103.14 211.343H108.015C108.015 202.324 115.345 194.995 124.365 194.995C133.385 194.995 140.716 202.324 140.716 211.343C140.716 220.361 133.385 227.691 124.365 227.691Z"
          fill={themeColor}
        />
        <path
          d="M220.604 173.786C220.604 162.081 211.086 152.564 199.379 152.564C191.246 152.564 184.171 157.171 180.622 163.917C177.06 157.183 169.973 152.564 161.816 152.564H161.803C150.109 152.564 140.59 162.081 140.59 173.786L140.615 211.355C140.615 223.048 150.121 232.564 161.816 232.564C169.973 232.564 177.06 227.946 180.609 221.176C184.159 227.934 191.246 232.564 199.403 232.564C211.11 232.564 220.629 223.048 220.629 211.355L220.604 173.786ZM199.416 227.691C190.395 227.691 183.065 220.361 183.065 211.343L183.041 210.918C183.004 199.249 173.498 189.769 161.816 189.769V194.643C170.836 194.643 178.166 201.972 178.166 210.99V211.343C178.166 220.361 170.836 227.691 161.816 227.691C152.795 227.691 145.489 220.361 145.489 211.355L145.465 173.786C145.465 164.78 152.795 157.438 161.816 157.438C170.848 157.438 178.203 164.767 178.203 173.762C178.203 179.426 180.403 184.81 184.39 188.906C188.426 193.051 193.763 195.336 199.416 195.336V190.462C190.554 190.462 183.077 182.804 183.065 173.75C183.065 164.755 190.395 157.438 199.391 157.438C208.387 157.438 215.742 164.767 215.742 173.786L215.766 211.355C215.766 220.361 208.436 227.691 199.416 227.691Z"
          fill={themeColor}
        />
        <path
          d="M199.415 227.692C190.395 227.692 183.065 220.363 183.065 211.344L183.041 210.919C183.004 199.25 173.498 189.77 161.815 189.77V194.644C170.835 194.644 178.166 201.973 178.166 210.992V211.344C178.166 220.363 170.835 227.692 161.815 227.692C152.795 227.692 145.489 220.363 145.489 211.356L145.465 173.787C145.465 164.781 152.795 157.439 161.815 157.439C170.848 157.439 178.202 164.769 178.202 173.763C178.202 179.427 180.403 184.811 184.39 188.907C188.426 193.052 193.763 195.337 199.415 195.337V190.463C190.553 190.463 183.077 182.806 183.065 173.751C183.065 164.756 190.395 157.439 199.391 157.439C208.387 157.439 215.742 164.769 215.742 173.787L215.766 211.356C215.766 220.363 208.435 227.692 199.415 227.692Z"
          fill="white"
        />
        <path
          d="M274.364 152.589L236.823 152.564C225.503 152.564 216.224 161.502 215.653 172.686H215.629V211.362C215.629 223.056 225.139 232.564 236.823 232.564L274.364 232.54C286.06 232.54 295.57 223.032 295.57 211.338V173.791C295.57 162.097 286.06 152.589 274.364 152.589ZM274.364 227.659L236.823 227.683C227.823 227.683 220.499 220.36 220.499 211.35V187.258C224.41 191.994 230.337 195.017 236.944 195.017C248.567 195.017 258.029 185.485 258.029 173.767H253.158C253.158 182.801 245.883 190.148 236.932 190.148C227.981 190.148 220.487 182.789 220.487 173.754C220.487 164.72 227.811 157.422 236.811 157.422L274.352 157.446C283.364 157.446 290.688 164.768 290.688 173.779V211.326C290.688 220.336 283.364 227.659 274.352 227.659H274.364Z"
          fill={themeColor}
        />
        <path
          d="M236.822 227.696L274.364 227.672C283.376 227.672 290.7 220.349 290.7 211.339V173.792C290.7 164.782 283.376 157.459 274.364 157.459L236.822 157.435C227.823 157.435 220.499 164.757 220.499 173.768C220.499 182.778 227.871 190.161 236.944 190.161C246.017 190.161 253.17 182.815 253.17 173.78H258.04C258.04 185.498 248.579 195.031 236.956 195.031C230.337 195.031 224.422 192.007 220.511 187.271V211.363C220.511 220.374 227.835 227.696 236.835 227.696H236.822Z"
          fill="white"
        />
        <path
          d="M349.407 232.564H311.819C300.113 232.564 290.594 223.048 290.594 211.343L290.57 173.774C290.57 162.081 300.088 152.564 311.795 152.564C319.964 152.564 327.064 157.207 330.613 163.977C334.163 157.195 341.262 152.564 349.432 152.564C361.138 152.564 370.657 162.081 370.657 173.774L370.632 211.343C370.632 223.035 361.114 232.564 349.407 232.564ZM311.795 157.438C302.775 157.438 295.445 164.767 295.445 173.774L295.469 211.343C295.469 220.361 302.799 227.691 311.819 227.691H349.407C358.427 227.691 365.758 220.361 365.758 211.343L365.782 173.774C365.782 164.767 358.452 157.438 349.432 157.438C340.411 157.438 333.081 164.767 333.081 173.786L333.057 197.633H328.182V174.284H328.158L328.133 173.786C328.133 164.767 320.803 157.438 311.783 157.438H311.795Z"
          fill={themeColor}
        />
        <path
          d="M311.795 157.439C302.775 157.439 295.444 164.769 295.444 173.775L295.469 211.344C295.469 220.363 302.799 227.692 311.819 227.692H349.407C358.427 227.692 365.757 220.363 365.757 211.344L365.782 173.775C365.782 164.769 358.451 157.439 349.431 157.439C340.411 157.439 333.081 164.769 333.081 173.787L333.056 197.634H328.182V174.285H328.157L328.133 173.799C328.133 164.781 320.803 157.452 311.783 157.452L311.795 157.439Z"
          fill="white"
        />
        <path
          d="M445.671 173.786C445.671 162.081 436.152 152.564 424.446 152.564C416.313 152.564 409.238 157.171 405.688 163.917C402.126 157.183 395.039 152.564 386.882 152.564H386.87C375.175 152.564 365.657 162.081 365.657 173.786L365.681 211.355C365.681 223.048 375.187 232.564 386.882 232.564C395.039 232.564 402.126 227.946 405.676 221.176C409.226 227.934 416.313 232.564 424.47 232.564C436.177 232.564 445.695 223.048 445.695 211.355L445.671 173.786ZM424.482 227.691C415.462 227.691 408.132 220.361 408.132 211.343L408.107 210.918C408.071 199.249 398.564 189.769 386.882 189.769V194.643C395.902 194.643 403.232 201.972 403.232 210.99V211.343C403.232 220.361 395.902 227.691 386.882 227.691C377.862 227.691 370.556 220.361 370.556 211.355L370.532 173.786C370.532 164.78 377.862 157.438 386.882 157.438C395.914 157.438 403.269 164.767 403.269 173.762C403.269 179.426 405.469 184.81 409.457 188.906C413.493 193.051 418.829 195.336 424.482 195.336V190.462C415.62 190.462 408.144 182.804 408.132 173.75C408.132 164.755 415.462 157.438 424.458 157.438C433.453 157.438 440.808 164.767 440.808 173.786L440.832 211.355C440.832 220.361 433.502 227.691 424.482 227.691Z"
          fill={themeColor}
        />
        <path
          d="M424.482 227.692C415.462 227.692 408.131 220.363 408.131 211.344L408.107 210.919C408.07 199.25 398.564 189.77 386.882 189.77V194.644C395.902 194.644 403.232 201.973 403.232 210.992V211.344C403.232 220.363 395.902 227.692 386.882 227.692C377.862 227.692 370.556 220.363 370.556 211.356L370.531 173.787C370.531 164.781 377.862 157.439 386.882 157.439C395.914 157.439 403.269 164.769 403.269 173.763C403.269 179.427 405.469 184.811 409.456 188.907C413.492 193.052 418.829 195.337 424.482 195.337V190.463C415.62 190.463 408.143 182.806 408.131 173.751C408.131 164.756 415.462 157.439 424.457 157.439C433.453 157.439 440.808 164.769 440.808 173.787L440.832 211.356C440.832 220.363 433.502 227.692 424.482 227.692Z"
          fill="white"
        />
        <path
          d="M440.695 211.359H445.577C445.577 202.351 452.907 195.036 461.904 195.036C470.901 195.036 478.231 202.364 478.231 211.359H483.113C483.113 199.673 473.604 190.154 461.904 190.154V190.118C452.895 190.118 445.577 182.79 445.577 173.794C445.577 164.798 452.907 157.47 461.904 157.47L499.44 157.446C508.449 157.446 515.766 164.774 515.766 173.77H520.649C520.649 162.071 511.14 152.564 499.44 152.564L461.904 152.589C450.204 152.589 440.695 162.096 440.695 173.794C440.695 181.95 445.322 189.034 452.079 192.577C445.322 196.119 440.695 203.216 440.695 211.359Z"
          fill={themeColor}
        />
        <path
          d="M520.649 173.77H515.766C515.766 182.778 508.437 190.094 499.44 190.094C490.442 190.094 483.113 182.766 483.113 173.77H478.231C478.231 185.468 487.739 194.975 499.44 194.975V195.012C508.449 195.012 515.766 202.34 515.766 211.335C515.766 220.331 508.437 227.659 499.44 227.659L461.904 227.684C452.895 227.684 445.577 220.356 445.577 211.36H440.695C440.695 223.058 450.204 232.565 461.904 232.565L499.44 232.541C511.128 232.541 520.649 223.034 520.649 211.335C520.649 203.18 516.022 196.095 509.265 192.553C516.022 189.01 520.649 181.914 520.649 173.77Z"
          fill={themeColor}
        />
        <path
          d="M499.574 227.696L462.038 227.721C453.029 227.721 445.711 220.393 445.711 211.385C445.711 202.377 453.041 195.049 462.038 195.049C471.035 195.049 478.365 202.377 478.365 211.385H483.247C483.247 199.687 473.738 190.167 462.038 190.167C453.029 190.167 445.711 182.827 445.711 173.819C445.711 164.811 453.041 157.496 462.038 157.496L499.574 157.471C508.583 157.471 515.9 164.799 515.9 173.807C515.9 182.815 508.571 190.131 499.574 190.131C490.576 190.131 483.247 182.803 483.247 173.807H478.365C478.365 185.505 487.873 195.012 499.574 195.012V195.049C508.583 195.049 515.9 202.377 515.9 211.373C515.9 220.368 508.571 227.696 499.574 227.696Z"
          fill="white"
        />
        <circle cx="534.648" cy="192.564" r="6" fill="white" />
        <circle cx="534.648" cy="192.564" r="6" stroke="#04030F" />
        <path
          d="M628.662 173.786C628.662 162.082 619.144 152.565 607.437 152.565C599.305 152.565 592.229 157.171 588.68 163.917C585.118 157.184 578.031 152.565 569.874 152.565H569.861C558.167 152.565 548.648 162.082 548.648 173.786L548.673 211.356C548.673 223.048 558.179 232.565 569.874 232.565C578.031 232.565 585.118 227.946 588.668 221.176C592.217 227.934 599.305 232.565 607.462 232.565C619.168 232.565 628.687 223.048 628.687 211.356L628.662 173.786ZM607.474 227.691C598.454 227.691 591.123 220.362 591.123 211.343L591.099 210.918C591.062 199.25 581.556 189.769 569.874 189.769V194.643C578.894 194.643 586.224 201.972 586.224 210.991V211.343C586.224 220.362 578.894 227.691 569.874 227.691C560.854 227.691 553.548 220.362 553.548 211.356L553.523 173.786C553.523 164.78 560.854 157.439 569.874 157.439C578.906 157.439 586.261 164.768 586.261 173.762C586.261 179.426 588.461 184.81 592.448 188.906C596.484 193.051 601.821 195.336 607.474 195.336V190.462C598.612 190.462 591.135 182.805 591.123 173.75C591.123 164.756 598.454 157.439 607.449 157.439C616.445 157.439 623.8 164.768 623.8 173.786L623.824 211.356C623.824 220.362 616.494 227.691 607.474 227.691Z"
          fill={themeColor}
        />
        <path
          d="M607.473 227.692C598.453 227.692 591.123 220.363 591.123 211.345L591.099 210.919C591.062 199.251 581.556 189.771 569.873 189.771V194.644C578.894 194.644 586.224 201.974 586.224 210.992V211.345C586.224 220.363 578.894 227.692 569.873 227.692C560.853 227.692 553.547 220.363 553.547 211.357L553.523 173.788C553.523 164.781 560.853 157.44 569.873 157.44C578.906 157.44 586.26 164.769 586.26 173.763C586.26 179.427 588.461 184.812 592.448 188.908C596.484 193.052 601.821 195.337 607.473 195.337V190.463C598.611 190.463 591.135 182.806 591.123 173.751C591.123 164.757 598.453 157.44 607.449 157.44C616.445 157.44 623.8 164.769 623.8 173.788L623.824 211.357C623.824 220.363 616.494 227.692 607.473 227.692Z"
          fill="white"
        />
        <path
          d="M705.701 173.786C705.701 162.082 696.183 152.565 684.476 152.565C676.343 152.565 669.268 157.171 665.718 163.917C662.156 157.184 655.069 152.565 646.912 152.565H646.9C635.206 152.565 625.687 162.082 625.687 173.786L625.711 211.356C625.711 223.048 635.218 232.565 646.912 232.565C655.069 232.565 662.156 227.946 665.706 221.176C669.256 227.934 676.343 232.565 684.5 232.565C696.207 232.565 705.725 223.048 705.725 211.356L705.701 173.786ZM684.512 227.691C675.492 227.691 668.162 220.362 668.162 211.343L668.137 210.918C668.101 199.25 658.595 189.769 646.912 189.769V194.643C655.932 194.643 663.263 201.972 663.263 210.991V211.343C663.263 220.362 655.932 227.691 646.912 227.691C637.892 227.691 630.586 220.362 630.586 211.356L630.562 173.786C630.562 164.78 637.892 157.439 646.912 157.439C655.945 157.439 663.299 164.768 663.299 173.762C663.299 179.426 665.5 184.81 669.487 188.906C673.523 193.051 678.859 195.336 684.512 195.336V190.462C675.65 190.462 668.174 182.805 668.162 173.75C668.162 164.756 675.492 157.439 684.488 157.439C693.484 157.439 700.838 164.768 700.838 173.786L700.863 211.356C700.863 220.362 693.532 227.691 684.512 227.691Z"
          fill={themeColor}
        />
        <path
          d="M684.512 227.692C675.492 227.692 668.162 220.363 668.162 211.345L668.137 210.919C668.101 199.251 658.594 189.771 646.912 189.771V194.644C655.932 194.644 663.262 201.974 663.262 210.992V211.345C663.262 220.363 655.932 227.692 646.912 227.692C637.892 227.692 630.586 220.363 630.586 211.357L630.562 173.788C630.562 164.781 637.892 157.44 646.912 157.44C655.944 157.44 663.299 164.769 663.299 173.763C663.299 179.427 665.499 184.812 669.487 188.908C673.523 193.052 678.859 195.337 684.512 195.337V190.463C675.65 190.463 668.174 182.806 668.162 173.751C668.162 164.757 675.492 157.44 684.488 157.44C693.484 157.44 700.838 164.769 700.838 173.788L700.863 211.357C700.863 220.363 693.532 227.692 684.512 227.692Z"
          fill="white"
        />
        <path
          d="M702.725 211.359H707.607C707.607 202.351 714.937 195.036 723.934 195.036C732.931 195.036 740.261 202.364 740.261 211.359H745.143C745.143 199.673 735.634 190.154 723.934 190.154V190.118C714.924 190.118 707.607 182.79 707.607 173.794C707.607 164.798 714.937 157.47 723.934 157.47L761.469 157.446C770.479 157.446 777.796 164.774 777.796 173.77H782.678C782.678 162.071 773.17 152.564 761.469 152.564L723.934 152.589C712.234 152.589 702.725 162.096 702.725 173.794C702.725 181.95 707.352 189.034 714.109 192.577C707.352 196.119 702.725 203.216 702.725 211.359Z"
          fill={themeColor}
        />
        <path
          d="M782.678 173.77H777.796C777.796 182.778 770.467 190.094 761.469 190.094C752.472 190.094 745.143 182.766 745.143 173.77H740.261C740.261 185.468 749.769 194.975 761.469 194.975V195.012C770.479 195.012 777.796 202.34 777.796 211.335C777.796 220.331 770.467 227.659 761.469 227.659L723.934 227.684C714.924 227.684 707.607 220.356 707.607 211.36H702.725C702.725 223.058 712.234 232.565 723.934 232.565L761.469 232.541C773.157 232.541 782.678 223.034 782.678 211.335C782.678 203.18 778.052 196.095 771.295 192.553C778.052 189.01 782.678 181.914 782.678 173.77Z"
          fill={themeColor}
        />
        <path
          d="M761.603 227.696L724.068 227.721C715.058 227.721 707.741 220.393 707.741 211.385C707.741 202.377 715.071 195.049 724.068 195.049C733.065 195.049 740.395 202.377 740.395 211.385H745.277C745.277 199.687 735.768 190.167 724.068 190.167C715.058 190.167 707.741 182.827 707.741 173.819C707.741 164.811 715.071 157.496 724.068 157.496L761.603 157.471C770.613 157.471 777.93 164.799 777.93 173.807C777.93 182.815 770.601 190.131 761.603 190.131C752.606 190.131 745.277 182.803 745.277 173.807H740.395C740.395 185.505 749.903 195.012 761.603 195.012V195.049C770.613 195.049 777.93 202.377 777.93 211.373C777.93 220.368 770.601 227.696 761.603 227.696Z"
          fill="white"
        />
        <circle cx="796.679" cy="192.564" r="6" fill="white" />
        <circle cx="796.679" cy="192.564" r="6" stroke="#04030F" />
        <path
          d="M890.693 173.786C890.693 162.081 881.174 152.564 869.467 152.564C861.335 152.564 854.26 157.171 850.71 163.917C847.148 157.183 840.061 152.564 831.904 152.564H831.892C820.197 152.564 810.679 162.081 810.679 173.786L810.703 211.355C810.703 223.048 820.209 232.564 831.904 232.564C840.061 232.564 847.148 227.946 850.698 221.176C854.248 227.934 861.335 232.564 869.492 232.564C881.199 232.564 890.717 223.048 890.717 211.355L890.693 173.786ZM869.504 227.691C860.484 227.691 853.154 220.361 853.154 211.343L853.129 210.918C853.093 199.249 843.586 189.769 831.904 189.769V194.643C840.924 194.643 848.254 201.972 848.254 210.99V211.343C848.254 220.361 840.924 227.691 831.904 227.691C822.884 227.691 815.578 220.361 815.578 211.355L815.553 173.786C815.553 164.78 822.884 157.438 831.904 157.438C840.936 157.438 848.291 164.767 848.291 173.762C848.291 179.426 850.491 184.81 854.479 188.906C858.515 193.051 863.851 195.336 869.504 195.336V190.462C860.642 190.462 853.166 182.804 853.154 173.75C853.154 164.755 860.484 157.438 869.48 157.438C878.475 157.438 885.83 164.767 885.83 173.786L885.854 211.355C885.854 220.361 878.524 227.691 869.504 227.691Z"
          fill={themeColor}
        />
        <path
          d="M869.504 227.692C860.484 227.692 853.153 220.363 853.153 211.344L853.129 210.919C853.092 199.25 843.586 189.77 831.904 189.77V194.644C840.924 194.644 848.254 201.973 848.254 210.992V211.344C848.254 220.363 840.924 227.692 831.904 227.692C822.884 227.692 815.578 220.363 815.578 211.356L815.553 173.787C815.553 164.781 822.884 157.439 831.904 157.439C840.936 157.439 848.291 164.769 848.291 173.763C848.291 179.427 850.491 184.811 854.478 188.907C858.514 193.052 863.851 195.337 869.504 195.337V190.463C860.642 190.463 853.165 182.806 853.153 173.751C853.153 164.756 860.484 157.439 869.479 157.439C878.475 157.439 885.83 164.769 885.83 173.787L885.854 211.356C885.854 220.363 878.524 227.692 869.504 227.692Z"
          fill="#04030F"
        />
        <path
          d="M944.453 152.589L906.911 152.564C895.591 152.564 886.312 161.502 885.742 172.686H885.717V211.362C885.717 223.056 895.227 232.564 906.911 232.564L944.453 232.54C956.149 232.54 965.658 223.032 965.658 211.338V173.791C965.658 162.097 956.149 152.589 944.453 152.589ZM944.453 227.659L906.911 227.683C897.911 227.683 890.588 220.36 890.588 211.35V187.258C894.498 191.994 900.425 195.017 907.032 195.017C918.656 195.017 928.117 185.485 928.117 173.767H923.247C923.247 182.801 915.972 190.148 907.02 190.148C898.069 190.148 890.575 182.789 890.575 173.754C890.575 164.72 897.899 157.422 906.899 157.422L944.44 157.446C953.452 157.446 960.776 164.768 960.776 173.779V211.326C960.776 220.336 953.452 227.659 944.44 227.659H944.453Z"
          fill={themeColor}
        />
        <path
          d="M906.911 227.696L944.453 227.672C953.465 227.672 960.788 220.349 960.788 211.339V173.792C960.788 164.782 953.465 157.459 944.453 157.459L906.911 157.435C897.912 157.435 890.588 164.757 890.588 173.768C890.588 182.778 897.96 190.161 907.033 190.161C916.105 190.161 923.259 182.815 923.259 173.78H928.129C928.129 185.498 918.668 195.031 907.045 195.031C900.426 195.031 894.511 192.007 890.6 187.271V211.363C890.6 220.374 897.924 227.696 906.923 227.696H906.911Z"
          fill="#04030F"
        />
        <path
          d="M1019.5 232.564H981.908C970.201 232.564 960.683 223.048 960.683 211.343L960.658 173.774C960.658 162.081 970.177 152.564 981.883 152.564C990.053 152.564 997.152 157.207 1000.7 163.977C1004.25 157.195 1011.35 152.564 1019.52 152.564C1031.23 152.564 1040.75 162.081 1040.75 173.774L1040.72 211.343C1040.72 223.035 1031.2 232.564 1019.5 232.564ZM981.883 157.438C972.863 157.438 965.533 164.767 965.533 173.774L965.557 211.343C965.557 220.361 972.888 227.691 981.908 227.691H1019.5C1028.52 227.691 1035.85 220.361 1035.85 211.343L1035.87 173.774C1035.87 164.767 1028.54 157.438 1019.52 157.438C1010.5 157.438 1003.17 164.767 1003.17 173.786L1003.15 197.633H998.27V174.284H998.246L998.222 173.786C998.222 164.767 990.891 157.438 981.871 157.438H981.883Z"
          fill={themeColor}
        />
        <path
          d="M981.883 157.439C972.863 157.439 965.533 164.769 965.533 173.775L965.557 211.344C965.557 220.363 972.887 227.692 981.907 227.692H1019.5C1028.52 227.692 1035.85 220.363 1035.85 211.344L1035.87 173.775C1035.87 164.769 1028.54 157.439 1019.52 157.439C1010.5 157.439 1003.17 164.769 1003.17 173.787L1003.14 197.634H998.27V174.285H998.246L998.222 173.799C998.222 164.781 990.891 157.452 981.871 157.452L981.883 157.439Z"
          fill="#04030F"
        />
        <path
          d="M1115.76 173.786C1115.76 162.081 1106.24 152.564 1094.53 152.564C1086.4 152.564 1079.33 157.171 1075.78 163.917C1072.21 157.183 1065.13 152.564 1056.97 152.564H1056.96C1045.26 152.564 1035.75 162.081 1035.75 173.786L1035.77 211.355C1035.77 223.048 1045.28 232.564 1056.97 232.564C1065.13 232.564 1072.21 227.946 1075.76 221.176C1079.31 227.934 1086.4 232.564 1094.56 232.564C1106.26 232.564 1115.78 223.048 1115.78 211.355L1115.76 173.786ZM1094.57 227.691C1085.55 227.691 1078.22 220.361 1078.22 211.343L1078.2 210.918C1078.16 199.249 1068.65 189.769 1056.97 189.769V194.643C1065.99 194.643 1073.32 201.972 1073.32 210.99V211.343C1073.32 220.361 1065.99 227.691 1056.97 227.691C1047.95 227.691 1040.64 220.361 1040.64 211.355L1040.62 173.786C1040.62 164.78 1047.95 157.438 1056.97 157.438C1066 157.438 1073.36 164.767 1073.36 173.762C1073.36 179.426 1075.56 184.81 1079.54 188.906C1083.58 193.051 1088.92 195.336 1094.57 195.336V190.462C1085.71 190.462 1078.23 182.804 1078.22 173.75C1078.22 164.755 1085.55 157.438 1094.55 157.438C1103.54 157.438 1110.9 164.767 1110.9 173.786L1110.92 211.355C1110.92 220.361 1103.59 227.691 1094.57 227.691Z"
          fill={themeColor}
        />
        <path
          d="M1094.57 227.692C1085.55 227.692 1078.22 220.363 1078.22 211.344L1078.2 210.919C1078.16 199.25 1068.65 189.77 1056.97 189.77V194.644C1065.99 194.644 1073.32 201.973 1073.32 210.992V211.344C1073.32 220.363 1065.99 227.692 1056.97 227.692C1047.95 227.692 1040.64 220.363 1040.64 211.356L1040.62 173.787C1040.62 164.781 1047.95 157.439 1056.97 157.439C1066 157.439 1073.36 164.769 1073.36 173.763C1073.36 179.427 1075.56 184.811 1079.54 188.907C1083.58 193.052 1088.92 195.337 1094.57 195.337V190.463C1085.71 190.463 1078.23 182.806 1078.22 173.751C1078.22 164.756 1085.55 157.439 1094.55 157.439C1103.54 157.439 1110.9 164.769 1110.9 173.787L1110.92 211.356C1110.92 220.363 1103.59 227.692 1094.57 227.692Z"
          fill="#04030F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1115.67 211.359H1110.78C1110.78 203.216 1115.41 196.119 1122.17 192.577C1115.41 189.034 1110.78 181.95 1110.78 173.794C1110.78 162.096 1120.29 152.589 1131.99 152.589L1169.53 152.564C1181.23 152.564 1190.74 162.071 1190.74 173.77H1185.85C1185.85 164.774 1178.54 157.446 1169.53 157.446L1131.99 157.47C1123 157.47 1115.67 164.798 1115.67 173.794C1115.67 182.79 1122.98 190.118 1131.99 190.118V190.154C1143.69 190.154 1153.2 199.673 1153.2 211.359H1148.32C1148.32 202.364 1140.99 195.036 1131.99 195.036C1123 195.036 1115.67 202.351 1115.67 211.359ZM1185.85 173.77H1190.74C1190.74 181.914 1186.11 189.011 1179.35 192.553C1186.11 196.095 1190.74 203.18 1190.74 211.336C1190.74 223.034 1181.22 232.541 1169.53 232.541L1131.99 232.565C1120.29 232.565 1110.78 223.058 1110.78 211.36H1115.67C1115.67 220.356 1122.98 227.684 1131.99 227.684L1169.53 227.659C1178.53 227.659 1185.85 220.331 1185.85 211.336C1185.85 202.34 1178.54 195.012 1169.53 195.012V194.975C1157.83 194.975 1148.32 185.468 1148.32 173.77H1153.2C1153.2 182.766 1160.53 190.094 1169.53 190.094C1178.53 190.094 1185.85 182.778 1185.85 173.77Z"
          fill={themeColor}
        />
        <path
          d="M1169.66 227.696L1132.13 227.721C1123.12 227.721 1115.8 220.393 1115.8 211.385C1115.8 202.377 1123.13 195.049 1132.13 195.049C1141.12 195.049 1148.45 202.377 1148.45 211.385H1153.34C1153.34 199.687 1143.83 190.167 1132.13 190.167C1123.12 190.167 1115.8 182.827 1115.8 173.819C1115.8 164.811 1123.13 157.496 1132.13 157.496L1169.66 157.471C1178.67 157.471 1185.99 164.799 1185.99 173.807C1185.99 182.815 1178.66 190.131 1169.66 190.131C1160.66 190.131 1153.34 182.803 1153.34 173.807H1148.45C1148.45 185.505 1157.96 195.012 1169.66 195.012V195.049C1178.67 195.049 1185.99 202.377 1185.99 211.373C1185.99 220.368 1178.66 227.696 1169.66 227.696Z"
          fill="#04030F"
        />
        <circle cx="1204.74" cy="192.564" r="6" fill="white" />
        <circle cx="1204.74" cy="192.564" r="6" stroke="#04030F" />
        <path
          d="M1287.33 192.564C1294.07 189.005 1298.69 181.924 1298.69 173.773C1298.68 162.076 1289.16 152.564 1277.48 152.564H1239.94C1228.25 152.564 1218.74 162.088 1218.74 173.785L1218.76 211.332V211.356H1218.74C1218.74 223.053 1228.25 232.564 1239.94 232.564L1277.49 232.54C1289.19 232.54 1298.7 223.029 1298.7 211.332C1298.7 203.193 1294.09 196.124 1287.34 192.564H1287.33ZM1277.48 227.669L1239.95 227.694V227.657C1230.95 227.657 1223.63 220.332 1223.63 211.332L1223.61 173.785C1223.61 164.772 1230.93 157.435 1239.94 157.435H1277.48C1286.49 157.435 1293.82 164.772 1293.82 173.773C1293.82 182.774 1286.49 190.135 1277.49 190.135C1265.79 190.135 1256.26 199.646 1256.26 211.344H1261.14C1261.14 202.331 1268.47 194.994 1277.49 194.994C1286.5 194.994 1293.82 202.318 1293.82 211.319C1293.82 220.32 1286.49 227.657 1277.48 227.657V227.669Z"
          fill={themeColor}
        />
        <path
          d="M1277.46 227.67L1239.94 227.695V227.658C1230.93 227.658 1223.62 220.334 1223.62 211.333L1223.6 173.786C1223.6 164.773 1230.92 157.437 1239.92 157.437H1277.48C1286.49 157.437 1293.82 164.773 1293.82 173.774C1293.82 182.775 1286.49 190.136 1277.49 190.136C1265.79 190.136 1256.26 199.647 1256.26 211.345H1261.14C1261.14 202.332 1268.47 194.995 1277.49 194.995C1286.5 194.995 1293.82 202.32 1293.82 211.321C1293.82 220.321 1286.49 227.658 1277.48 227.658L1277.46 227.67Z"
          fill="white"
        />
        <path
          d="M1373.72 173.786C1373.72 162.081 1364.2 152.564 1352.49 152.564C1344.36 152.564 1337.28 157.171 1333.73 163.917C1330.17 157.183 1323.08 152.564 1314.93 152.564H1314.91C1303.22 152.564 1293.7 162.081 1293.7 173.786L1293.73 211.355C1293.73 223.048 1303.23 232.564 1314.93 232.564C1323.08 232.564 1330.17 227.946 1333.72 221.176C1337.27 227.934 1344.36 232.564 1352.51 232.564C1364.22 232.564 1373.74 223.048 1373.74 211.355L1373.72 173.786ZM1352.53 227.691C1343.51 227.691 1336.18 220.361 1336.18 211.343L1336.15 210.918C1336.12 199.249 1326.61 189.769 1314.93 189.769V194.643C1323.95 194.643 1331.28 201.972 1331.28 210.99V211.343C1331.28 220.361 1323.95 227.691 1314.93 227.691C1305.91 227.691 1298.6 220.361 1298.6 211.355L1298.58 173.786C1298.58 164.78 1305.91 157.438 1314.93 157.438C1323.96 157.438 1331.31 164.767 1331.31 173.762C1331.31 179.426 1333.51 184.81 1337.5 188.906C1341.54 193.051 1346.87 195.336 1352.53 195.336V190.462C1343.66 190.462 1336.19 182.804 1336.18 173.75C1336.18 164.755 1343.51 157.438 1352.5 157.438C1361.5 157.438 1368.85 164.767 1368.85 173.786L1368.88 211.355C1368.88 220.361 1361.55 227.691 1352.53 227.691Z"
          fill={themeColor}
        />
        <path
          d="M1352.53 227.692C1343.51 227.692 1336.18 220.363 1336.18 211.344L1336.15 210.919C1336.12 199.25 1326.61 189.77 1314.93 189.77V194.644C1323.95 194.644 1331.28 201.973 1331.28 210.992V211.344C1331.28 220.363 1323.95 227.692 1314.93 227.692C1305.91 227.692 1298.6 220.363 1298.6 211.356L1298.58 173.787C1298.58 164.781 1305.91 157.439 1314.93 157.439C1323.96 157.439 1331.31 164.769 1331.31 173.763C1331.31 179.427 1333.51 184.811 1337.5 188.907C1341.54 193.052 1346.87 195.337 1352.53 195.337V190.463C1343.66 190.463 1336.19 182.806 1336.18 173.751C1336.18 164.756 1343.51 157.439 1352.5 157.439C1361.5 157.439 1368.85 164.769 1368.85 173.787L1368.88 211.356C1368.88 220.363 1361.55 227.692 1352.53 227.692Z"
          fill="white"
        />
        <path
          d="M1448.79 173.774C1448.78 162.081 1439.26 152.564 1427.57 152.564L1389.99 152.589H1389.97V152.564C1378.26 152.564 1368.74 162.081 1368.74 173.774L1368.76 211.343C1368.76 223.048 1378.28 232.564 1389.99 232.564C1395.65 232.564 1400.99 230.304 1405.02 226.183C1406.53 224.628 1407.8 222.877 1408.78 221.006C1409.77 222.877 1411.02 224.628 1412.55 226.183C1416.58 230.304 1421.91 232.564 1427.58 232.564C1439.28 232.564 1448.8 223.048 1448.8 211.343L1448.83 173.774H1448.79ZM1427.58 227.691C1418.56 227.691 1411.23 220.228 1411.23 211.051C1411.23 201.875 1418.41 194.995 1427.59 194.995V190.121C1421.91 190.121 1416.59 192.273 1412.6 196.174C1411.11 197.645 1409.85 199.298 1408.88 201.085C1405.32 193.914 1398.11 188.87 1390 188.87V193.743C1398.72 193.743 1406.36 201.826 1406.36 211.051C1406.36 220.276 1399.02 227.691 1390 227.691C1380.98 227.691 1373.65 220.361 1373.65 211.343L1373.63 173.798H1373.66C1373.66 164.792 1380.99 157.475 1390 157.475L1427.58 157.451C1436.6 157.451 1443.93 164.78 1443.93 173.798H1443.96L1443.94 211.355C1443.94 220.374 1436.61 227.703 1427.59 227.703L1427.58 227.691Z"
          fill={themeColor}
        />
        <path
          d="M1373.64 211.342L1373.61 173.797H1373.65C1373.65 164.791 1380.98 157.474 1389.99 157.474L1427.57 157.45C1436.59 157.45 1443.92 164.779 1443.92 173.797H1443.95L1443.93 211.354C1443.93 220.373 1436.6 227.702 1427.58 227.702C1418.56 227.702 1411.23 220.239 1411.23 211.063C1411.23 201.886 1418.41 195.007 1427.59 195.007V190.133C1421.91 190.133 1416.59 192.284 1412.6 196.186C1411.11 197.656 1409.85 199.309 1408.88 201.096C1405.32 193.925 1398.11 188.881 1390 188.881V193.755C1398.72 193.755 1406.36 201.837 1406.36 211.063C1406.36 220.288 1399.02 227.702 1390 227.702C1380.98 227.702 1373.65 220.373 1373.65 211.354L1373.64 211.342Z"
          fill="white"
        />
        <path
          d="M1465.04 190.275C1475.41 190.275 1483.82 200.213 1483.82 210.588"
          stroke="THEME"
          strokeWidth="15"
          strokeMiterlimit="10"
        />
        <path
          d="M1502.6 232.564C1494.57 232.564 1487.41 227.656 1483.82 220.634C1480.23 227.656 1473.07 232.552 1465.04 232.552C1453.34 232.552 1443.83 223.04 1443.83 211.34V173.801C1443.83 162.101 1453.34 152.589 1465.04 152.589L1502.6 152.564C1514.29 152.564 1523.8 162.077 1523.8 173.776C1523.8 181.916 1519.18 189.011 1512.39 192.558C1519.18 196.118 1523.82 203.201 1523.82 211.34V211.668C1523.65 223.21 1514.19 232.552 1502.61 232.552L1502.6 232.564ZM1486.26 210.575C1486.26 219.857 1493.74 227.693 1502.6 227.693C1511.46 227.693 1518.93 220.367 1518.93 211.365V211.061C1518.77 202.18 1511.46 195 1502.47 195V190.129C1511.54 190.129 1518.93 182.791 1518.93 173.776C1518.93 164.762 1511.61 157.436 1502.6 157.436L1465.04 157.46C1456.03 157.46 1448.7 164.786 1448.7 173.801V211.34C1448.7 220.355 1456.03 227.681 1465.04 227.681C1474.06 227.681 1481.39 219.845 1481.39 210.575H1486.26Z"
          fill={themeColor}
        />
        <path
          d="M1518.93 211.376C1518.93 220.379 1511.61 227.704 1502.6 227.704C1493.6 227.704 1486.26 219.868 1486.26 210.587C1486.26 198.474 1476.34 187.844 1465.04 187.844V192.716C1473.75 192.716 1481.39 201.074 1481.39 210.587C1481.39 220.099 1473.9 227.692 1465.04 227.692C1456.18 227.692 1448.7 220.366 1448.7 211.352V173.812C1448.7 164.798 1456.03 157.472 1465.04 157.472L1502.6 157.448C1511.61 157.448 1518.93 164.773 1518.93 173.788C1518.93 182.802 1511.54 190.14 1502.47 190.14V195.012C1511.45 195.012 1518.77 202.192 1518.93 211.073V211.376Z"
          fill="white"
        />
        <path
          d="M1076.71 23.786C1076.71 12.0813 1067.19 2.56445 1055.48 2.56445C1047.35 2.56445 1040.28 7.17096 1036.73 13.9166C1033.17 7.18311 1026.08 2.56445 1017.92 2.56445H1017.91C1006.21 2.56445 996.696 12.0813 996.696 23.786L996.72 61.3551C996.72 73.0476 1006.23 82.5645 1017.92 82.5645C1026.08 82.5645 1033.17 77.9458 1036.71 71.1758C1040.26 77.9336 1047.35 82.5645 1055.51 82.5645C1067.22 82.5645 1076.73 73.0476 1076.73 61.3551L1076.71 23.786ZM1055.52 77.6905C1046.5 77.6905 1039.17 70.3615 1039.17 61.3429L1039.15 60.9175C1039.11 49.2493 1029.6 39.7689 1017.92 39.7689V44.6428C1026.94 44.6428 1034.27 51.9719 1034.27 60.9905V61.3429C1034.27 70.3615 1026.94 77.6905 1017.92 77.6905C1008.9 77.6905 1001.59 70.3615 1001.59 61.3551L1001.57 23.786C1001.57 14.7796 1008.9 7.43835 1017.92 7.43835C1026.95 7.43835 1034.31 14.7674 1034.31 23.7617C1034.31 29.4256 1036.51 34.81 1040.5 38.906C1044.53 43.0506 1049.87 45.3356 1055.52 45.3356V40.4618C1046.66 40.4618 1039.18 32.8045 1039.17 23.7495C1039.17 14.7553 1046.5 7.43835 1055.5 7.43835C1064.49 7.43835 1071.85 14.7674 1071.85 23.786L1071.87 61.3551C1071.87 70.3615 1064.54 77.6905 1055.52 77.6905Z"
          fill={themeColor}
        />
        <path
          d="M1055.52 77.6916C1046.5 77.6916 1039.17 70.3626 1039.17 61.344L1039.15 60.9186C1039.11 49.2504 1029.6 39.77 1017.92 39.77V44.6439C1026.94 44.6439 1034.27 51.973 1034.27 60.9916V61.344C1034.27 70.3626 1026.94 77.6916 1017.92 77.6916C1008.9 77.6916 1001.59 70.3626 1001.59 61.3562L1001.57 23.7871C1001.57 14.7807 1008.9 7.43945 1017.92 7.43945C1026.95 7.43945 1034.31 14.7685 1034.31 23.7628C1034.31 29.4267 1036.51 34.8111 1040.5 38.9071C1044.53 43.0517 1049.87 45.3367 1055.52 45.3367V40.4629C1046.66 40.4629 1039.18 32.8056 1039.17 23.7506C1039.17 14.7564 1046.5 7.43945 1055.5 7.43945C1064.49 7.43945 1071.85 14.7685 1071.85 23.7871L1071.87 61.3562C1071.87 70.3626 1064.54 77.6916 1055.52 77.6916Z"
          fill="#04030F"
        />
        <path
          d="M1130.47 2.58874L1092.93 2.56445C1081.61 2.56445 1072.33 11.5019 1071.76 22.6859H1071.73V61.3623C1071.73 73.0563 1081.24 82.5645 1092.93 82.5645L1130.47 82.5402C1142.17 82.5402 1151.68 73.032 1151.68 61.338V23.7909C1151.68 12.0969 1142.17 2.58874 1130.47 2.58874ZM1130.47 77.6586L1092.93 77.6828C1083.93 77.6828 1076.6 70.3604 1076.6 61.3501V37.2578C1080.52 41.9937 1086.44 45.0174 1093.05 45.0174C1104.67 45.0174 1114.13 35.4849 1114.13 23.7666H1109.26C1109.26 32.8012 1101.99 40.1479 1093.04 40.1479C1084.09 40.1479 1076.59 32.7891 1076.59 23.7545C1076.59 14.7199 1083.92 7.42177 1092.92 7.42177L1130.46 7.44606C1139.47 7.44606 1146.79 14.7685 1146.79 23.7788V61.3258C1146.79 70.3362 1139.47 77.6586 1130.46 77.6586H1130.47Z"
          fill={themeColor}
        />
        <path
          d="M1092.93 77.6961L1130.47 77.6719C1139.48 77.6719 1146.81 70.3494 1146.81 61.3391V23.7921C1146.81 14.7818 1139.48 7.45934 1130.47 7.45934L1092.93 7.43506C1083.93 7.43506 1076.6 14.7575 1076.6 23.7678C1076.6 32.7781 1083.98 40.1612 1093.05 40.1612C1102.12 40.1612 1109.28 32.8145 1109.28 23.7799H1114.15C1114.15 35.4982 1104.69 45.0307 1093.06 45.0307C1086.44 45.0307 1080.53 42.007 1076.62 37.2711V61.3634C1076.62 70.3737 1083.94 77.6961 1092.94 77.6961H1092.93Z"
          fill="#04030F"
        />
        <path
          d="M1205.51 82.5645H1167.92C1156.22 82.5645 1146.7 73.0476 1146.7 61.3429L1146.68 23.7738C1146.68 12.0813 1156.19 2.56445 1167.9 2.56445C1176.07 2.56445 1183.17 7.20742 1186.72 13.9774C1190.27 7.19526 1197.37 2.56445 1205.54 2.56445C1217.24 2.56445 1226.76 12.0813 1226.76 23.7738L1226.74 61.3429C1226.74 73.0354 1217.22 82.5645 1205.51 82.5645ZM1167.9 7.43836C1158.88 7.43836 1151.55 14.7674 1151.55 23.7738L1151.57 61.3429C1151.57 70.3615 1158.9 77.6905 1167.92 77.6905H1205.51C1214.53 77.6905 1221.86 70.3615 1221.86 61.3429L1221.89 23.7738C1221.89 14.7674 1214.56 7.43836 1205.54 7.43836C1196.52 7.43836 1189.19 14.7674 1189.19 23.786L1189.16 47.6328H1184.29V24.2843H1184.26L1184.24 23.786C1184.24 14.7674 1176.91 7.43836 1167.89 7.43836H1167.9Z"
          fill={themeColor}
        />
        <path
          d="M1167.9 7.43945C1158.88 7.43945 1151.55 14.7685 1151.55 23.7749L1151.57 61.344C1151.57 70.3626 1158.9 77.6916 1167.92 77.6916H1205.51C1214.53 77.6916 1221.86 70.3626 1221.86 61.344L1221.89 23.7749C1221.89 14.7685 1214.56 7.43945 1205.54 7.43945C1196.52 7.43945 1189.19 14.7685 1189.19 23.7871L1189.16 47.6339H1184.29V24.2854H1184.26L1184.24 23.7992C1184.24 14.7807 1176.91 7.4516 1167.89 7.4516L1167.9 7.43945Z"
          fill="#04030F"
        />
        <path
          d="M1301.78 23.786C1301.78 12.0813 1292.26 2.56445 1280.55 2.56445C1272.42 2.56445 1265.34 7.17096 1261.79 13.9166C1258.23 7.18311 1251.14 2.56445 1242.99 2.56445H1242.98C1231.28 2.56445 1221.76 12.0813 1221.76 23.786L1221.79 61.3551C1221.79 73.0476 1231.29 82.5645 1242.99 82.5645C1251.14 82.5645 1258.23 77.9458 1261.78 71.1758C1265.33 77.9336 1272.42 82.5645 1280.58 82.5645C1292.28 82.5645 1301.8 73.0476 1301.8 61.3551L1301.78 23.786ZM1280.59 77.6905C1271.57 77.6905 1264.24 70.3615 1264.24 61.3429L1264.21 60.9175C1264.18 49.2493 1254.67 39.7689 1242.99 39.7689V44.6428C1252.01 44.6428 1259.34 51.9719 1259.34 60.9905V61.3429C1259.34 70.3615 1252.01 77.6905 1242.99 77.6905C1233.97 77.6905 1226.66 70.3615 1226.66 61.3551L1226.64 23.786C1226.64 14.7796 1233.97 7.43835 1242.99 7.43835C1252.02 7.43835 1259.37 14.7674 1259.37 23.7617C1259.37 29.4256 1261.57 34.81 1265.56 38.906C1269.6 43.0506 1274.93 45.3356 1280.59 45.3356V40.4618C1271.73 40.4618 1264.25 32.8045 1264.24 23.7495C1264.24 14.7553 1271.57 7.43835 1280.56 7.43835C1289.56 7.43835 1296.91 14.7674 1296.91 23.786L1296.94 61.3551C1296.94 70.3615 1289.61 77.6905 1280.59 77.6905Z"
          fill={themeColor}
        />
        <path
          d="M1280.59 77.6916C1271.57 77.6916 1264.24 70.3626 1264.24 61.344L1264.21 60.9186C1264.18 49.2504 1254.67 39.77 1242.99 39.77V44.6439C1252.01 44.6439 1259.34 51.973 1259.34 60.9916V61.344C1259.34 70.3626 1252.01 77.6916 1242.99 77.6916C1233.97 77.6916 1226.66 70.3626 1226.66 61.3562L1226.64 23.7871C1226.64 14.7807 1233.97 7.43945 1242.99 7.43945C1252.02 7.43945 1259.37 14.7685 1259.37 23.7628C1259.37 29.4267 1261.57 34.8111 1265.56 38.9071C1269.6 43.0517 1274.93 45.3367 1280.59 45.3367V40.4629C1271.73 40.4629 1264.25 32.8056 1264.24 23.7506C1264.24 14.7564 1271.57 7.43945 1280.56 7.43945C1289.56 7.43945 1296.91 14.7685 1296.91 23.7871L1296.94 61.3562C1296.94 70.3626 1289.61 77.6916 1280.59 77.6916Z"
          fill="#04030F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1301.68 61.3594H1296.8C1296.8 53.2157 1301.43 46.1189 1308.18 42.5766C1301.43 39.0343 1296.8 31.9497 1296.8 23.7939C1296.8 12.0958 1306.31 2.5888 1318.01 2.5888L1355.55 2.56445C1367.25 2.56445 1376.75 12.0715 1376.75 23.7696H1371.87C1371.87 14.7738 1364.55 7.44576 1355.55 7.44576L1318.01 7.47011C1309.01 7.47011 1301.68 14.7982 1301.68 23.7939C1301.68 32.7897 1309 40.1177 1318.01 40.1177V40.1542C1329.71 40.1542 1339.22 49.6734 1339.22 61.3594H1334.34C1334.34 52.3636 1327.01 45.0356 1318.01 45.0356C1309.01 45.0356 1301.68 52.3514 1301.68 61.3594ZM1371.87 23.7701H1376.75C1376.75 31.9137 1372.13 39.0105 1365.37 42.5528C1372.13 46.0951 1376.75 53.1797 1376.75 61.3355C1376.75 73.0337 1367.23 82.5407 1355.55 82.5407L1318.01 82.565C1306.31 82.565 1296.8 73.058 1296.8 61.3599H1301.68C1301.68 70.3556 1309 77.6837 1318.01 77.6837L1355.55 77.6593C1364.54 77.6593 1371.87 70.3313 1371.87 61.3355C1371.87 52.3398 1364.55 45.0117 1355.55 45.0117V44.9752C1343.84 44.9752 1334.34 35.4682 1334.34 23.7701H1339.22C1339.22 32.7658 1346.55 40.0939 1355.55 40.0939C1364.54 40.0939 1371.87 32.778 1371.87 23.7701Z"
          fill={themeColor}
        />
        <path
          d="M1355.68 77.6964L1318.14 77.7208C1309.13 77.7208 1301.82 70.3927 1301.82 61.3848C1301.82 52.3769 1309.15 45.0488 1318.14 45.0488C1327.14 45.0488 1334.47 52.3769 1334.47 61.3848H1339.35C1339.35 49.6866 1329.84 40.1675 1318.14 40.1675C1309.13 40.1675 1301.82 32.8273 1301.82 23.8193C1301.82 14.8114 1309.15 7.49554 1318.14 7.49554L1355.68 7.47119C1364.69 7.47119 1372.01 14.7992 1372.01 23.8072C1372.01 32.8151 1364.68 40.131 1355.68 40.131C1346.68 40.131 1339.35 32.8029 1339.35 23.8072H1334.47C1334.47 35.5053 1343.98 45.0123 1355.68 45.0123V45.0488C1364.69 45.0488 1372.01 52.3769 1372.01 61.3726C1372.01 70.3683 1364.68 77.6964 1355.68 77.6964Z"
          fill="#04030F"
        />
        <circle cx="1391.75" cy="42.5645" r="6" fill="white" />
        <circle cx="1391.75" cy="42.5645" r="6" stroke="#04030F" />
        <path
          d="M1475.35 42.5645C1482.09 39.0054 1486.71 31.9237 1486.71 23.7731C1486.7 12.0755 1477.18 2.56445 1465.49 2.56445H1427.95C1416.27 2.56445 1406.75 12.0877 1406.75 23.7852L1406.78 61.3315V61.3558H1406.75C1406.75 73.0534 1416.27 82.5645 1427.95 82.5645L1465.51 82.5402C1477.21 82.5402 1486.72 73.0291 1486.72 61.3315C1486.72 53.1931 1482.1 46.1235 1475.36 42.5645H1475.35ZM1465.49 77.6692L1427.97 77.6935V77.6571C1418.96 77.6571 1411.65 70.3324 1411.65 61.3315L1411.63 23.7852C1411.63 14.7722 1418.95 7.43539 1427.95 7.43539H1465.49C1474.51 7.43539 1481.84 14.7722 1481.84 23.7731C1481.84 32.774 1474.51 40.1351 1465.51 40.1351C1453.81 40.1351 1444.28 49.6461 1444.28 61.3437H1449.15C1449.15 52.3306 1456.49 44.9939 1465.51 44.9939C1474.52 44.9939 1481.84 52.3185 1481.84 61.3194C1481.84 70.3203 1474.51 77.6571 1465.49 77.6571V77.6692Z"
          fill={themeColor}
        />
        <path
          d="M1465.48 77.6703L1427.95 77.6946V77.6582C1418.95 77.6582 1411.64 70.3336 1411.64 61.3327L1411.61 23.7864C1411.61 14.7733 1418.94 7.43652 1427.94 7.43652H1465.49C1474.51 7.43652 1481.84 14.7733 1481.84 23.7742C1481.84 32.7751 1474.51 40.1362 1465.51 40.1362C1453.81 40.1362 1444.28 49.6473 1444.28 61.3448H1449.15C1449.15 52.3318 1456.49 44.995 1465.51 44.995C1474.52 44.995 1481.84 52.3196 1481.84 61.3205C1481.84 70.3214 1474.51 77.6582 1465.49 77.6582L1465.48 77.6703Z"
          fill="white"
        />
        <circle cx="981.696" cy="42.5645" r="6" fill="white" />
        <circle cx="981.696" cy="42.5645" r="6" stroke="#04030F" />
        <path
          d="M812.68 23.7865C812.68 12.0818 803.162 2.56494 791.455 2.56494C783.322 2.56494 776.247 7.17145 772.697 13.9171C769.135 7.1836 762.048 2.56494 753.891 2.56494H753.879C742.185 2.56494 732.666 12.0818 732.666 23.7865L732.69 61.3556C732.69 73.0481 742.197 82.5649 753.891 82.5649C762.048 82.5649 769.135 77.9463 772.685 71.1763C776.235 77.9341 783.322 82.5649 791.479 82.5649C803.186 82.5649 812.704 73.0481 812.704 61.3556L812.68 23.7865ZM791.491 77.691C782.471 77.691 775.141 70.362 775.141 61.3434L775.116 60.918C775.08 49.2498 765.574 39.7694 753.891 39.7694V44.6433C762.911 44.6433 770.242 51.9724 770.242 60.991V61.3434C770.242 70.362 762.911 77.691 753.891 77.691C744.871 77.691 737.565 70.3619 737.565 61.3556L737.541 23.7865C737.541 14.7801 744.871 7.43884 753.891 7.43884C762.924 7.43884 770.278 14.7679 770.278 23.7621C770.278 29.4261 772.479 34.8105 776.466 38.9065C780.502 43.0511 785.839 45.3361 791.491 45.3361V40.4622C782.629 40.4622 775.153 32.805 775.141 23.75C775.141 14.7558 782.471 7.43884 791.467 7.43884C800.463 7.43884 807.817 14.7679 807.817 23.7865L807.842 61.3556C807.842 70.3619 800.511 77.691 791.491 77.691Z"
          fill={themeColor}
        />
        <path
          d="M791.491 77.6921C782.471 77.6921 775.141 70.3631 775.141 61.3445L775.116 60.9191C775.08 49.2509 765.573 39.7705 753.891 39.7705V44.6444C762.911 44.6444 770.241 51.9735 770.241 60.9921V61.3445C770.241 70.3631 762.911 77.6921 753.891 77.6921C744.871 77.6921 737.565 70.363 737.565 61.3567L737.541 23.7875C737.541 14.7812 744.871 7.43994 753.891 7.43994C762.923 7.43994 770.278 14.769 770.278 23.7632C770.278 29.4272 772.478 34.8116 776.466 38.9076C780.502 43.0522 785.838 45.3372 791.491 45.3372V40.4633C782.629 40.4633 775.153 32.8061 775.141 23.7511C775.141 14.7569 782.471 7.43994 791.467 7.43994C800.463 7.43994 807.817 14.769 807.817 23.7875L807.842 61.3567C807.842 70.363 800.511 77.6921 791.491 77.6921Z"
          fill="white"
        />
        <path
          d="M889.719 23.7865C889.719 12.0818 880.2 2.56494 868.493 2.56494C860.361 2.56494 853.286 7.17145 849.736 13.9171C846.174 7.1836 839.087 2.56494 830.93 2.56494H830.918C819.223 2.56494 809.705 12.0818 809.705 23.7865L809.729 61.3556C809.729 73.0481 819.235 82.5649 830.93 82.5649C839.087 82.5649 846.174 77.9463 849.724 71.1763C853.273 77.9341 860.361 82.5649 868.518 82.5649C880.224 82.5649 889.743 73.0481 889.743 61.3556L889.719 23.7865ZM868.53 77.691C859.51 77.691 852.179 70.362 852.179 61.3434L852.155 60.918C852.119 49.2498 842.612 39.7694 830.93 39.7694V44.6433C839.95 44.6433 847.28 51.9724 847.28 60.991V61.3434C847.28 70.362 839.95 77.691 830.93 77.691C821.91 77.691 814.604 70.3619 814.604 61.3556L814.579 23.7865C814.579 14.7801 821.91 7.43884 830.93 7.43884C839.962 7.43884 847.317 14.7679 847.317 23.7621C847.317 29.4261 849.517 34.8105 853.504 38.9065C857.54 43.0511 862.877 45.3361 868.53 45.3361V40.4622C859.668 40.4622 852.192 32.805 852.179 23.75C852.179 14.7558 859.51 7.43884 868.506 7.43884C877.501 7.43884 884.856 14.7679 884.856 23.7865L884.88 61.3556C884.88 70.3619 877.55 77.691 868.53 77.691Z"
          fill={themeColor}
        />
        <path
          d="M868.53 77.6921C859.509 77.6921 852.179 70.3631 852.179 61.3445L852.155 60.9191C852.118 49.2509 842.612 39.7705 830.93 39.7705V44.6444C839.95 44.6444 847.28 51.9735 847.28 60.9921V61.3445C847.28 70.3631 839.95 77.6921 830.93 77.6921C821.909 77.6921 814.603 70.363 814.603 61.3567L814.579 23.7875C814.579 14.7812 821.909 7.43994 830.93 7.43994C839.962 7.43994 847.317 14.769 847.317 23.7632C847.317 29.4272 849.517 34.8116 853.504 38.9076C857.54 43.0522 862.877 45.3372 868.53 45.3372V40.4633C859.668 40.4633 852.191 32.8061 852.179 23.7511C852.179 14.7569 859.509 7.43994 868.505 7.43994C877.501 7.43994 884.856 14.769 884.856 23.7875L884.88 61.3567C884.88 70.363 877.55 77.6921 868.53 77.6921Z"
          fill="white"
        />
        <path
          d="M886.743 61.3594H891.625C891.625 52.3514 898.954 45.0356 907.952 45.0356C916.949 45.0356 924.278 52.3636 924.278 61.3594H929.16C929.16 49.6734 919.652 40.1542 907.952 40.1542V40.1177C898.942 40.1177 891.625 32.7897 891.625 23.7939C891.625 14.7982 898.954 7.47011 907.952 7.47011L945.487 7.44576C954.497 7.44576 961.814 14.7738 961.814 23.7696H966.696C966.696 12.0715 957.187 2.56445 945.487 2.56445L907.952 2.5888C896.251 2.5888 886.743 12.0958 886.743 23.7939C886.743 31.9497 891.369 39.0343 898.126 42.5766C891.369 46.1189 886.743 53.2157 886.743 61.3594Z"
          fill={themeColor}
        />
        <path
          d="M966.696 23.77H961.814C961.814 32.7779 954.484 40.0938 945.487 40.0938C936.49 40.0938 929.16 32.7658 929.16 23.77H924.278C924.278 35.4681 933.787 44.9751 945.487 44.9751V45.0117C954.497 45.0117 961.814 52.3397 961.814 61.3355C961.814 70.3312 954.484 77.6592 945.487 77.6592L907.952 77.6836C898.942 77.6836 891.625 70.3556 891.625 61.3598H886.743C886.743 73.0579 896.251 82.5649 907.952 82.5649L945.487 82.5406C957.175 82.5406 966.696 73.0336 966.696 61.3355C966.696 53.1796 962.069 46.0951 955.312 42.5527C962.069 39.0104 966.696 31.9137 966.696 23.77Z"
          fill={themeColor}
        />
        <path
          d="M945.621 77.6964L908.085 77.7208C899.076 77.7208 891.759 70.3927 891.759 61.3848C891.759 52.3769 899.088 45.0488 908.085 45.0488C917.083 45.0488 924.412 52.3769 924.412 61.3848H929.294C929.294 49.6866 919.786 40.1675 908.085 40.1675C899.076 40.1675 891.759 32.8273 891.759 23.8193C891.759 14.8114 899.088 7.49554 908.085 7.49554L945.621 7.47119C954.63 7.47119 961.948 14.7992 961.948 23.8072C961.948 32.8151 954.618 40.131 945.621 40.131C936.624 40.131 929.294 32.8029 929.294 23.8072H924.412C924.412 35.5053 933.921 45.0123 945.621 45.0123V45.0488C954.63 45.0488 961.948 52.3769 961.948 61.3726C961.948 70.3683 954.618 77.6964 945.621 77.6964Z"
          fill="white"
        />
        <circle cx="717.666" cy="42.5645" r="6" fill="white" />
        <circle cx="717.666" cy="42.5645" r="6" stroke="#04030F" />
        <path
          d="M139.906 23.7749C139.906 14.7685 147.237 7.43945 156.257 7.43945C165.277 7.43945 172.607 14.7685 172.607 23.7871L172.644 61.3562H177.518C177.518 52.3377 184.849 45.0086 193.869 45.0086C202.889 45.0086 210.219 52.3377 210.219 61.3562C210.219 70.3747 202.889 77.7038 193.869 77.7038H156.281C147.261 77.7038 139.931 70.3747 139.931 61.3562L139.906 23.7871V23.7749Z"
          fill="white"
        />
        <path
          d="M215.094 61.3429C215.094 49.6504 205.576 40.1336 193.869 40.1336C187.293 40.1336 181.397 43.1479 177.507 47.8516L177.482 23.786C177.482 12.0813 167.964 2.56445 156.257 2.56445C144.55 2.56445 135.032 12.0813 135.032 23.7738L135.056 61.3429C135.056 73.0476 144.575 82.5645 156.281 82.5645H193.869C205.564 82.5645 215.082 73.0476 215.094 61.3551V61.3429ZM193.869 77.6905H156.281C147.261 77.6905 139.931 70.3615 139.931 61.3429L139.907 23.7738C139.907 14.7674 147.237 7.43835 156.257 7.43835C165.277 7.43835 172.607 14.7674 172.607 23.786L172.644 61.3429H177.519C177.519 52.3244 184.849 44.9953 193.869 44.9953C202.889 44.9953 210.22 52.3244 210.22 61.3429C210.22 70.3615 202.889 77.6905 193.869 77.6905Z"
          fill={themeColor}
        />
        <path
          d="M231.344 82.5645C219.637 82.5645 210.119 73.0476 210.119 61.3429L210.094 23.7738C210.094 12.0813 219.613 2.56445 231.32 2.56445C243.026 2.56445 252.545 12.0813 252.545 23.786V61.3673C252.545 73.0476 243.038 82.5523 231.356 82.5523L231.344 82.5645ZM231.32 7.43835C222.299 7.43835 214.969 14.7674 214.969 23.7738L214.993 61.3429C214.993 70.3615 222.324 77.6906 231.344 77.6906C240.364 77.6906 247.67 70.3736 247.67 61.3673V23.786C247.67 14.7674 240.34 7.43835 231.32 7.43835Z"
          fill={themeColor}
        />
        <path
          d="M231.307 7.43945C222.287 7.43945 214.957 14.7685 214.957 23.7749L214.981 61.344C214.981 70.3626 222.311 77.6917 231.331 77.6917C240.351 77.6917 247.658 70.3747 247.658 61.3684V23.7871C247.658 14.7685 240.327 7.43945 231.307 7.43945Z"
          fill="white"
        />
        <path
          d="M252.419 23.7749C252.419 14.7685 259.75 7.43945 268.77 7.43945C277.79 7.43945 285.12 14.7685 285.12 23.7871L285.157 61.3562H290.032C290.032 52.3377 297.362 45.0086 306.382 45.0086C315.402 45.0086 322.733 52.3377 322.733 61.3562C322.733 70.3747 315.402 77.7038 306.382 77.7038H268.794C259.774 77.7038 252.444 70.3747 252.444 61.3562L252.419 23.7871V23.7749Z"
          fill="white"
        />
        <path
          d="M327.608 61.3429C327.608 49.6504 318.089 40.1336 306.382 40.1336C299.806 40.1336 293.91 43.1479 290.02 47.8516L289.995 23.786C289.995 12.0813 280.477 2.56445 268.77 2.56445C257.063 2.56445 247.545 12.0813 247.545 23.7738L247.569 61.3429C247.569 73.0476 257.088 82.5645 268.794 82.5645H306.382C318.077 82.5645 327.595 73.0476 327.608 61.3551V61.3429ZM306.382 77.6905H268.794C259.774 77.6905 252.444 70.3615 252.444 61.3429L252.42 23.7738C252.42 14.7674 259.75 7.43835 268.77 7.43835C277.79 7.43835 285.121 14.7674 285.121 23.786L285.157 61.3429H290.032C290.032 52.3244 297.362 44.9953 306.382 44.9953C315.402 44.9953 322.733 52.3244 322.733 61.3429C322.733 70.3615 315.402 77.6905 306.382 77.6905Z"
          fill={themeColor}
        />
        <path
          d="M402.621 23.786C402.621 12.0813 393.103 2.56445 381.396 2.56445C373.264 2.56445 366.188 7.17096 362.639 13.9166C359.077 7.18311 351.99 2.56445 343.833 2.56445H343.82C332.126 2.56445 322.607 12.0813 322.607 23.786L322.632 61.3551C322.632 73.0476 332.138 82.5645 343.833 82.5645C351.99 82.5645 359.077 77.9458 362.627 71.1758C366.176 77.9336 373.264 82.5645 381.421 82.5645C393.127 82.5645 402.646 73.0476 402.646 61.3551L402.621 23.786ZM381.433 77.6905C372.413 77.6905 365.082 70.3615 365.082 61.3429L365.058 60.9175C365.021 49.2493 355.515 39.7689 343.833 39.7689V44.6428C352.853 44.6428 360.183 51.9719 360.183 60.9905V61.3429C360.183 70.3615 352.853 77.6905 343.833 77.6905C334.813 77.6905 327.506 70.3615 327.506 61.3551L327.482 23.786C327.482 14.7796 334.813 7.43835 343.833 7.43835C352.865 7.43835 360.22 14.7674 360.22 23.7617C360.22 29.4256 362.42 34.81 366.407 38.906C370.443 43.0506 375.78 45.3356 381.433 45.3356V40.4618C372.571 40.4618 365.094 32.8045 365.082 23.7495C365.082 14.7553 372.413 7.43835 381.408 7.43835C390.404 7.43835 397.759 14.7674 397.759 23.786L397.783 61.3551C397.783 70.3615 390.453 77.6905 381.433 77.6905Z"
          fill={themeColor}
        />
        <path
          d="M381.432 77.6916C372.412 77.6916 365.082 70.3626 365.082 61.344L365.058 60.9186C365.021 49.2504 355.515 39.77 343.832 39.77V44.6439C352.853 44.6439 360.183 51.973 360.183 60.9916V61.344C360.183 70.3626 352.853 77.6916 343.832 77.6916C334.812 77.6916 327.506 70.3626 327.506 61.3562L327.482 23.7871C327.482 14.7807 334.812 7.43945 343.832 7.43945C352.865 7.43945 360.219 14.7685 360.219 23.7628C360.219 29.4267 362.42 34.8111 366.407 38.9071C370.443 43.0517 375.78 45.3367 381.432 45.3367V40.4629C372.57 40.4629 365.094 32.8056 365.082 23.7506C365.082 14.7564 372.412 7.43945 381.408 7.43945C390.404 7.43945 397.759 14.7685 397.759 23.7871L397.783 61.3562C397.783 70.3626 390.453 77.6916 381.432 77.6916Z"
          fill="white"
        />
        <path
          d="M456.381 2.58874L418.84 2.56445C407.52 2.56445 398.241 11.5019 397.67 22.6859H397.646V61.3623C397.646 73.0563 407.156 82.5645 418.84 82.5645L456.381 82.5402C468.077 82.5402 477.587 73.032 477.587 61.338V23.7909C477.587 12.0969 468.077 2.58874 456.381 2.58874ZM456.381 77.6586L418.84 77.6828C409.84 77.6828 402.516 70.3604 402.516 61.3501V37.2578C406.427 41.9937 412.354 45.0174 418.961 45.0174C430.584 45.0174 440.046 35.4849 440.046 23.7666H435.175C435.175 32.8012 427.9 40.1479 418.949 40.1479C409.998 40.1479 402.504 32.7891 402.504 23.7545C402.504 14.7199 409.828 7.42177 418.828 7.42177L456.369 7.44606C465.381 7.44606 472.705 14.7685 472.705 23.7788V61.3258C472.705 70.3362 465.381 77.6586 456.369 77.6586H456.381Z"
          fill={themeColor}
        />
        <path
          d="M418.84 77.6961L456.381 77.6719C465.393 77.6719 472.717 70.3494 472.717 61.3391V23.7921C472.717 14.7818 465.393 7.45934 456.381 7.45934L418.84 7.43506C409.84 7.43506 402.516 14.7575 402.516 23.7678C402.516 32.7781 409.888 40.1612 418.961 40.1612C428.034 40.1612 435.187 32.8145 435.187 23.7799H440.058C440.058 35.4982 430.596 45.0307 418.973 45.0307C412.354 45.0307 406.439 42.007 402.528 37.2711V61.3634C402.528 70.3737 409.852 77.6961 418.852 77.6961H418.84Z"
          fill="white"
        />
        <path
          d="M531.424 82.5645H493.836C482.13 82.5645 472.611 73.0476 472.611 61.3429L472.587 23.7738C472.587 12.0813 482.105 2.56445 493.812 2.56445C501.981 2.56445 509.081 7.20742 512.63 13.9774C516.18 7.19526 523.279 2.56445 531.449 2.56445C543.155 2.56445 552.674 12.0813 552.674 23.7738L552.65 61.3429C552.65 73.0354 543.131 82.5645 531.424 82.5645ZM493.812 7.43836C484.792 7.43836 477.462 14.7674 477.462 23.7738L477.486 61.3429C477.486 70.3615 484.816 77.6905 493.836 77.6905H531.424C540.444 77.6905 547.775 70.3615 547.775 61.3429L547.799 23.7738C547.799 14.7674 540.469 7.43836 531.449 7.43836C522.429 7.43836 515.098 14.7674 515.098 23.786L515.074 47.6328H510.199V24.2843H510.175L510.15 23.786C510.15 14.7674 502.82 7.43836 493.8 7.43836H493.812Z"
          fill={themeColor}
        />
        <path
          d="M493.812 7.43945C484.792 7.43945 477.461 14.7685 477.461 23.7749L477.486 61.344C477.486 70.3626 484.816 77.6916 493.836 77.6916H531.424C540.444 77.6916 547.775 70.3626 547.775 61.344L547.799 23.7749C547.799 14.7685 540.468 7.43945 531.448 7.43945C522.428 7.43945 515.098 14.7685 515.098 23.7871L515.074 47.6339H510.199V24.2854H510.175L510.15 23.7992C510.15 14.7807 502.82 7.4516 493.8 7.4516L493.812 7.43945Z"
          fill="white"
        />
        <path
          d="M627.688 23.786C627.688 12.0813 618.169 2.56445 606.463 2.56445C598.33 2.56445 591.255 7.17096 587.705 13.9166C584.143 7.18311 577.056 2.56445 568.899 2.56445H568.887C557.192 2.56445 547.674 12.0813 547.674 23.786L547.698 61.3551C547.698 73.0476 557.205 82.5645 568.899 82.5645C577.056 82.5645 584.143 77.9458 587.693 71.1758C591.243 77.9336 598.33 82.5645 606.487 82.5645C618.194 82.5645 627.712 73.0476 627.712 61.3551L627.688 23.786ZM606.499 77.6905C597.479 77.6905 590.149 70.3615 590.149 61.3429L590.124 60.9175C590.088 49.2493 580.581 39.7689 568.899 39.7689V44.6428C577.919 44.6428 585.25 51.9719 585.25 60.9905V61.3429C585.25 70.3615 577.919 77.6905 568.899 77.6905C559.879 77.6905 552.573 70.3615 552.573 61.3551L552.549 23.786C552.549 14.7796 559.879 7.43835 568.899 7.43835C577.931 7.43835 585.286 14.7674 585.286 23.7617C585.286 29.4256 587.486 34.81 591.474 38.906C595.51 43.0506 600.846 45.3356 606.499 45.3356V40.4618C597.637 40.4618 590.161 32.8045 590.149 23.7495C590.149 14.7553 597.479 7.43835 606.475 7.43835C615.471 7.43835 622.825 14.7674 622.825 23.786L622.85 61.3551C622.85 70.3615 615.519 77.6905 606.499 77.6905Z"
          fill={themeColor}
        />
        <path
          d="M606.499 77.6916C597.479 77.6916 590.148 70.3626 590.148 61.344L590.124 60.9186C590.088 49.2504 580.581 39.77 568.899 39.77V44.6439C577.919 44.6439 585.249 51.973 585.249 60.9916V61.344C585.249 70.3626 577.919 77.6916 568.899 77.6916C559.879 77.6916 552.573 70.3626 552.573 61.3562L552.548 23.7871C552.548 14.7807 559.879 7.43945 568.899 7.43945C577.931 7.43945 585.286 14.7685 585.286 23.7628C585.286 29.4267 587.486 34.8111 591.473 38.9071C595.509 43.0517 600.846 45.3367 606.499 45.3367V40.4629C597.637 40.4629 590.161 32.8056 590.148 23.7506C590.148 14.7564 597.479 7.43945 606.475 7.43945C615.47 7.43945 622.825 14.7685 622.825 23.7871L622.849 61.3562C622.849 70.3626 615.519 77.6916 606.499 77.6916Z"
          fill="white"
        />
        <path
          d="M622.712 61.3594H627.595C627.595 52.3514 634.924 45.0356 643.921 45.0356C652.919 45.0356 660.248 52.3636 660.248 61.3594H665.13C665.13 49.6734 655.621 40.1542 643.921 40.1542V40.1177C634.912 40.1177 627.595 32.7897 627.595 23.7939C627.595 14.7982 634.924 7.47011 643.921 7.47011L681.457 7.44576C690.466 7.44576 697.783 14.7738 697.783 23.7696H702.666C702.666 12.0715 693.157 2.56445 681.457 2.56445L643.921 2.5888C632.221 2.5888 622.712 12.0958 622.712 23.7939C622.712 31.9497 627.339 39.0343 634.096 42.5766C627.339 46.1189 622.712 53.2157 622.712 61.3594Z"
          fill={themeColor}
        />
        <path
          d="M702.666 23.77H697.783C697.783 32.7779 690.454 40.0938 681.457 40.0938C672.459 40.0938 665.13 32.7658 665.13 23.77H660.248C660.248 35.4681 669.757 44.9751 681.457 44.9751V45.0117C690.466 45.0117 697.783 52.3397 697.783 61.3355C697.783 70.3312 690.454 77.6592 681.457 77.6592L643.921 77.6836C634.912 77.6836 627.595 70.3556 627.595 61.3598H622.712C622.712 73.0579 632.221 82.5649 643.921 82.5649L681.457 82.5406C693.145 82.5406 702.666 73.0336 702.666 61.3355C702.666 53.1796 698.039 46.0951 691.282 42.5527C698.039 39.0104 702.666 31.9137 702.666 23.77Z"
          fill={themeColor}
        />
        <path
          d="M681.591 77.6964L644.055 77.7208C635.046 77.7208 627.729 70.3927 627.729 61.3848C627.729 52.3769 635.058 45.0488 644.055 45.0488C653.053 45.0488 660.382 52.3769 660.382 61.3848H665.264C665.264 49.6866 655.755 40.1675 644.055 40.1675C635.046 40.1675 627.729 32.8273 627.729 23.8193C627.729 14.8114 635.058 7.49554 644.055 7.49554L681.591 7.47119C690.6 7.47119 697.917 14.7992 697.917 23.8072C697.917 32.8151 690.588 40.131 681.591 40.131C672.593 40.131 665.264 32.8029 665.264 23.8072H660.382C660.382 35.5053 669.891 45.0123 681.591 45.0123V45.0488C690.6 45.0488 697.917 52.3769 697.917 61.3726C697.917 70.3683 690.588 77.6964 681.591 77.6964Z"
          fill="white"
        />
        <circle cx="120.032" cy="42.5645" r="6" fill="white" />
        <circle cx="120.032" cy="42.5645" r="6" stroke="#04030F" />
        <path
          d="M8.86302 82.5645C0.831242 82.5645 -6.32567 77.6563 -9.92235 70.6343C-13.5069 77.6563 -20.6638 82.5523 -28.6956 82.5523C-40.3969 82.5523 -49.9111 73.0398 -49.9111 61.3404V23.8006C-49.9111 12.1013 -40.3969 2.58876 -28.6956 2.58876L8.86302 2.56445C20.5522 2.56445 30.0664 12.077 30.0664 23.7763C30.0664 31.916 25.4369 39.0109 18.6567 42.5584C25.4369 46.118 30.0786 53.2007 30.0786 61.3404V61.6685C29.9085 73.2098 20.455 82.5523 8.87516 82.5523L8.86302 82.5645ZM-7.48001 60.5751C-7.48001 69.8568 0.00497437 77.6928 8.86302 77.6928C17.7211 77.6928 25.1939 70.367 25.1939 61.3647V61.061C25.0359 52.1802 17.7211 45.0003 8.72936 45.0003V40.1286C17.8061 40.1286 25.1939 32.7907 25.1939 23.7763C25.1939 14.7619 17.8669 7.43614 8.86302 7.43614L-28.6956 7.46044C-37.7116 7.46044 -45.0386 14.7862 -45.0386 23.8006V61.3404C-45.0386 70.3549 -37.7116 77.6806 -28.6956 77.6806C-19.6796 77.6806 -12.3525 69.8446 -12.3525 60.5751H-7.48001Z"
          fill={themeColor}
        />
        <path
          d="M25.1939 61.3764C25.1939 70.3786 17.8669 77.7044 8.86305 77.7044C-0.140808 77.7044 -7.47998 69.8684 -7.47998 60.5867C-7.47998 48.4743 -17.3952 37.8441 -28.6955 37.8441V42.7158C-19.9833 42.7158 -12.3525 51.0742 -12.3525 60.5867C-12.3525 70.0992 -19.8375 77.6922 -28.6955 77.6922C-37.5536 77.6922 -45.0386 70.3665 -45.0386 61.3521V23.8122C-45.0386 14.7978 -37.7115 7.47206 -28.6955 7.47206L8.86305 7.44775C17.8669 7.44775 25.1939 14.7735 25.1939 23.7879C25.1939 32.8023 17.8061 40.1402 8.72939 40.1402V45.0119C17.7089 45.0119 25.036 52.1919 25.1939 61.0726V61.3764Z"
          fill="white"
        />
        <path
          d="M25.0786 61.3594H29.9608C29.9608 52.3514 37.2902 45.0356 46.2875 45.0356C55.2848 45.0356 62.6141 52.3636 62.6141 61.3594H67.4963C67.4963 49.6734 57.9876 40.1542 46.2875 40.1542V40.1177C37.278 40.1177 29.9608 32.7897 29.9608 23.7939C29.9608 14.7982 37.2902 7.47011 46.2875 7.47011L83.823 7.44576C92.8325 7.44576 100.15 14.7738 100.15 23.7696H105.032C105.032 12.0715 95.5231 2.56445 83.823 2.56445L46.2875 2.5888C34.5873 2.5888 25.0786 12.0958 25.0786 23.7939C25.0786 31.9497 29.7051 39.0343 36.4622 42.5766C29.7051 46.1189 25.0786 53.2157 25.0786 61.3594Z"
          fill={themeColor}
        />
        <path
          d="M105.032 23.77H100.15C100.15 32.7779 92.8203 40.0938 83.823 40.0938C74.8256 40.0938 67.4963 32.7658 67.4963 23.77H62.6141C62.6141 35.4681 72.1228 44.9751 83.823 44.9751V45.0117C92.8325 45.0117 100.15 52.3397 100.15 61.3355C100.15 70.3312 92.8203 77.6592 83.823 77.6592L46.2875 77.6836C37.278 77.6836 29.9608 70.3556 29.9608 61.3598H25.0786C25.0786 73.0579 34.5873 82.5649 46.2875 82.5649L83.823 82.5406C95.511 82.5406 105.032 73.0336 105.032 61.3355C105.032 53.1796 100.405 46.0951 93.6482 42.5527C100.405 39.0104 105.032 31.9137 105.032 23.77Z"
          fill={themeColor}
        />
        <path
          d="M83.9569 77.6964L46.4214 77.7208C37.4119 77.7208 30.0947 70.3927 30.0947 61.3848C30.0947 52.3769 37.4241 45.0488 46.4214 45.0488C55.4187 45.0488 62.7481 52.3769 62.7481 61.3848H67.6302C67.6302 49.6866 58.1216 40.1675 46.4214 40.1675C37.4119 40.1675 30.0947 32.8273 30.0947 23.8193C30.0947 14.8114 37.4241 7.49554 46.4214 7.49554L83.9569 7.47119C92.9664 7.47119 100.284 14.7992 100.284 23.8072C100.284 32.8151 92.9542 40.131 83.9569 40.131C74.9596 40.131 67.6302 32.8029 67.6302 23.8072H62.7481C62.7481 35.5053 72.2567 45.0123 83.9569 45.0123V45.0488C92.9664 45.0488 100.284 52.3769 100.284 61.3726C100.284 70.3683 92.9542 77.6964 83.9569 77.6964Z"
          fill="white"
        />
        <path
          d="M1076.71 398.786C1076.71 387.081 1067.19 377.564 1055.48 377.564C1047.35 377.564 1040.28 382.171 1036.73 388.917C1033.17 382.183 1026.08 377.564 1017.92 377.564H1017.91C1006.21 377.564 996.696 387.081 996.696 398.786L996.72 436.355C996.72 448.048 1006.23 457.564 1017.92 457.564C1026.08 457.564 1033.17 452.946 1036.71 446.176C1040.26 452.934 1047.35 457.564 1055.51 457.564C1067.22 457.564 1076.73 448.048 1076.73 436.355L1076.71 398.786ZM1055.52 452.691C1046.5 452.691 1039.17 445.361 1039.17 436.343L1039.15 435.918C1039.11 424.249 1029.6 414.769 1017.92 414.769V419.643C1026.94 419.643 1034.27 426.972 1034.27 435.99V436.343C1034.27 445.361 1026.94 452.691 1017.92 452.691C1008.9 452.691 1001.59 445.361 1001.59 436.355L1001.57 398.786C1001.57 389.78 1008.9 382.438 1017.92 382.438C1026.95 382.438 1034.31 389.767 1034.31 398.762C1034.31 404.426 1036.51 409.81 1040.5 413.906C1044.53 418.051 1049.87 420.336 1055.52 420.336V415.462C1046.66 415.462 1039.18 407.804 1039.17 398.75C1039.17 389.755 1046.5 382.438 1055.5 382.438C1064.49 382.438 1071.85 389.767 1071.85 398.786L1071.87 436.355C1071.87 445.361 1064.54 452.691 1055.52 452.691Z"
          fill={themeColor}
        />
        <path
          d="M1055.52 452.692C1046.5 452.692 1039.17 445.363 1039.17 436.344L1039.15 435.919C1039.11 424.25 1029.6 414.77 1017.92 414.77V419.644C1026.94 419.644 1034.27 426.973 1034.27 435.992V436.344C1034.27 445.363 1026.94 452.692 1017.92 452.692C1008.9 452.692 1001.59 445.363 1001.59 436.356L1001.57 398.787C1001.57 389.781 1008.9 382.439 1017.92 382.439C1026.95 382.439 1034.31 389.769 1034.31 398.763C1034.31 404.427 1036.51 409.811 1040.5 413.907C1044.53 418.052 1049.87 420.337 1055.52 420.337V415.463C1046.66 415.463 1039.18 407.806 1039.17 398.751C1039.17 389.756 1046.5 382.439 1055.5 382.439C1064.49 382.439 1071.85 389.769 1071.85 398.787L1071.87 436.356C1071.87 445.363 1064.54 452.692 1055.52 452.692Z"
          fill="#04030F"
        />
        <path
          d="M1130.47 377.589L1092.93 377.564C1081.61 377.564 1072.33 386.502 1071.76 397.686H1071.73V436.362C1071.73 448.056 1081.24 457.564 1092.93 457.564L1130.47 457.54C1142.17 457.54 1151.68 448.032 1151.68 436.338V398.791C1151.68 387.097 1142.17 377.589 1130.47 377.589ZM1130.47 452.659L1092.93 452.683C1083.93 452.683 1076.6 445.36 1076.6 436.35V412.258C1080.52 416.994 1086.44 420.017 1093.05 420.017C1104.67 420.017 1114.13 410.485 1114.13 398.767H1109.26C1109.26 407.801 1101.99 415.148 1093.04 415.148C1084.09 415.148 1076.59 407.789 1076.59 398.754C1076.59 389.72 1083.92 382.422 1092.92 382.422L1130.46 382.446C1139.47 382.446 1146.79 389.768 1146.79 398.779V436.326C1146.79 445.336 1139.47 452.659 1130.46 452.659H1130.47Z"
          fill={themeColor}
        />
        <path
          d="M1092.93 452.696L1130.47 452.672C1139.48 452.672 1146.81 445.349 1146.81 436.339V398.792C1146.81 389.782 1139.48 382.459 1130.47 382.459L1092.93 382.435C1083.93 382.435 1076.6 389.757 1076.6 398.768C1076.6 407.778 1083.98 415.161 1093.05 415.161C1102.12 415.161 1109.28 407.815 1109.28 398.78H1114.15C1114.15 410.498 1104.69 420.031 1093.06 420.031C1086.44 420.031 1080.53 417.007 1076.62 412.271V436.363C1076.62 445.374 1083.94 452.696 1092.94 452.696H1092.93Z"
          fill="#04030F"
        />
        <path
          d="M1205.51 457.564H1167.92C1156.22 457.564 1146.7 448.048 1146.7 436.343L1146.68 398.774C1146.68 387.081 1156.19 377.564 1167.9 377.564C1176.07 377.564 1183.17 382.207 1186.72 388.977C1190.27 382.195 1197.37 377.564 1205.54 377.564C1217.24 377.564 1226.76 387.081 1226.76 398.774L1226.74 436.343C1226.74 448.035 1217.22 457.564 1205.51 457.564ZM1167.9 382.438C1158.88 382.438 1151.55 389.767 1151.55 398.774L1151.57 436.343C1151.57 445.361 1158.9 452.691 1167.92 452.691H1205.51C1214.53 452.691 1221.86 445.361 1221.86 436.343L1221.89 398.774C1221.89 389.767 1214.56 382.438 1205.54 382.438C1196.52 382.438 1189.19 389.767 1189.19 398.786L1189.16 422.633H1184.29V399.284H1184.26L1184.24 398.786C1184.24 389.767 1176.91 382.438 1167.89 382.438H1167.9Z"
          fill={themeColor}
        />
        <path
          d="M1167.9 382.439C1158.88 382.439 1151.55 389.769 1151.55 398.775L1151.57 436.344C1151.57 445.363 1158.9 452.692 1167.92 452.692H1205.51C1214.53 452.692 1221.86 445.363 1221.86 436.344L1221.89 398.775C1221.89 389.769 1214.56 382.439 1205.54 382.439C1196.52 382.439 1189.19 389.769 1189.19 398.787L1189.16 422.634H1184.29V399.285H1184.26L1184.24 398.799C1184.24 389.781 1176.91 382.452 1167.89 382.452L1167.9 382.439Z"
          fill="#04030F"
        />
        <path
          d="M1301.78 398.786C1301.78 387.081 1292.26 377.564 1280.55 377.564C1272.42 377.564 1265.34 382.171 1261.79 388.917C1258.23 382.183 1251.14 377.564 1242.99 377.564H1242.98C1231.28 377.564 1221.76 387.081 1221.76 398.786L1221.79 436.355C1221.79 448.048 1231.29 457.564 1242.99 457.564C1251.14 457.564 1258.23 452.946 1261.78 446.176C1265.33 452.934 1272.42 457.564 1280.58 457.564C1292.28 457.564 1301.8 448.048 1301.8 436.355L1301.78 398.786ZM1280.59 452.691C1271.57 452.691 1264.24 445.361 1264.24 436.343L1264.21 435.918C1264.18 424.249 1254.67 414.769 1242.99 414.769V419.643C1252.01 419.643 1259.34 426.972 1259.34 435.99V436.343C1259.34 445.361 1252.01 452.691 1242.99 452.691C1233.97 452.691 1226.66 445.361 1226.66 436.355L1226.64 398.786C1226.64 389.78 1233.97 382.438 1242.99 382.438C1252.02 382.438 1259.37 389.767 1259.37 398.762C1259.37 404.426 1261.57 409.81 1265.56 413.906C1269.6 418.051 1274.93 420.336 1280.59 420.336V415.462C1271.73 415.462 1264.25 407.804 1264.24 398.75C1264.24 389.755 1271.57 382.438 1280.56 382.438C1289.56 382.438 1296.91 389.767 1296.91 398.786L1296.94 436.355C1296.94 445.361 1289.61 452.691 1280.59 452.691Z"
          fill={themeColor}
        />
        <path
          d="M1280.59 452.692C1271.57 452.692 1264.24 445.363 1264.24 436.344L1264.21 435.919C1264.18 424.25 1254.67 414.77 1242.99 414.77V419.644C1252.01 419.644 1259.34 426.973 1259.34 435.992V436.344C1259.34 445.363 1252.01 452.692 1242.99 452.692C1233.97 452.692 1226.66 445.363 1226.66 436.356L1226.64 398.787C1226.64 389.781 1233.97 382.439 1242.99 382.439C1252.02 382.439 1259.37 389.769 1259.37 398.763C1259.37 404.427 1261.57 409.811 1265.56 413.907C1269.6 418.052 1274.93 420.337 1280.59 420.337V415.463C1271.73 415.463 1264.25 407.806 1264.24 398.751C1264.24 389.756 1271.57 382.439 1280.56 382.439C1289.56 382.439 1296.91 389.769 1296.91 398.787L1296.94 436.356C1296.94 445.363 1289.61 452.692 1280.59 452.692Z"
          fill="#04030F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1301.68 436.359H1296.8C1296.8 428.216 1301.43 421.119 1308.18 417.577C1301.43 414.034 1296.8 406.95 1296.8 398.794C1296.8 387.096 1306.31 377.589 1318.01 377.589L1355.55 377.564C1367.25 377.564 1376.75 387.071 1376.75 398.77H1371.87C1371.87 389.774 1364.55 382.446 1355.55 382.446L1318.01 382.47C1309.01 382.47 1301.68 389.798 1301.68 398.794C1301.68 407.79 1309 415.118 1318.01 415.118V415.154C1329.71 415.154 1339.22 424.673 1339.22 436.359H1334.34C1334.34 427.364 1327.01 420.036 1318.01 420.036C1309.01 420.036 1301.68 427.351 1301.68 436.359ZM1371.87 398.77H1376.75C1376.75 406.914 1372.13 414.011 1365.37 417.553C1372.13 421.095 1376.75 428.18 1376.75 436.336C1376.75 448.034 1367.23 457.541 1355.55 457.541L1318.01 457.565C1306.31 457.565 1296.8 448.058 1296.8 436.36H1301.68C1301.68 445.356 1309 452.684 1318.01 452.684L1355.55 452.659C1364.54 452.659 1371.87 445.331 1371.87 436.336C1371.87 427.34 1364.55 420.012 1355.55 420.012V419.975C1343.84 419.975 1334.34 410.468 1334.34 398.77H1339.22C1339.22 407.766 1346.55 415.094 1355.55 415.094C1364.54 415.094 1371.87 407.778 1371.87 398.77Z"
          fill={themeColor}
        />
        <path
          d="M1355.68 452.696L1318.14 452.721C1309.13 452.721 1301.82 445.393 1301.82 436.385C1301.82 427.377 1309.15 420.049 1318.14 420.049C1327.14 420.049 1334.47 427.377 1334.47 436.385H1339.35C1339.35 424.687 1329.84 415.167 1318.14 415.167C1309.13 415.167 1301.82 407.827 1301.82 398.819C1301.82 389.811 1309.15 382.496 1318.14 382.496L1355.68 382.471C1364.69 382.471 1372.01 389.799 1372.01 398.807C1372.01 407.815 1364.68 415.131 1355.68 415.131C1346.68 415.131 1339.35 407.803 1339.35 398.807H1334.47C1334.47 410.505 1343.98 420.012 1355.68 420.012V420.049C1364.69 420.049 1372.01 427.377 1372.01 436.373C1372.01 445.368 1364.68 452.696 1355.68 452.696Z"
          fill="#04030F"
        />
        <circle cx="1391.75" cy="417.564" r="6" fill="white" />
        <circle cx="1391.75" cy="417.564" r="6" stroke="#04030F" />
        <path
          d="M1475.35 417.564C1482.09 414.005 1486.71 406.924 1486.71 398.773C1486.7 387.076 1477.18 377.564 1465.49 377.564H1427.95C1416.27 377.564 1406.75 387.088 1406.75 398.785L1406.78 436.332V436.356H1406.75C1406.75 448.053 1416.27 457.564 1427.95 457.564L1465.51 457.54C1477.21 457.54 1486.72 448.029 1486.72 436.332C1486.72 428.193 1482.1 421.124 1475.36 417.564H1475.35ZM1465.49 452.669L1427.97 452.694V452.657C1418.96 452.657 1411.65 445.332 1411.65 436.332L1411.63 398.785C1411.63 389.772 1418.95 382.435 1427.95 382.435H1465.49C1474.51 382.435 1481.84 389.772 1481.84 398.773C1481.84 407.774 1474.51 415.135 1465.51 415.135C1453.81 415.135 1444.28 424.646 1444.28 436.344H1449.15C1449.15 427.331 1456.49 419.994 1465.51 419.994C1474.52 419.994 1481.84 427.318 1481.84 436.319C1481.84 445.32 1474.51 452.657 1465.49 452.657V452.669Z"
          fill={themeColor}
        />
        <path
          d="M1465.48 452.67L1427.95 452.695V452.658C1418.95 452.658 1411.64 445.334 1411.64 436.333L1411.61 398.786C1411.61 389.773 1418.94 382.437 1427.94 382.437H1465.49C1474.51 382.437 1481.84 389.773 1481.84 398.774C1481.84 407.775 1474.51 415.136 1465.51 415.136C1453.81 415.136 1444.28 424.647 1444.28 436.345H1449.15C1449.15 427.332 1456.49 419.995 1465.51 419.995C1474.52 419.995 1481.84 427.32 1481.84 436.321C1481.84 445.321 1474.51 452.658 1465.49 452.658L1465.48 452.67Z"
          fill="white"
        />
        <circle cx="981.696" cy="417.564" r="6" fill="white" />
        <circle cx="981.696" cy="417.564" r="6" stroke="#04030F" />
        <path
          d="M812.68 398.786C812.68 387.082 803.162 377.565 791.455 377.565C783.322 377.565 776.247 382.171 772.697 388.917C769.135 382.184 762.048 377.565 753.891 377.565H753.879C742.185 377.565 732.666 387.082 732.666 398.786L732.69 436.356C732.69 448.048 742.197 457.565 753.891 457.565C762.048 457.565 769.135 452.946 772.685 446.176C776.235 452.934 783.322 457.565 791.479 457.565C803.186 457.565 812.704 448.048 812.704 436.356L812.68 398.786ZM791.491 452.691C782.471 452.691 775.141 445.362 775.141 436.343L775.116 435.918C775.08 424.25 765.574 414.769 753.891 414.769V419.643C762.911 419.643 770.242 426.972 770.242 435.991V436.343C770.242 445.362 762.911 452.691 753.891 452.691C744.871 452.691 737.565 445.362 737.565 436.356L737.541 398.786C737.541 389.78 744.871 382.439 753.891 382.439C762.924 382.439 770.278 389.768 770.278 398.762C770.278 404.426 772.479 409.81 776.466 413.906C780.502 418.051 785.839 420.336 791.491 420.336V415.462C782.629 415.462 775.153 407.805 775.141 398.75C775.141 389.756 782.471 382.439 791.467 382.439C800.463 382.439 807.817 389.768 807.817 398.786L807.842 436.356C807.842 445.362 800.511 452.691 791.491 452.691Z"
          fill={themeColor}
        />
        <path
          d="M791.491 452.692C782.471 452.692 775.141 445.363 775.141 436.345L775.116 435.919C775.08 424.251 765.573 414.771 753.891 414.771V419.644C762.911 419.644 770.241 426.974 770.241 435.992V436.345C770.241 445.363 762.911 452.692 753.891 452.692C744.871 452.692 737.565 445.363 737.565 436.357L737.541 398.788C737.541 389.781 744.871 382.44 753.891 382.44C762.923 382.44 770.278 389.769 770.278 398.763C770.278 404.427 772.478 409.812 776.466 413.908C780.502 418.052 785.838 420.337 791.491 420.337V415.463C782.629 415.463 775.153 407.806 775.141 398.751C775.141 389.757 782.471 382.44 791.467 382.44C800.463 382.44 807.817 389.769 807.817 398.788L807.842 436.357C807.842 445.363 800.511 452.692 791.491 452.692Z"
          fill="white"
        />
        <path
          d="M889.719 398.786C889.719 387.082 880.2 377.565 868.493 377.565C860.361 377.565 853.286 382.171 849.736 388.917C846.174 382.184 839.087 377.565 830.93 377.565H830.918C819.223 377.565 809.705 387.082 809.705 398.786L809.729 436.356C809.729 448.048 819.235 457.565 830.93 457.565C839.087 457.565 846.174 452.946 849.724 446.176C853.273 452.934 860.361 457.565 868.518 457.565C880.224 457.565 889.743 448.048 889.743 436.356L889.719 398.786ZM868.53 452.691C859.51 452.691 852.179 445.362 852.179 436.343L852.155 435.918C852.119 424.25 842.612 414.769 830.93 414.769V419.643C839.95 419.643 847.28 426.972 847.28 435.991V436.343C847.28 445.362 839.95 452.691 830.93 452.691C821.91 452.691 814.604 445.362 814.604 436.356L814.579 398.786C814.579 389.78 821.91 382.439 830.93 382.439C839.962 382.439 847.317 389.768 847.317 398.762C847.317 404.426 849.517 409.81 853.504 413.906C857.54 418.051 862.877 420.336 868.53 420.336V415.462C859.668 415.462 852.192 407.805 852.179 398.75C852.179 389.756 859.51 382.439 868.506 382.439C877.501 382.439 884.856 389.768 884.856 398.786L884.88 436.356C884.88 445.362 877.55 452.691 868.53 452.691Z"
          fill={themeColor}
        />
        <path
          d="M868.53 452.692C859.509 452.692 852.179 445.363 852.179 436.345L852.155 435.919C852.118 424.251 842.612 414.771 830.93 414.771V419.644C839.95 419.644 847.28 426.974 847.28 435.992V436.345C847.28 445.363 839.95 452.692 830.93 452.692C821.909 452.692 814.603 445.363 814.603 436.357L814.579 398.788C814.579 389.781 821.909 382.44 830.93 382.44C839.962 382.44 847.317 389.769 847.317 398.763C847.317 404.427 849.517 409.812 853.504 413.908C857.54 418.052 862.877 420.337 868.53 420.337V415.463C859.668 415.463 852.191 407.806 852.179 398.751C852.179 389.757 859.509 382.44 868.505 382.44C877.501 382.44 884.856 389.769 884.856 398.788L884.88 436.357C884.88 445.363 877.55 452.692 868.53 452.692Z"
          fill="white"
        />
        <path
          d="M886.743 436.359H891.625C891.625 427.351 898.954 420.036 907.952 420.036C916.949 420.036 924.278 427.364 924.278 436.359H929.16C929.16 424.673 919.652 415.154 907.952 415.154V415.118C898.942 415.118 891.625 407.79 891.625 398.794C891.625 389.798 898.954 382.47 907.952 382.47L945.487 382.446C954.497 382.446 961.814 389.774 961.814 398.77H966.696C966.696 387.071 957.187 377.564 945.487 377.564L907.952 377.589C896.251 377.589 886.743 387.096 886.743 398.794C886.743 406.95 891.369 414.034 898.126 417.577C891.369 421.119 886.743 428.216 886.743 436.359Z"
          fill={themeColor}
        />
        <path
          d="M966.696 398.77H961.814C961.814 407.778 954.484 415.094 945.487 415.094C936.49 415.094 929.16 407.766 929.16 398.77H924.278C924.278 410.468 933.787 419.975 945.487 419.975V420.012C954.497 420.012 961.814 427.34 961.814 436.335C961.814 445.331 954.484 452.659 945.487 452.659L907.952 452.684C898.942 452.684 891.625 445.356 891.625 436.36H886.743C886.743 448.058 896.251 457.565 907.952 457.565L945.487 457.541C957.175 457.541 966.696 448.034 966.696 436.335C966.696 428.18 962.069 421.095 955.312 417.553C962.069 414.01 966.696 406.914 966.696 398.77Z"
          fill={themeColor}
        />
        <path
          d="M945.621 452.696L908.085 452.721C899.076 452.721 891.759 445.393 891.759 436.385C891.759 427.377 899.088 420.049 908.085 420.049C917.083 420.049 924.412 427.377 924.412 436.385H929.294C929.294 424.687 919.786 415.167 908.085 415.167C899.076 415.167 891.759 407.827 891.759 398.819C891.759 389.811 899.088 382.496 908.085 382.496L945.621 382.471C954.63 382.471 961.948 389.799 961.948 398.807C961.948 407.815 954.618 415.131 945.621 415.131C936.624 415.131 929.294 407.803 929.294 398.807H924.412C924.412 410.505 933.921 420.012 945.621 420.012V420.049C954.63 420.049 961.948 427.377 961.948 436.373C961.948 445.368 954.618 452.696 945.621 452.696Z"
          fill="white"
        />
        <circle cx="717.666" cy="417.564" r="6" fill="white" />
        <circle cx="717.666" cy="417.564" r="6" stroke="#04030F" />
        <path
          d="M139.906 398.775C139.906 389.769 147.237 382.439 156.257 382.439C165.277 382.439 172.607 389.769 172.607 398.787L172.644 436.356H177.518C177.518 427.338 184.849 420.009 193.869 420.009C202.889 420.009 210.219 427.338 210.219 436.356C210.219 445.375 202.889 452.704 193.869 452.704H156.281C147.261 452.704 139.931 445.375 139.931 436.356L139.906 398.787V398.775Z"
          fill="white"
        />
        <path
          d="M215.094 436.343C215.094 424.65 205.576 415.134 193.869 415.134C187.293 415.134 181.397 418.148 177.507 422.852L177.482 398.786C177.482 387.081 167.964 377.564 156.257 377.564C144.55 377.564 135.032 387.081 135.032 398.774L135.056 436.343C135.056 448.048 144.575 457.564 156.281 457.564H193.869C205.564 457.564 215.082 448.048 215.094 436.355V436.343ZM193.869 452.691H156.281C147.261 452.691 139.931 445.361 139.931 436.343L139.907 398.774C139.907 389.767 147.237 382.438 156.257 382.438C165.277 382.438 172.607 389.767 172.607 398.786L172.644 436.343H177.519C177.519 427.324 184.849 419.995 193.869 419.995C202.889 419.995 210.22 427.324 210.22 436.343C210.22 445.361 202.889 452.691 193.869 452.691Z"
          fill={themeColor}
        />
        <path
          d="M231.344 457.564C219.637 457.564 210.119 448.048 210.119 436.343L210.094 398.774C210.094 387.081 219.613 377.564 231.32 377.564C243.026 377.564 252.545 387.081 252.545 398.786V436.367C252.545 448.048 243.038 457.552 231.356 457.552L231.344 457.564ZM231.32 382.438C222.299 382.438 214.969 389.767 214.969 398.774L214.993 436.343C214.993 445.361 222.324 452.691 231.344 452.691C240.364 452.691 247.67 445.374 247.67 436.367V398.786C247.67 389.767 240.34 382.438 231.32 382.438Z"
          fill={themeColor}
        />
        <path
          d="M231.307 382.439C222.287 382.439 214.957 389.769 214.957 398.775L214.981 436.344C214.981 445.363 222.311 452.692 231.331 452.692C240.351 452.692 247.658 445.375 247.658 436.368V398.787C247.658 389.769 240.327 382.439 231.307 382.439Z"
          fill="white"
        />
        <path
          d="M252.419 398.775C252.419 389.769 259.75 382.439 268.77 382.439C277.79 382.439 285.12 389.769 285.12 398.787L285.157 436.356H290.032C290.032 427.338 297.362 420.009 306.382 420.009C315.402 420.009 322.733 427.338 322.733 436.356C322.733 445.375 315.402 452.704 306.382 452.704H268.794C259.774 452.704 252.444 445.375 252.444 436.356L252.419 398.787V398.775Z"
          fill="white"
        />
        <path
          d="M327.608 436.343C327.608 424.65 318.089 415.134 306.382 415.134C299.806 415.134 293.91 418.148 290.02 422.852L289.995 398.786C289.995 387.081 280.477 377.564 268.77 377.564C257.063 377.564 247.545 387.081 247.545 398.774L247.569 436.343C247.569 448.048 257.088 457.564 268.794 457.564H306.382C318.077 457.564 327.595 448.048 327.608 436.355V436.343ZM306.382 452.691H268.794C259.774 452.691 252.444 445.361 252.444 436.343L252.42 398.774C252.42 389.767 259.75 382.438 268.77 382.438C277.79 382.438 285.121 389.767 285.121 398.786L285.157 436.343H290.032C290.032 427.324 297.362 419.995 306.382 419.995C315.402 419.995 322.733 427.324 322.733 436.343C322.733 445.361 315.402 452.691 306.382 452.691Z"
          fill={themeColor}
        />
        <path
          d="M402.621 398.786C402.621 387.081 393.103 377.564 381.396 377.564C373.264 377.564 366.188 382.171 362.639 388.917C359.077 382.183 351.99 377.564 343.833 377.564H343.82C332.126 377.564 322.607 387.081 322.607 398.786L322.632 436.355C322.632 448.048 332.138 457.564 343.833 457.564C351.99 457.564 359.077 452.946 362.627 446.176C366.176 452.934 373.264 457.564 381.421 457.564C393.127 457.564 402.646 448.048 402.646 436.355L402.621 398.786ZM381.433 452.691C372.413 452.691 365.082 445.361 365.082 436.343L365.058 435.918C365.021 424.249 355.515 414.769 343.833 414.769V419.643C352.853 419.643 360.183 426.972 360.183 435.99V436.343C360.183 445.361 352.853 452.691 343.833 452.691C334.813 452.691 327.506 445.361 327.506 436.355L327.482 398.786C327.482 389.78 334.813 382.438 343.833 382.438C352.865 382.438 360.22 389.767 360.22 398.762C360.22 404.426 362.42 409.81 366.407 413.906C370.443 418.051 375.78 420.336 381.433 420.336V415.462C372.571 415.462 365.094 407.804 365.082 398.75C365.082 389.755 372.413 382.438 381.408 382.438C390.404 382.438 397.759 389.767 397.759 398.786L397.783 436.355C397.783 445.361 390.453 452.691 381.433 452.691Z"
          fill={themeColor}
        />
        <path
          d="M381.432 452.692C372.412 452.692 365.082 445.363 365.082 436.344L365.058 435.919C365.021 424.25 355.515 414.77 343.832 414.77V419.644C352.853 419.644 360.183 426.973 360.183 435.992V436.344C360.183 445.363 352.853 452.692 343.832 452.692C334.812 452.692 327.506 445.363 327.506 436.356L327.482 398.787C327.482 389.781 334.812 382.439 343.832 382.439C352.865 382.439 360.219 389.769 360.219 398.763C360.219 404.427 362.42 409.811 366.407 413.907C370.443 418.052 375.78 420.337 381.432 420.337V415.463C372.57 415.463 365.094 407.806 365.082 398.751C365.082 389.756 372.412 382.439 381.408 382.439C390.404 382.439 397.759 389.769 397.759 398.787L397.783 436.356C397.783 445.363 390.453 452.692 381.432 452.692Z"
          fill="white"
        />
        <path
          d="M456.381 377.589L418.84 377.564C407.52 377.564 398.241 386.502 397.67 397.686H397.646V436.362C397.646 448.056 407.156 457.564 418.84 457.564L456.381 457.54C468.077 457.54 477.587 448.032 477.587 436.338V398.791C477.587 387.097 468.077 377.589 456.381 377.589ZM456.381 452.659L418.84 452.683C409.84 452.683 402.516 445.36 402.516 436.35V412.258C406.427 416.994 412.354 420.017 418.961 420.017C430.584 420.017 440.046 410.485 440.046 398.767H435.175C435.175 407.801 427.9 415.148 418.949 415.148C409.998 415.148 402.504 407.789 402.504 398.754C402.504 389.72 409.828 382.422 418.828 382.422L456.369 382.446C465.381 382.446 472.705 389.768 472.705 398.779V436.326C472.705 445.336 465.381 452.659 456.369 452.659H456.381Z"
          fill={themeColor}
        />
        <path
          d="M418.84 452.696L456.381 452.672C465.393 452.672 472.717 445.349 472.717 436.339V398.792C472.717 389.782 465.393 382.459 456.381 382.459L418.84 382.435C409.84 382.435 402.516 389.757 402.516 398.768C402.516 407.778 409.888 415.161 418.961 415.161C428.034 415.161 435.187 407.815 435.187 398.78H440.058C440.058 410.498 430.596 420.031 418.973 420.031C412.354 420.031 406.439 417.007 402.528 412.271V436.363C402.528 445.374 409.852 452.696 418.852 452.696H418.84Z"
          fill="white"
        />
        <path
          d="M531.424 457.564H493.836C482.13 457.564 472.611 448.048 472.611 436.343L472.587 398.774C472.587 387.081 482.105 377.564 493.812 377.564C501.981 377.564 509.081 382.207 512.63 388.977C516.18 382.195 523.279 377.564 531.449 377.564C543.155 377.564 552.674 387.081 552.674 398.774L552.65 436.343C552.65 448.035 543.131 457.564 531.424 457.564ZM493.812 382.438C484.792 382.438 477.462 389.767 477.462 398.774L477.486 436.343C477.486 445.361 484.816 452.691 493.836 452.691H531.424C540.444 452.691 547.775 445.361 547.775 436.343L547.799 398.774C547.799 389.767 540.469 382.438 531.449 382.438C522.429 382.438 515.098 389.767 515.098 398.786L515.074 422.633H510.199V399.284H510.175L510.15 398.786C510.15 389.767 502.82 382.438 493.8 382.438H493.812Z"
          fill={themeColor}
        />
        <path
          d="M493.812 382.439C484.792 382.439 477.461 389.769 477.461 398.775L477.486 436.344C477.486 445.363 484.816 452.692 493.836 452.692H531.424C540.444 452.692 547.775 445.363 547.775 436.344L547.799 398.775C547.799 389.769 540.468 382.439 531.448 382.439C522.428 382.439 515.098 389.769 515.098 398.787L515.074 422.634H510.199V399.285H510.175L510.15 398.799C510.15 389.781 502.82 382.452 493.8 382.452L493.812 382.439Z"
          fill="white"
        />
        <path
          d="M627.688 398.786C627.688 387.081 618.169 377.564 606.463 377.564C598.33 377.564 591.255 382.171 587.705 388.917C584.143 382.183 577.056 377.564 568.899 377.564H568.887C557.192 377.564 547.674 387.081 547.674 398.786L547.698 436.355C547.698 448.048 557.205 457.564 568.899 457.564C577.056 457.564 584.143 452.946 587.693 446.176C591.243 452.934 598.33 457.564 606.487 457.564C618.194 457.564 627.712 448.048 627.712 436.355L627.688 398.786ZM606.499 452.691C597.479 452.691 590.149 445.361 590.149 436.343L590.124 435.918C590.088 424.249 580.581 414.769 568.899 414.769V419.643C577.919 419.643 585.25 426.972 585.25 435.99V436.343C585.25 445.361 577.919 452.691 568.899 452.691C559.879 452.691 552.573 445.361 552.573 436.355L552.549 398.786C552.549 389.78 559.879 382.438 568.899 382.438C577.931 382.438 585.286 389.767 585.286 398.762C585.286 404.426 587.486 409.81 591.474 413.906C595.51 418.051 600.846 420.336 606.499 420.336V415.462C597.637 415.462 590.161 407.804 590.149 398.75C590.149 389.755 597.479 382.438 606.475 382.438C615.471 382.438 622.825 389.767 622.825 398.786L622.85 436.355C622.85 445.361 615.519 452.691 606.499 452.691Z"
          fill={themeColor}
        />
        <path
          d="M606.499 452.692C597.479 452.692 590.148 445.363 590.148 436.344L590.124 435.919C590.088 424.25 580.581 414.77 568.899 414.77V419.644C577.919 419.644 585.249 426.973 585.249 435.992V436.344C585.249 445.363 577.919 452.692 568.899 452.692C559.879 452.692 552.573 445.363 552.573 436.356L552.548 398.787C552.548 389.781 559.879 382.439 568.899 382.439C577.931 382.439 585.286 389.769 585.286 398.763C585.286 404.427 587.486 409.811 591.473 413.907C595.509 418.052 600.846 420.337 606.499 420.337V415.463C597.637 415.463 590.161 407.806 590.148 398.751C590.148 389.756 597.479 382.439 606.475 382.439C615.47 382.439 622.825 389.769 622.825 398.787L622.849 436.356C622.849 445.363 615.519 452.692 606.499 452.692Z"
          fill="white"
        />
        <path
          d="M622.712 436.359H627.595C627.595 427.351 634.924 420.036 643.921 420.036C652.919 420.036 660.248 427.364 660.248 436.359H665.13C665.13 424.673 655.621 415.154 643.921 415.154V415.118C634.912 415.118 627.595 407.79 627.595 398.794C627.595 389.798 634.924 382.47 643.921 382.47L681.457 382.446C690.466 382.446 697.783 389.774 697.783 398.77H702.666C702.666 387.071 693.157 377.564 681.457 377.564L643.921 377.589C632.221 377.589 622.712 387.096 622.712 398.794C622.712 406.95 627.339 414.034 634.096 417.577C627.339 421.119 622.712 428.216 622.712 436.359Z"
          fill={themeColor}
        />
        <path
          d="M702.666 398.77H697.783C697.783 407.778 690.454 415.094 681.457 415.094C672.459 415.094 665.13 407.766 665.13 398.77H660.248C660.248 410.468 669.757 419.975 681.457 419.975V420.012C690.466 420.012 697.783 427.34 697.783 436.335C697.783 445.331 690.454 452.659 681.457 452.659L643.921 452.684C634.912 452.684 627.595 445.356 627.595 436.36H622.712C622.712 448.058 632.221 457.565 643.921 457.565L681.457 457.541C693.145 457.541 702.666 448.034 702.666 436.335C702.666 428.18 698.039 421.095 691.282 417.553C698.039 414.01 702.666 406.914 702.666 398.77Z"
          fill={themeColor}
        />
        <path
          d="M681.591 452.696L644.055 452.721C635.046 452.721 627.729 445.393 627.729 436.385C627.729 427.377 635.058 420.049 644.055 420.049C653.053 420.049 660.382 427.377 660.382 436.385H665.264C665.264 424.687 655.755 415.167 644.055 415.167C635.046 415.167 627.729 407.827 627.729 398.819C627.729 389.811 635.058 382.496 644.055 382.496L681.591 382.471C690.6 382.471 697.917 389.799 697.917 398.807C697.917 407.815 690.588 415.131 681.591 415.131C672.593 415.131 665.264 407.803 665.264 398.807H660.382C660.382 410.505 669.891 420.012 681.591 420.012V420.049C690.6 420.049 697.917 427.377 697.917 436.373C697.917 445.368 690.588 452.696 681.591 452.696Z"
          fill="white"
        />
        <circle cx="120.032" cy="417.564" r="6" fill="white" />
        <circle cx="120.032" cy="417.564" r="6" stroke="#04030F" />
        <path
          d="M8.86302 457.564C0.831242 457.564 -6.32567 452.656 -9.92235 445.634C-13.5069 452.656 -20.6638 457.552 -28.6956 457.552C-40.3969 457.552 -49.9111 448.04 -49.9111 436.34V398.801C-49.9111 387.101 -40.3969 377.589 -28.6956 377.589L8.86302 377.564C20.5522 377.564 30.0664 387.077 30.0664 398.776C30.0664 406.916 25.4369 414.011 18.6567 417.558C25.4369 421.118 30.0786 428.201 30.0786 436.34V436.668C29.9085 448.21 20.455 457.552 8.87516 457.552L8.86302 457.564ZM-7.48001 435.575C-7.48001 444.857 0.00497437 452.693 8.86302 452.693C17.7211 452.693 25.1939 445.367 25.1939 436.365V436.061C25.0359 427.18 17.7211 420 8.72936 420V415.129C17.8061 415.129 25.1939 407.791 25.1939 398.776C25.1939 389.762 17.8669 382.436 8.86302 382.436L-28.6956 382.46C-37.7116 382.46 -45.0386 389.786 -45.0386 398.801V436.34C-45.0386 445.355 -37.7116 452.681 -28.6956 452.681C-19.6796 452.681 -12.3525 444.845 -12.3525 435.575H-7.48001Z"
          fill={themeColor}
        />
        <path
          d="M25.1939 436.376C25.1939 445.379 17.8669 452.704 8.86305 452.704C-0.140808 452.704 -7.47998 444.868 -7.47998 435.587C-7.47998 423.474 -17.3952 412.844 -28.6955 412.844V417.716C-19.9833 417.716 -12.3525 426.074 -12.3525 435.587C-12.3525 445.099 -19.8375 452.692 -28.6955 452.692C-37.5536 452.692 -45.0386 445.366 -45.0386 436.352V398.812C-45.0386 389.798 -37.7115 382.472 -28.6955 382.472L8.86305 382.448C17.8669 382.448 25.1939 389.773 25.1939 398.788C25.1939 407.802 17.8061 415.14 8.72939 415.14V420.012C17.7089 420.012 25.036 427.192 25.1939 436.073V436.376Z"
          fill="white"
        />
        <path
          d="M25.0786 436.359H29.9608C29.9608 427.351 37.2902 420.036 46.2875 420.036C55.2848 420.036 62.6141 427.364 62.6141 436.359H67.4963C67.4963 424.673 57.9876 415.154 46.2875 415.154V415.118C37.278 415.118 29.9608 407.79 29.9608 398.794C29.9608 389.798 37.2902 382.47 46.2875 382.47L83.823 382.446C92.8325 382.446 100.15 389.774 100.15 398.77H105.032C105.032 387.071 95.5231 377.564 83.823 377.564L46.2875 377.589C34.5873 377.589 25.0786 387.096 25.0786 398.794C25.0786 406.95 29.7051 414.034 36.4622 417.577C29.7051 421.119 25.0786 428.216 25.0786 436.359Z"
          fill={themeColor}
        />
        <path
          d="M105.032 398.77H100.15C100.15 407.778 92.8203 415.094 83.823 415.094C74.8256 415.094 67.4963 407.766 67.4963 398.77H62.6141C62.6141 410.468 72.1228 419.975 83.823 419.975V420.012C92.8325 420.012 100.15 427.34 100.15 436.335C100.15 445.331 92.8203 452.659 83.823 452.659L46.2875 452.684C37.278 452.684 29.9608 445.356 29.9608 436.36H25.0786C25.0786 448.058 34.5873 457.565 46.2875 457.565L83.823 457.541C95.511 457.541 105.032 448.034 105.032 436.335C105.032 428.18 100.405 421.095 93.6482 417.553C100.405 414.01 105.032 406.914 105.032 398.77Z"
          fill={themeColor}
        />
        <path
          d="M83.9569 452.696L46.4214 452.721C37.4119 452.721 30.0947 445.393 30.0947 436.385C30.0947 427.377 37.4241 420.049 46.4214 420.049C55.4187 420.049 62.7481 427.377 62.7481 436.385H67.6302C67.6302 424.687 58.1216 415.167 46.4214 415.167C37.4119 415.167 30.0947 407.827 30.0947 398.819C30.0947 389.811 37.4241 382.496 46.4214 382.496L83.9569 382.471C92.9664 382.471 100.284 389.799 100.284 398.807C100.284 407.815 92.9542 415.131 83.9569 415.131C74.9596 415.131 67.6302 407.803 67.6302 398.807H62.7481C62.7481 410.505 72.2567 420.012 83.9569 420.012V420.049C92.9664 420.049 100.284 427.377 100.284 436.373C100.284 445.368 92.9542 452.696 83.9569 452.696Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1470_5262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(756 0.564484) rotate(90) scale(490.648 893)"
        >
          <stop stopColor="#D9D9D9" stopOpacity="0.2" />
          <stop offset="1" stopColor="#737373" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1470_5262"
          x1="376.092"
          y1="473.77"
          x2="376.092"
          y2="532.565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C496FF" />
          <stop offset="1" stopColor="#86F9E4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1470_5262"
          x1="453.131"
          y1="473.77"
          x2="453.131"
          y2="532.565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C496FF" />
          <stop offset="1" stopColor="#86F9E4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1470_5262"
          x1="530.126"
          y1="468.149"
          x2="530.126"
          y2="511.359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C496FF" />
          <stop offset="1" stopColor="#86F9E4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1470_5262"
          x1="530.126"
          y1="489.354"
          x2="530.126"
          y2="532.565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C496FF" />
          <stop offset="1" stopColor="#86F9E4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BrandBackgroundPattern;
