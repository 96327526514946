import React from "react";

const IconInbox: React.FC = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 20C0.5 8.95431 9.45431 0 20.5 0C31.5457 0 40.5 8.95431 40.5 20C40.5 31.0457 31.5457 40 20.5 40C9.45431 40 0.5 31.0457 0.5 20Z"
        fill="#11101B"
      />
      <path
        d="M20.5 9.5C20.6989 9.5 20.8897 9.57902 21.0303 9.71967C21.171 9.86032 21.25 10.0511 21.25 10.25V15.5H19.75V10.25C19.75 10.0511 19.829 9.86032 19.9697 9.71967C20.1103 9.57902 20.3011 9.5 20.5 9.5ZM19.75 15.5V21.19L18.03 19.47C17.8878 19.3375 17.6998 19.2654 17.5055 19.2688C17.3112 19.2723 17.1258 19.351 16.9884 19.4884C16.851 19.6258 16.7723 19.8112 16.7688 20.0055C16.7654 20.1998 16.8375 20.3878 16.97 20.53L19.97 23.53C20.1106 23.6705 20.3012 23.7493 20.5 23.7493C20.6988 23.7493 20.8894 23.6705 21.03 23.53L24.03 20.53C24.1037 20.4613 24.1628 20.3785 24.2038 20.2865C24.2448 20.1945 24.2668 20.0952 24.2686 19.9945C24.2704 19.8938 24.2518 19.7938 24.2141 19.7004C24.1764 19.607 24.1203 19.5222 24.049 19.451C23.9778 19.3797 23.893 19.3236 23.7996 19.2859C23.7062 19.2482 23.6062 19.2296 23.5055 19.2314C23.4048 19.2332 23.3055 19.2552 23.2135 19.2962C23.1215 19.3372 23.0387 19.3963 22.97 19.47L21.25 21.19V15.5H25C25.7956 15.5 26.5587 15.8161 27.1213 16.3787C27.6839 16.9413 28 17.7044 28 18.5V27.5C28 28.2956 27.6839 29.0587 27.1213 29.6213C26.5587 30.1839 25.7956 30.5 25 30.5H16C15.2044 30.5 14.4413 30.1839 13.8787 29.6213C13.3161 29.0587 13 28.2956 13 27.5V18.5C13 17.7044 13.3161 16.9413 13.8787 16.3787C14.4413 15.8161 15.2044 15.5 16 15.5H19.75Z"
        fill="#828187"
      />
    </svg>
  );
};

export default IconInbox;
