const IconElectricalPlug: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="electrical_services_FILL1_wght400_GRAD0_opsz24 1">
        <path
          id="Vector"
          d="M18 15V13H20C20.2833 13 20.5208 13.0958 20.7125 13.2875C20.9042 13.4792 21 13.7167 21 14C21 14.2833 20.9042 14.5208 20.7125 14.7125C20.5208 14.9042 20.2833 15 20 15H18ZM18 19V17H20C20.2833 17 20.5208 17.0958 20.7125 17.2875C20.9042 17.4792 21 17.7167 21 18C21 18.2833 20.9042 18.5208 20.7125 18.7125C20.5208 18.9042 20.2833 19 20 19H18ZM14 20C13.45 20 12.9792 19.8042 12.5875 19.4125C12.1958 19.0208 12 18.55 12 18H10V14H12C12 13.45 12.1958 12.9792 12.5875 12.5875C12.9792 12.1958 13.45 12 14 12H17V20H14ZM7 17C5.9 17 4.95833 16.6083 4.175 15.825C3.39167 15.0417 3 14.1 3 13C3 11.9 3.39167 10.9583 4.175 10.175C4.95833 9.39167 5.9 9 7 9H8.5C8.91667 9 9.27083 8.85417 9.5625 8.5625C9.85417 8.27083 10 7.91667 10 7.5C10 7.08333 9.85417 6.72917 9.5625 6.4375C9.27083 6.14583 8.91667 6 8.5 6H5C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H8.5C9.46667 4 10.2917 4.34167 10.975 5.025C11.6583 5.70833 12 6.53333 12 7.5C12 8.46667 11.6583 9.29167 10.975 9.975C10.2917 10.6583 9.46667 11 8.5 11H7C6.45 11 5.97917 11.1958 5.5875 11.5875C5.19583 11.9792 5 12.45 5 13C5 13.55 5.19583 14.0208 5.5875 14.4125C5.97917 14.8042 6.45 15 7 15H9V17H7Z"
          fill="#828187"
        />
      </g>
    </svg>
  );
};

export default IconElectricalPlug;
