import React from "react";

const IconChevronUp: React.FC<{ color?: string; size?: number }> = ({
  color,
  size,
}) => {
  const fill = color || "#19BB46";

  return (
    <svg
      width={size || 14}
      height={size || 15}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Standard-Icons">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.69066 4.63234C6.77269 4.55041 6.88389 4.50439 6.99983 4.50439C7.11577 4.50439 7.22696 4.55041 7.309 4.63234L11.684 9.00734C11.727 9.0474 11.7615 9.0957 11.7854 9.14936C11.8093 9.20303 11.8221 9.26096 11.8232 9.31971C11.8242 9.37845 11.8134 9.4368 11.7914 9.49127C11.7694 9.54575 11.7366 9.59524 11.6951 9.63678C11.6536 9.67833 11.6041 9.71108 11.5496 9.73308C11.4951 9.75508 11.4368 9.76589 11.378 9.76485C11.3193 9.76382 11.2613 9.75096 11.2077 9.72705C11.154 9.70314 11.1057 9.66866 11.0657 9.62568L6.99983 5.55984L2.934 9.62568C2.85106 9.70296 2.74137 9.74503 2.62802 9.74303C2.51468 9.74103 2.40654 9.69511 2.32638 9.61496C2.24623 9.5348 2.20031 9.42666 2.19831 9.31331C2.19631 9.19997 2.23838 9.09028 2.31566 9.00734L6.69066 4.63234Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default IconChevronUp;
